/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/ng2-semantic-ui/dist/modules/progress/components/progress.ngfactory";
import * as i3 from "ng2-semantic-ui/dist/modules/progress/components/progress";
import * as i4 from "../../../../common/frontend/_components/notifications/notifications.component.ngfactory";
import * as i5 from "../../../../common/frontend/_components/notifications/notifications.component";
import * as i6 from "@angular/router";
import * as i7 from "@angular/common";
import * as i8 from "./app.component";
import * as i9 from "../../../../common/frontend/_services/loader.service";
import * as i10 from "../_services/user.service";
import * as i11 from "../_services/logged-in-user-update.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "ui text loader cdm-loader__indeterminate"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getLoadingText(); _ck(_v, 1, 0, currVal_0); }); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "sui-progress", [["class", "active blue cdm-loader__determinate"]], [[2, "ui", null], [2, "progress", null], [2, "success", null], [1, "data-percent", 0]], null, null, i2.View_SuiProgress_0, i2.RenderType_SuiProgress)), i1.ɵdid(1, 49152, null, 0, i3.SuiProgress, [], { value: [0, "value"], classValue: [1, "classValue"] }, null), (_l()(), i1.ɵted(2, 0, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.getPercentageLoadingValue(); var currVal_5 = "active blue cdm-loader__determinate"; _ck(_v, 1, 0, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1)._popupClasses; var currVal_1 = i1.ɵnov(_v, 1)._popupClasses; var currVal_2 = i1.ɵnov(_v, 1)._reachedMaximum; var currVal_3 = i1.ɵnov(_v, 1).percentage; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_6 = _co.getLoadingText(); _ck(_v, 2, 0, currVal_6); }); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-notifications", [], null, null, null, i4.View_NotificationsComponent_0, i4.RenderType_NotificationsComponent)), i1.ɵdid(1, 49152, null, 0, i5.NotificationsComponent, [], null, null), (_l()(), i1.ɵeld(2, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "ui active inverted dimmer cdm-loader"]], null, null, null, null, null)), i1.ɵdid(5, 278528, null, 0, i7.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(6, { "cdm-loader--visible": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(8, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i1.ɵdid(10, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 0, "div", [["class", "cdm-background"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_0 = "ui active inverted dimmer cdm-loader"; var currVal_1 = _ck(_v, 6, 0, _co.isLoading()); _ck(_v, 5, 0, currVal_0, currVal_1); var currVal_2 = _co.isIndeterminateLoading(); _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.isDeterminateLoading(); _ck(_v, 10, 0, currVal_3); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 4440064, null, 0, i8.AppComponent, [i9.LoaderService, i10.UserService, i11.LoggedInUserUpdateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i8.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
