<app-notifications></app-notifications>

<router-outlet></router-outlet>

<div class="ui active inverted dimmer cdm-loader" [ngClass]="{ 'cdm-loader--visible': isLoading() }">
	<div class="ui text loader cdm-loader__indeterminate" *ngIf="isIndeterminateLoading()">{{
		getLoadingText() }}</div>
	<sui-progress class="active blue cdm-loader__determinate" *ngIf="isDeterminateLoading()"
		[value]="getPercentageLoadingValue()">{{ getLoadingText() }}</sui-progress>
</div>

<div class="cdm-background"></div>
