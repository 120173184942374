import { Observable } from 'rxjs';
import { SocketService } from '@citadel/common-frontend/_services/socket.service';
import Events from '../_util/events';
import * as i0 from "@angular/core";
import * as i1 from "./socket.service";
export class LibraryService {
    constructor(socketService) {
        this.socketService = socketService;
    }
    init() {
        return new Observable((observer) => {
            observer.next();
            observer.complete();
        });
    }
    queryMediaLibraries(query, page, count, types) {
        return this.socketService.call(Events.QUERY_MEDIA_LIBRARIES, undefined, { query, page, count, types });
    }
    reportDownload(resourceId, provider) {
        return this.socketService.call(Events.REPORT_MEDIA_DOWNLOAD, undefined, { resourceId, provider });
    }
}
LibraryService.ngInjectableDef = i0.defineInjectable({ factory: function LibraryService_Factory() { return new LibraryService(i0.inject(i1.SocketService)); }, token: LibraryService, providedIn: "root" });
