import * as i0 from "@angular/core";
export class WaveformService {
    constructor() {
        this.waveformInstances = [];
    }
    registerWaveformInstance(instance) {
        this.waveformInstances.push(instance);
    }
    stopAllPlayback() {
        for (const waveformInstance of this.waveformInstances) {
            if (!waveformInstance) {
                continue;
            }
            waveformInstance.stop();
        }
    }
}
WaveformService.ngInjectableDef = i0.defineInjectable({ factory: function WaveformService_Factory() { return new WaveformService(); }, token: WaveformService, providedIn: "root" });
