import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
export class NetworkUpdateService {
    constructor() {
        this.networksUpdated = new Subject();
        this.networksUpdated$ = this.networksUpdated.asObservable();
    }
    notifyNetworksUpdate() {
        this.networksUpdated.next();
    }
}
NetworkUpdateService.ngInjectableDef = i0.defineInjectable({ factory: function NetworkUpdateService_Factory() { return new NetworkUpdateService(); }, token: NetworkUpdateService, providedIn: "root" });
