export default {
	GENERATE_VIDEO: 'builder/generate',
	CHECK_VIDEO_STATUS: 'builder/status',
	GET_VIDEO_LINK: 'builder/link',
	CREATE_PROJECT: 'builder/project/create',
	LIST_PROJECTS: 'builder/project/list',
	DELETE_PROJECT: 'builder/project/delete',
	EDIT_PROJECT: 'builder/project/edit',
	CREATE_TEMPLATE: 'builder/template/create',
	LIST_TEMPLATES: 'builder/template/list',
	DELETE_TEMPLATE: 'builder/template/delete',
	EDIT_TEMPLATE: 'builder/template/edit',
  LIST_TAGS: 'tag/list',
  CREATE_TAG: 'tag/create',
  DELETE_TAG: 'tag/delete',
  TAG_RESOURCES_FROM_TAG: 'tag/resources',



  SEARCH_IN_FILESYSTEM: 'filesystem/search',
	EDIT_IN_FILESYSTEM: 'filesystem/edit',
	COPY_IN_FILESYSTEM: 'filesystem/paste',
	MOVE_IN_FILESYSTEM: 'filesystem/move',
	DELETE_IN_FILESYSTEM: 'filesystem/delete',
	MAKE_DIRECTORY_IN_FILESYSTEM: 'filesystem/mkdir',
	GET_DIRECTORY_DETAILS_IN_FILESYSTEM: 'filesystem/ls',
	GET_DIRECTORY_BY_PATH_IN_FILESYSTEM: 'filesystem/get-path',
	GET_CHILDREN_FILES_IN_FILESYSTEM: 'get-all-children-files',
	GET_FILE_DETAILS_IN_FILESYSTEM: 'filesystem/file',
	CHECK_FOR_PATH_IN_FILESYSTEM: 'filesystem/check-path',

	GET_PERMISSIONS: 'permission/get',
	LIST_PERMISSION_TYPES: 'permission/types',

	LIST_ENTITLEMENTS: 'entitlements/get',
	CREATE_ENTITLEMENT: 'entitlement/create',
	EDIT_ENTITLEMENT: 'entitlement/edit',
	DELETE_ENTITLEMENT: 'entitlement/delete',
	GET_EVALUATED_ENTITLEMENT_ACCESS: 'entitlement/access',

	QUERY_MEDIA_LIBRARIES: 'library/query',
	REPORT_MEDIA_DOWNLOAD: 'library/report-download',

  STORE_PROMO_CODE: 'billing/store/promoCode',
  RETRIEVE_PROMO_CODE: 'billing/retrieve/promoCode',
};
