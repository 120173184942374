import {Injectable} from '@angular/core';
import {SuiModalService, ModalConfig, SuiActiveModal} from 'ng2-semantic-ui';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private modals: SuiActiveModal<any, any, any>[] = [];

  constructor(private suiModalService: SuiModalService) {
  }

  public open(modal: ModalConfig<any, any, any>): SuiActiveModal<any, any, any> {
    (modal as any).previousSelected = [];
    const createdModal = this.suiModalService.open(modal);
    this.modals.push(createdModal);
    return createdModal;
  }

  public closeAllModals(): void {
    for (const modal of this.modals) {
      modal.deny(undefined);
    }
  }

  public refreshAllModals(): void {
    // TODO: not really close, but later will fix
    for (const modal of this.modals) {
      if(typeof (modal as any).refreshModal === 'function'){
        (modal as any).refreshModal(undefined);
      }
    }
  }

}
