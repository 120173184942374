import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AdsUpdateService {
  private adsUpdated = new Subject<void>();

  public adsUpdated$ = this.adsUpdated.asObservable();

  public notifyAdsUpdate() {
    this.adsUpdated.next();
  }
}
