/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./selectSearch.modal.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../_components/table/table.component.ngfactory";
import * as i3 from "../../_components/table/table.component";
import * as i4 from "../../_services/notification.service";
import * as i5 from "./selectSearch.modal";
import * as i6 from "ng2-semantic-ui/dist/modules/modal/classes/modal-controls";
var styles_SelectSearchModalComponent = [i0.styles];
var RenderType_SelectSearchModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SelectSearchModalComponent, data: {} });
export { RenderType_SelectSearchModalComponent as RenderType_SelectSearchModalComponent };
export function View_SelectSearchModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { table: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["Choose a ", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "cdm-search-select-modal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-table", [], null, [[null, "onRowSelect"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onRowSelect" === en)) {
        var pd_0 = (_co.chooseResource($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_TableComponent_0, i2.RenderType_TableComponent)), i1.ɵdid(5, 114688, [[1, 4], ["table", 4]], 0, i3.TableComponent, [i4.NotificationService], { items: [0, "items"], columns: [1, "columns"], pageSize: [2, "pageSize"], showId: [3, "showId"] }, { onRowSelect: "onRowSelect" }), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "button", [["class", "ui button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modal.deny(undefined) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.options; var currVal_2 = _co.columns; var currVal_3 = 8; var currVal_4 = _co.hasProperty("searchToken"); _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.type; _ck(_v, 2, 0, currVal_0); }); }
export function View_SelectSearchModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-select-search", [], null, null, null, View_SelectSearchModalComponent_0, RenderType_SelectSearchModalComponent)), i1.ɵdid(1, 114688, null, 0, i5.SelectSearchModalComponent, [i6.Modal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SelectSearchModalComponentNgFactory = i1.ɵccf("app-modal-select-search", i5.SelectSearchModalComponent, View_SelectSearchModalComponent_Host_0, {}, {}, []);
export { SelectSearchModalComponentNgFactory as SelectSearchModalComponentNgFactory };
