import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
export class NetworkGroupUpdateService {
    constructor() {
        this.networkGroupsUpdated = new Subject();
        this.networkGroupsUpdated$ = this.networkGroupsUpdated.asObservable();
    }
    notifyNetworkGroupsUpdate() {
        this.networkGroupsUpdated.next();
    }
}
NetworkGroupUpdateService.ngInjectableDef = i0.defineInjectable({ factory: function NetworkGroupUpdateService_Factory() { return new NetworkGroupUpdateService(); }, token: NetworkGroupUpdateService, providedIn: "root" });
