import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
export class ScreensUpdateService {
    constructor() {
        this.screensUpdated = new Subject();
        this.screensUpdated$ = this.screensUpdated.asObservable();
    }
    notifyScreensUpdated() {
        this.screensUpdated.next();
    }
}
ScreensUpdateService.ngInjectableDef = i0.defineInjectable({ factory: function ScreensUpdateService_Factory() { return new ScreensUpdateService(); }, token: ScreensUpdateService, providedIn: "root" });
