import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoggedInUserUpdateService {
  private loggedInUserUpdates = new Subject<void>();

  public loggedInUserUpdates$ = this.loggedInUserUpdates.asObservable();

  public notifyLoggedInUserUpdate() {
    this.loggedInUserUpdates.next();
  }
}
