import { UserService } from 'src/_services/user.service';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { LoaderService } from '@citadel/common-frontend/_services/loader.service';
import { SocketService } from '@citadel/common-frontend/_services/socket.service';
import { AnalyticsService } from '@citadel/common-frontend/_services/analytics.service';
import { VersionService } from '../_services/version.service';
import { CampaignService } from 'src/_services/campaign.service';
import { AdService } from 'src/_services/ad.service';
import { NetworkService } from 'src/_services/network.service';
import { FilesystemService } from '@citadel/common-frontend/_services/filesystem.service';
import { BillingService } from 'src/_services/billing.service';
import { NetworkGroupService } from 'src/_services/network-group.service';

let loaded = false;

@Injectable()
export class AuthenticatedGuard implements CanActivate {
	constructor(
		private router: Router,
		private analytics: AnalyticsService,
		private loader: LoaderService,
		private socket: SocketService,
		private version: VersionService,
		private user: UserService,
		private campaign: CampaignService,
		private filesystem: FilesystemService,
		private billing: BillingService,
		private networkGroup: NetworkGroupService,
		private ad: AdService,
		private network: NetworkService
	) { }

	canActivate(route: ActivatedRouteSnapshot): Promise<any> {
		const promise = new Promise(async (resolve, reject) => {
			if (loaded) {
				this.loader.setLoading(false);
				resolve(true);
				return;
			}

			try {
				await this.socket.init(environment.api.url, null, environment.production).toPromise();
				await this.user.init(true).toPromise();
				await this.analytics.init(environment.analytics.google.id).toPromise();

				const completion = Promise.all([
					this.version.init().toPromise(),
					this.campaign.init().toPromise(),
					this.ad.init().toPromise(),
					this.network.init().toPromise(),
					this.billing.init().toPromise(),
					this.networkGroup.init().toPromise(),
					this.filesystem.init(environment.api.url).toPromise()
				]);

				completion.then(() => {
					// hacky but necessary
					const element = document.getElementById('cdm-preload');
					if (element) {
						element.remove();
					}

					this.loader.setLoading(false);
					loaded = true;

					resolve(true);
				});
			} catch (e) {
				// invalidate session
        console.error("cc-trace-a484-592a1747eec0", e);
				this.user.logout();
        resolve(false);
			}
		});

		return promise;
	}
}

export function markForChange() {
	loaded = false;
}
