import { ComponentModalConfig, ModalSize } from 'ng2-semantic-ui';
import IInputModalContext from './input.interface';
import { InputModalComponent } from './input.modal';

export class InputModal extends ComponentModalConfig<IInputModalContext, string, void> {
	constructor(
    header: string,
    message: { header: string, text: string } | undefined,
    input: { label: string },
    sections?:{label: string, value: string}[]
    ) {
		super(InputModalComponent, { header, message, input, sections });
		this.isClosable = false;
		this.transitionDuration = 200;
		this.size = ModalSize.Small;

	}
}
