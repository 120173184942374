import { NgPipesModule } from 'angular-pipes';
import { NotificationsService, SimpleNotificationsModule } from 'angular2-notifications';
import { SuiModule } from 'ng2-semantic-ui';
import { ContextMenuModule } from 'ngx-contextmenu';
import { CookieService } from 'ngx-cookie-service';
import { FileDropModule } from 'ngx-file-drop';
import { MomentModule } from 'ngx-moment';
import { UserService } from 'src/_services/user.service';

import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { BuilderService } from '@citadel/common-frontend/_services/builder.service';
import { FilesystemService } from '@citadel/common-frontend/_services/filesystem.service';
import { LoaderService } from '@citadel/common-frontend/_services/loader.service';
import { NotificationService } from '@citadel/common-frontend/_services/notification.service';
import { SocketService } from '@citadel/common-frontend/_services/socket.service';
import { SharedModule } from '@citadel/common-frontend/shared.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { VersionService } from '../_services/version.service';
import { AppComponent } from './app.component';
import { routes } from './app.routes';
import { AuthenticatedGuard } from 'src/_guards/authenticated.guard';
import { UnauthenticatedGuard } from 'src/_guards/unauthenticated.guard';
import { CampaignService } from 'src/_services/campaign.service';
import { environment } from 'src/environments/environment';
import { AgmCoreModule } from '@agm/core';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { NetworkService } from 'src/_services/network.service';
import { AdService } from 'src/_services/ad.service';
import { BillingService } from 'src/_services/billing.service';
import { ModalModule } from 'src/_modals/modal.module';
import { StatisticService } from 'src/_services/statistic.service';
import { TokenModule } from 'src/token/token.module';
import { NetworkGroupService } from 'src/_services/network-group.service';
import { FormsModule } from '@angular/forms';  // Import FormsModule


@NgModule({
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		RouterModule,
		HttpClientModule,
		RouterModule.forRoot(routes),
		// library modules
		SimpleNotificationsModule.forRoot(),
		FileDropModule,
		SuiModule,
		MomentModule,
		NgPipesModule,
		SharedModule,
		TokenModule,
		ModalModule,
		NgxChartsModule,
		AgmCoreModule.forRoot({ apiKey: environment.keys.google.maps, libraries: ['visualization'] }),
		AgmJsMarkerClustererModule,
		ContextMenuModule.forRoot(),
    FormsModule
	],
	providers: [
		CookieService,
		SocketService,
		VersionService,
		NotificationService,
		UserService,
		LoaderService,
		NotificationsService,
		NotificationService,
		CampaignService,
		BillingService,
		StatisticService,
		NetworkService,
		AdService,
		FilesystemService,
		BuilderService,
		CampaignService,
		NetworkGroupService,
		AuthenticatedGuard,
		UnauthenticatedGuard,
	],
	declarations: [AppComponent],
	bootstrap: [AppComponent],
})
export class AppModule { }
