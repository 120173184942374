import {SuiModal} from 'ng2-semantic-ui';
import {Component} from '@angular/core';
import IInputModalContext from './searchResults.interface';
import {flattenArrayFilterOutUndefined} from '../../_util/functions';
import {OnInit, OnDestroy, ViewChild, SimpleChanges, ChangeDetectorRef} from '@angular/core';
import {NotificationService} from '../../_services/notification.service';
import { Pipe, PipeTransform, Optional } from '@angular/core';


interface Section {
  label: string;
  value: string;
}

interface Ctx<T> {
  searchTerms: string,
  chosenResult: T,
  sections: Section[];
  searchResults: T[],
  updateResults: (searchTerms: string, v: T[]) => Promise<T[]>
}


@Pipe({
  name: 'customTransform' // The name you'll use in the template
})
export class CustomTransformPipe implements PipeTransform {
  // constructor(@Optional() private m?: any) {
  //   if (m) {
  //     this.transform = m;
  //   }
  // }
  transform(value: any, ...args: any[]): any {
    // Implement your transformation logic
    // Example: Capitalize all string values in an object

    if(!value){
      return 'Err:cc-trace-b60c-f2b954cb12da: Unknown value.'
    }
    if(value.description){
      return value.description;
    }
    return value.summary || value.addressString || JSON.stringify({ccAddress: value});
  }
}

@Component({
  selector: 'app-modal-input',
  templateUrl: './searchResults.modal.html',
  styleUrls: ['./searchResults.modal.scss'],
})
export class SearchResultsModalComponent<T> {
  public header: string;
  public message: { header: string; text: string } | undefined;
  private toggleDetectChange = true;
  private debounceTimeout: any = null;

  public ctx: Ctx<T> = {
    chosenResult: null,
    searchTerms: '',
    sections: [],
    searchResults: [],
    updateResults(searchTerms: string, v: T[]) {
      // defined here as an example, you should pass your own updateResults function
      return Promise.resolve([...v]);
    }
  };

  constructor(
    public modal: SuiModal<IInputModalContext<T>, T, void>,
    private cdr: ChangeDetectorRef,
    private notifs: NotificationService
  ) {
    this.header = modal.context.header;
    this.message = modal.context.message;
    this.ctx.sections = flattenArrayFilterOutUndefined([
      modal.context.sections,
    ]);
    this.ctx.searchResults = flattenArrayFilterOutUndefined([
      modal.context.searchResults
    ]);
    this.ctx.updateResults = modal.context.updateResults;
    this.ctx.searchTerms = modal.context.input.initialValue;

  }

  public hasValue(): boolean {
    return !!this.ctx.chosenResult;
  }

  public async save() {
    // this.cdr.detectChanges();
    if(!this.ctx.chosenResult){
      this.notifs.displayInfo('No option is selected.')
      return;
    }
    this.ctx.searchTerms = '';
    // this.cdr.detectChanges();
    this.modal.approve(this.ctx.chosenResult);
  }

  public onDropdownSelect(result: T): void {
    this.ctx.chosenResult = result;
    console.log('Selected result:', result);
  }

  public async onInputChange(ev: Event): Promise<void> {

    const inputElement = ev.target as HTMLInputElement;
    const searchTerm = inputElement && inputElement.value;

    if (this.ctx.searchTerms === searchTerm) {
      // No change in value, skip processing
      return;
    }

    clearTimeout(this.debounceTimeout);
    this.ctx.searchTerms = searchTerm;

    this.debounceTimeout = setTimeout(async () => {

      this.ctx.searchTerms = searchTerm;

      try {
        this.ctx.searchResults = await this.ctx.updateResults(searchTerm,
          flattenArrayFilterOutUndefined([this.ctx.searchResults])
        );
        console.log('Updated searchResults:', this.ctx.searchResults);
      } catch (error) {
        console.error('Error updating search results:', error);
        this.notifs.displayError('There was an issue updating the search results.');
      }

      console.log('Updated searchResults:', this.ctx.searchResults);
      if(this.toggleDetectChange){
        this.toggleDetectChange = false;
        // this.cdr.detectChanges();
      } else {
        this.toggleDetectChange = true;
      }

    }, 300);

  }

  public async refreshModal (){
    // this.cdr.detectChanges();
  }

}
