import { Observable } from 'rxjs';
import * as io from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';
import { NgZone } from '@angular/core';
import { compress, decompress } from '../_util/compression';
import * as i0 from "@angular/core";
export class SocketService {
    constructor(zone) {
        this.zone = zone;
        this.registeredTaggedEvents = {};
        this.registeredEvents = {};
    }
    init(endpointBaseUrl, target, rejectUnauthorized = true) {
        return new Observable((observer) => {
            const socketConnectionOpts = {
                forceNew: true,
                transports: ['websocket'],
                rejectUnauthorized: rejectUnauthorized,
                query: {
                    target: target,
                },
            };
            const connectionUrl = endpointBaseUrl;
            this.socket = io(connectionUrl, socketConnectionOpts);
            this.socket.on('connect', async () => {
                observer.next(this.socket);
                observer.complete();
            });
            this.socket.on('error', (e) => {
                console.error(e);
                observer.error(e);
                observer.complete();
            });
            this.socket.on('data', (payload) => {
                const { event, tag, data, success } = decompress(payload);
                console.log(`Got ${event} response!`);
                if (tag) {
                    if (this.registeredTaggedEvents[tag]) {
                        this.registeredTaggedEvents[tag](success, data);
                    }
                }
                else {
                    if (this.registeredEvents[event]) {
                        this.registeredEvents[event](data);
                    }
                }
            });
        });
    }
    getTargetString() {
        if (window.location.host.indexOf('localhost') !== -1 || window.location.host.indexOf('127.0.0.1') !== -1) {
            return 'local';
        }
        const splitHost = window.location.host.split('.');
        return splitHost[0];
    }
    call(event, callback = undefined, ...args) {
        return new Observable((observer) => {
            const uuidTag = uuidv4();
            this.registerTaggedEvent(uuidTag, (success, data) => {
                if (!success) {
                    observer.error(data);
                    return;
                }
                if (callback) {
                    this.zone.run(() => {
                        callback(data);
                    });
                }
                this.zone.run(() => {
                    observer.next(data);
                    observer.complete();
                });
            });
            const payload = { event: event, tag: uuidTag, data: args };
            const compressedPayload = compress(payload);
            console.log(`Sending ${event}...`);
            this.getSocketConnection().emit('data', compressedPayload);
        });
    }
    registerEvent(event, callback) {
        this.registeredEvents[event] = callback;
    }
    unregisterEvent(event) {
        delete this.registeredEvents[event];
    }
    registerTaggedEvent(tag, callback) {
        this.registeredTaggedEvents[tag] = callback;
    }
    unregisterTaggedEvent(tag) {
        delete this.registeredTaggedEvents[tag];
    }
    getSocketConnection() {
        return this.socket;
    }
}
SocketService.ngInjectableDef = i0.defineInjectable({ factory: function SocketService_Factory() { return new SocketService(i0.inject(i0.NgZone)); }, token: SocketService, providedIn: "root" });
