import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PermissionsUpdateService {
  private permissionsUpdated = new Subject<void>();

  public permissionsUpdated$ = this.permissionsUpdated.asObservable();

  public notifyNetworkGroupsUpdate() {
    // this.permissionsUpdated.next();
  }
}
