import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
export class AdsUpdateService {
    constructor() {
        this.adsUpdated = new Subject();
        this.adsUpdated$ = this.adsUpdated.asObservable();
    }
    notifyAdsUpdate() {
        this.adsUpdated.next();
    }
}
AdsUpdateService.ngInjectableDef = i0.defineInjectable({ factory: function AdsUpdateService_Factory() { return new AdsUpdateService(); }, token: AdsUpdateService, providedIn: "root" });
