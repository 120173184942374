import { runCollectionFilter } from '../../_util/functions';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {NotificationService} from '../../_services/notification.service';

@Component({
	selector: 'app-table',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {
	@Input()
	public tableId = '';

	@Input()
	public items: any[] = [];

  @Input()
  public fetchItems: () => Promise<any[]>;

  @Input()
	public columns: any[] = [];

	@Input()
	public pageSize = 10e9;

	@Input()
	public actions: any[] = [];

	@Input()
	public getStatus: Function;

	@Input()
	public isItemSelected: Function;

	@Input()
	public showId = false;

	@Input()
	public parent: Component;

	@Output()
	public onRowSelect: EventEmitter<any> = new EventEmitter();

	@Output()
	public onView: EventEmitter<any> = new EventEmitter();

	@Output()
	public onDelete: EventEmitter<any> = new EventEmitter();

	@Output()
	public onCheckChange: EventEmitter<any> = new EventEmitter();

	public visibleItems: any[] = [];
	public filter: any = {};
	public page = 0;

	public visiblePages: number[] = [];
	public showNavigateBackEllipsis = false;
	public showNavigateForwardEllipsis = false;

	private pageCountToShow = 5;
	private visibleItemsLength: number;

  private isRefreshing = false;
  private isDestroyed = false;
  private refreshTimeout: any = null;

  private tableAction = Promise.resolve();

	constructor(private notifs: NotificationService) { }

	ngOnInit() {
		this.viewPage(0);
	}

  async refresh() {

    if (!this.fetchItems) {
      this.viewPage(0); // Refresh the table view with updated items
      return;
    }

    try {
      // Fetch new data from the parent function
      this.items = await this.fetchItems();
      this.viewPage(0); // Refresh the table view with updated items
    } catch (err) {
      console.error('cc-trace-ab6f-07ee9ff3b7f5', 'Failed to fetch items:', err);
      this.notifs.displayError('Failed to refresh table.');
    }

  }

	public toggleIDs(): void {
		this.showId = !this.showId;
	}

	public getHeaderFillerColumnCount(): number {
		let onViewColumns = this.onView.observers.length > 0 ? 1 : 0;
		let actionsColumns = this.actions.length;
		let deleteColumns = this.onDelete.observers.length > 0 ? 1 : 0;
		let offset = 1;

		return (onViewColumns + actionsColumns + deleteColumns) - offset;
	}

	public getRowClasses(item: any): any {
		let classes = {};

		classes['cdm-table__selectable-row'] = (this.onRowSelect.observers.length > 0);

		let status = this.checkItemStatus(item);

		switch (status) {
			case 'positive':
				classes['left'] = true;
				classes['green'] = true;
				classes['marked'] = true;
				break;
			case 'warning':
				classes['left'] = true;
				classes['orange'] = true;
				classes['marked'] = true;
				break;
			case 'negative':
				classes['left'] = true;
				classes['red'] = true;
				classes['marked'] = true;
				break;
			case 'info':
				classes['left'] = true;
				classes['primary'] = true;
				classes['marked'] = true;
				break;
			case 'notice':
				classes['left'] = true;
				classes['secondary'] = true;
				classes['marked'] = true;
				break;
		}

		return classes;
	}

	public checkItemStatus(item) {
		if (!this.getStatus) {
			return null;
		}
		return this.getStatus(item);
	}

	public getMaxPageCount() {
		return Math.ceil(this.visibleItemsLength / this.pageSize);
	}

	public nextPage() {
		if (this.page + 1 === this.getMaxPageCount()) {
			return;
		}
		this.viewPage(++this.page);
	}

	public previousPage() {
		if (this.page === 0) {
			return;
		}
		this.viewPage(--this.page);
	}

	public onForwardEllipsis() {
		const pageToNavigateTo = this.page + Math.ceil(this.pageCountToShow);
		if (pageToNavigateTo > this.getMaxPageCount()) {
			return this.viewPage(this.getMaxPageCount() - 1);
		}
		this.viewPage(pageToNavigateTo);
	}

	public onBackEllipsis() {
		const pageToNavigateTo = this.page - Math.ceil(this.pageCountToShow);
		if (pageToNavigateTo < 0) {
			return this.viewPage(0);
		}
		this.viewPage(pageToNavigateTo);
	}

  private runTimeout() {
    clearTimeout(this.refreshTimeout);
    if (this.isDestroyed) {
      return;
    }
    this.refreshTimeout = setTimeout(() => this.runFilter(), 500);
  }

	public async viewPage(page: number) {

    if (this.isDestroyed) {
      return;
    }

    if (this.isRefreshing) {
      console.warn('[runFilter]: Skipped re-entrant viewPage call.');
      this.runTimeout();
      return;
    }

    this.isRefreshing = true;

    try {

      this.page = page;
      this.runFilter();

      this.showNavigateBackEllipsis = true;
      this.showNavigateForwardEllipsis = true;

      let lowerLimit = this.page - Math.floor(this.pageCountToShow / 2);
      let upperLimit = this.page + Math.ceil(this.pageCountToShow / 2);
      if (lowerLimit <= 0) {
        upperLimit = Math.min(this.getMaxPageCount(), upperLimit - lowerLimit);
        lowerLimit = 0;
      }
      if (upperLimit >= this.getMaxPageCount()) {
        lowerLimit = Math.max(0, this.getMaxPageCount() - this.pageCountToShow);
        upperLimit = this.getMaxPageCount();
      }
      this.visiblePages = [];
      for (let limit = lowerLimit; limit < upperLimit; limit++) {
        this.visiblePages.push(limit);
      }

      if (this.visiblePages.length === 0) {
        this.showNavigateBackEllipsis = false;
        this.showNavigateForwardEllipsis = false;
      }

      if (this.visiblePages[0] === 0) {
        this.showNavigateBackEllipsis = false;
      }

      if (this.visiblePages[this.visiblePages.length - 1] + 1 === this.getMaxPageCount()) {
        this.showNavigateForwardEllipsis = false;
      }
    } catch(err){
      console.error('cc-trace-8f10-7dcd869d123d', err);
      this.notifs.displayError('Could not refresh table.');
    } finally {
      this.isRefreshing = false;
    }
	}

	public onSearchChange() {
		this.viewPage(0);
	}

	public onInternalItemCheckChange($event, item) {
		if (this.onCheckChange) {
			this.onCheckChange.emit({ value: !!$event, item: item });
		}
	}

	public runFilter(): void {
		const { data, totalAmount } = runCollectionFilter(this.items, this.filter, this.page, this.pageSize);
		this.visibleItems = data;
		this.visibleItemsLength = totalAmount;
	}

	public getEffectiveColumnCount() {
		let columnCount = this.columns.length;

		if (this.onView.observers.length > 0) {
			columnCount++;
		}

		if (this.onDelete.observers.length > 0) {
			columnCount++;
		}

		if (this.onCheckChange.observers.length > 0) {
			columnCount++;
		}

		if (this.showId) {
			columnCount++;
		}

		columnCount += this.actions.length;
		return columnCount;
	}

	public sanitizeCellValue(value: any) {
		if (value === null || value === undefined || value === 'undefined' || value === 'null') {
			return 'N/A';
		}
		return value.toString();
	}

	public clearFilter() {
		this.filter = {};
		this.viewPage(0);
	}

	public onItemClicked(item: any) {
		if (this.onRowSelect) {
			this.onRowSelect.emit(item);
		}
	}

	async viewItem(item: any) {
		if (this.onView) {
			this.onView.emit(item);
		}
	}

	async deleteItem(item: any) {
		if (this.onDelete) {
			this.onDelete.emit(item);
		}
	}

	public isInternalItemSelected(item: any) {
		if (this.isItemSelected) {
			return this.isItemSelected(item, this.parent);
		} else {
			return false;
		}
	}

	public isSharedResource(item: any) {
		return false;
	}
}
