import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CampaignsUpdateService {
  private campaignsUpdated = new Subject<void>();

  public campaignsUpdated$ = this.campaignsUpdated.asObservable();

  public notifyCampaignsUpdate() {
    this.campaignsUpdated.next();
  }
}
