import {SuiModal} from 'ng2-semantic-ui';

import {Component, ViewChild, ChangeDetectorRef} from '@angular/core';

import {FSComponent} from '../../_components/fs/fs.component';
import {PermissionService} from '../../_services/permission.service';
import IFilePickerModalContext from './filePicker.interface';
import {validateMimetype} from '../../_util/files';

@Component({
  selector: 'app-modal-file-picker',
  templateUrl: './filePicker.modal.html',
  styleUrls: ['./filePicker.modal.scss'],
})
export class FilePickerModalComponent {
  @ViewChild('picker') picker: FSComponent;
  public type: string;

  public supportsMultiple: boolean;
  public sessionToken: string;
  public showUploadNew = true;
  private selectedResources;

  constructor(
    public modal: SuiModal<IFilePickerModalContext, void, void>,
    public permissionService: PermissionService,
    private cdr: ChangeDetectorRef,
    ) {
    this.type = this.modal.context.type;
    this.supportsMultiple = this.modal.context.supportsMultiple;
    this.showUploadNew = this.modal.context.showUploadNew;
    this.sessionToken = this.modal.context.sessionToken;
    this.selectedResources = [];
    this.initializeForm();
  }

  private initializeForm() {
  }

  public onSelectChange($event: any) {
    this.selectedResources = $event;
    this.cdr.detectChanges();
    setTimeout(() => {
      this.cdr.detectChanges();
    },10);
  }

  public uploadFile() {
    this.picker.upload();
  }

  public readyToSelect() {
    const invalidMimeType = !!this.selectedResources.find((resource) => !validateMimetype(resource.mimeType, this.type));
    if (this.selectedResources.length === 0 || invalidMimeType) {
      return false;
    }
    if (!this.supportsMultiple && this.selectedResources.length > 1) {
      return false;
    }
    return true;
  }

  public chooseFile() {
    this.modal.approve(this.selectedResources);
  }
}
