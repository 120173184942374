import { SuiModalService } from 'ng2-semantic-ui';
import * as i0 from "@angular/core";
import * as i1 from "ng2-semantic-ui/dist/modules/modal/services/modal.service";
export class ModalService {
    constructor(suiModalService) {
        this.suiModalService = suiModalService;
        this.modals = [];
    }
    open(modal) {
        modal.previousSelected = [];
        const createdModal = this.suiModalService.open(modal);
        this.modals.push(createdModal);
        return createdModal;
    }
    closeAllModals() {
        for (const modal of this.modals) {
            modal.deny(undefined);
        }
    }
    refreshAllModals() {
        // TODO: not really close, but later will fix
        for (const modal of this.modals) {
            if (typeof modal.refreshModal === 'function') {
                modal.refreshModal(undefined);
            }
        }
    }
}
ModalService.ngInjectableDef = i0.defineInjectable({ factory: function ModalService_Factory() { return new ModalService(i0.inject(i1.SuiModalService)); }, token: ModalService, providedIn: "root" });
