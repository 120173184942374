import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import Events from '../_util/events';
import { SocketService } from './socket.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./socket.service";
export class FilesystemService {
    constructor(http, socketService) {
        this.http = http;
        this.socketService = socketService;
    }
    init(baseUrl) {
        return new Observable((observer) => {
            observer.next();
            this.endpointBaseUrl = baseUrl;
            observer.complete();
        });
    }
    upload(file, parent, sessionToken, name) {
        const formData = new FormData();
        formData.append('file', file, name);
        formData.append('parent', parent ? parent._id : '');
        return this.http.post(`${this.endpointBaseUrl}/api/upload`, formData, {
            reportProgress: true,
            observe: 'events',
            headers: {
                ['x-citadel-session']: sessionToken,
                ['x-target']: this.socketService.getTargetString(),
            },
        });
    }
    uploadBlob(blob, name, parent, sessionToken) {
        const formData = new FormData();
        formData.append('file', blob, name);
        formData.append('parent', parent ? parent._id : '');
        return this.http.post(`${this.endpointBaseUrl}/api/upload`, formData, {
            reportProgress: true,
            observe: 'events',
            headers: {
                ['x-citadel-session']: sessionToken,
                ['x-target']: this.socketService.getTargetString(),
            },
        });
    }
    search(filter) {
        return this.socketService.call(Events.SEARCH_IN_FILESYSTEM, undefined, filter);
    }
    edit(type, resource, config) {
        return this.socketService.call(Events.EDIT_IN_FILESYSTEM, undefined, resource._id, type, config);
    }
    copy(type, resource, destination) {
        return this.socketService.call(Events.COPY_IN_FILESYSTEM, undefined, resource._id, type, destination ? destination._id : null);
    }
    move(type, resource, destination) {
        return this.socketService.call(Events.MOVE_IN_FILESYSTEM, undefined, resource._id, type, destination ? destination._id : null);
    }
    delete(type, resource) {
        return this.socketService.call(Events.DELETE_IN_FILESYSTEM, undefined, resource._id, type);
    }
    makeDirectory(directoryName, parent, type = 'user') {
        return this.socketService.call(Events.MAKE_DIRECTORY_IN_FILESYSTEM, undefined, directoryName, parent ? parent._id : null, type);
    }
    getDirectoryDetails(directory) {
        return this.socketService.call(Events.GET_DIRECTORY_DETAILS_IN_FILESYSTEM, undefined, directory ? directory._id : null);
    }
    getAllChildrenFiles(directory) {
        return this.socketService.call(Events.GET_CHILDREN_FILES_IN_FILESYSTEM, undefined, directory);
    }
    getDirectoryByPath(path) {
        return this.socketService.call(Events.GET_DIRECTORY_BY_PATH_IN_FILESYSTEM, undefined, path);
    }
    getFileDetails(fileId) {
        return this.socketService.call(Events.GET_FILE_DETAILS_IN_FILESYSTEM, undefined, fileId);
    }
    checkForPath(path) {
        return this.socketService.call(Events.CHECK_FOR_PATH_IN_FILESYSTEM, undefined, path);
    }
}
FilesystemService.ngInjectableDef = i0.defineInjectable({ factory: function FilesystemService_Factory() { return new FilesystemService(i0.inject(i1.HttpClient), i0.inject(i2.SocketService)); }, token: FilesystemService, providedIn: "root" });
