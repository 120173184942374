import { Observable } from 'rxjs';
import Events from '../_util/events';
import { SocketService } from './socket.service';
import * as i0 from "@angular/core";
import * as i1 from "./socket.service";
export class BuilderService {
    constructor(socketService) {
        this.socketService = socketService;
    }
    init() {
        return new Observable((observer) => {
            observer.next();
            observer.complete();
        });
    }
    generateVideo(config) {
        return this.socketService.call(Events.GENERATE_VIDEO, undefined, config);
    }
    checkVideoStatus(id) {
        return this.socketService.call(Events.CHECK_VIDEO_STATUS, undefined, id);
    }
    getVideoLink(id) {
        return this.socketService.call(Events.GET_VIDEO_LINK, undefined, id);
    }
    getProjects() {
        return this.socketService.call(Events.LIST_PROJECTS, undefined);
    }
    createProject(name, body) {
        return this.socketService.call(Events.CREATE_PROJECT, undefined, name, body);
    }
    deleteProject(projectId) {
        return this.socketService.call(Events.DELETE_PROJECT, undefined, projectId);
    }
    editProject(projectId, name, body) {
        return this.socketService.call(Events.EDIT_PROJECT, undefined, projectId, name, body);
    }
    getTemplates() {
        return this.socketService.call(Events.LIST_TEMPLATES, undefined);
    }
    createTemplate(name, body) {
        return this.socketService.call(Events.CREATE_TEMPLATE, undefined, name, body);
    }
    deleteTemplate(templateId) {
        return this.socketService.call(Events.DELETE_TEMPLATE, undefined, templateId);
    }
    editTemplate(templateId, name, body) {
        return this.socketService.call(Events.EDIT_TEMPLATE, undefined, templateId, name, body);
    }
}
BuilderService.ngInjectableDef = i0.defineInjectable({ factory: function BuilderService_Factory() { return new BuilderService(i0.inject(i1.SocketService)); }, token: BuilderService, providedIn: "root" });
