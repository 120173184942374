import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
export class AnalyticsService {
    constructor(document) {
        this.document = document;
        this.window = this.document.defaultView;
    }
    init(id) {
        return new Observable((observer) => {
            if (!id) {
                observer.next();
                observer.complete();
                return;
            }
            const script = this.document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
            this.document.head.appendChild(script);
            this.window['dataLayer'] = this.window['dataLayer'] || [];
            this.window['gtag'] = function () {
                this.window['dataLayer'].push(arguments);
            };
            this.window['gtag']('js', new Date());
            this.window['gtag']('config', id);
            observer.next();
            observer.complete();
        });
    }
    sendEvent(event, parameters) {
        this.window['gtag']('event', event, parameters);
    }
}
AnalyticsService.ngInjectableDef = i0.defineInjectable({ factory: function AnalyticsService_Factory() { return new AnalyticsService(i0.inject(i1.DOCUMENT)); }, token: AnalyticsService, providedIn: "root" });
