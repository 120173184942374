import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
export class CampaignsUpdateService {
    constructor() {
        this.campaignsUpdated = new Subject();
        this.campaignsUpdated$ = this.campaignsUpdated.asObservable();
    }
    notifyCampaignsUpdate() {
        this.campaignsUpdated.next();
    }
}
CampaignsUpdateService.ngInjectableDef = i0.defineInjectable({ factory: function CampaignsUpdateService_Factory() { return new CampaignsUpdateService(); }, token: CampaignsUpdateService, providedIn: "root" });
