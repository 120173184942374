import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
export class LoggedInUserUpdateService {
    constructor() {
        this.loggedInUserUpdates = new Subject();
        this.loggedInUserUpdates$ = this.loggedInUserUpdates.asObservable();
    }
    notifyLoggedInUserUpdate() {
        this.loggedInUserUpdates.next();
    }
}
LoggedInUserUpdateService.ngInjectableDef = i0.defineInjectable({ factory: function LoggedInUserUpdateService_Factory() { return new LoggedInUserUpdateService(); }, token: LoggedInUserUpdateService, providedIn: "root" });
