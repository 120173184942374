import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NetworkGroupUpdateService {
  private networkGroupsUpdated = new Subject<void>();

  public networkGroupsUpdated$ = this.networkGroupsUpdated.asObservable();

  public notifyNetworkGroupsUpdate() {
    this.networkGroupsUpdated.next();
  }
}
