import * as i0 from "@angular/core";
export class PreferencesService {
    constructor() {
        this.PREFERENCE_PREFIX = 'preference';
    }
    setPreference(key, value) {
        return localStorage.setItem(this.generateStorageKey(key), value);
    }
    getPreference(key, fallback = undefined) {
        return localStorage.getItem(this.generateStorageKey(key)) || fallback;
    }
    generateStorageKey(key) {
        return [this.PREFERENCE_PREFIX, ...key.map(x => x.trim())].join('/');
    }
}
PreferencesService.ngInjectableDef = i0.defineInjectable({ factory: function PreferencesService_Factory() { return new PreferencesService(); }, token: PreferencesService, providedIn: "root" });
