import { NgPipesModule } from 'angular-pipes';
import { SuiModule } from 'ng2-semantic-ui';
import { ContextMenuModule } from 'ngx-contextmenu';
import { CookieService } from 'ngx-cookie-service';
import { MomentModule } from 'ngx-moment';
import { ComponentModule } from 'src/_components/component.module';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SelectSearchModalComponent } from '@citadel/common-frontend/_modals/selectSearch/selectSearch.modal';
import {SharedModule} from '@citadel/common-frontend/shared.module';

const MODALS = [
	SelectSearchModalComponent,
];

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    CommonModule,
    ComponentModule,
    ContextMenuModule,
    MomentModule.forRoot({
      relativeTimeThresholdOptions: {
        ss: 1,
      },
    }),
    SuiModule,
    NgPipesModule,
    CommonModule,
    SharedModule,
  ],
	providers: [CookieService],
	declarations: [...MODALS],
	exports: [...MODALS],
	entryComponents: [...MODALS],
})
export class ModalModule { }
