import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ScreensUpdateService {
  private screensUpdated = new Subject<void>();

  screensUpdated$ = this.screensUpdated.asObservable();

  notifyScreensUpdated() {
    this.screensUpdated.next();
  }
}
