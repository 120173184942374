import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NetworkUpdateService {
  private networksUpdated = new Subject<void>();

  public networksUpdated$ = this.networksUpdated.asObservable();

  public notifyNetworksUpdate() {
    this.networksUpdated.next();
  }
}
