import { NotificationsService } from 'angular2-notifications';
import * as i0 from "@angular/core";
import * as i1 from "angular2-notifications";
export class NotificationService {
    constructor(notifications) {
        this.notifications = notifications;
    }
    conditionalClear(override) {
        if (override && override['clearAllPreviousNotifs'] === true) {
            this.clearNotifications();
        }
    }
    displayError(error, override = {}) {
        console.error('cc-trace-9690-d879f7d59c95', error);
        this.conditionalClear(override);
        this.notifications.error('Oops', error, override);
    }
    displaySuccess(success, override = {}) {
        this.conditionalClear(override);
        this.notifications.success('Success', success, override);
    }
    displayInfo(message, override = {}) {
        this.conditionalClear(override);
        this.notifications.info('Info', message, override);
    }
    clearNotifications() {
        this.notifications.remove();
    }
}
NotificationService.ngInjectableDef = i0.defineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.inject(i1.NotificationsService)); }, token: NotificationService, providedIn: "root" });
