<div class="cdm-video-creator" [ngClass]="{ 'cdm-video-creator--grabbing': dragging || transforming }" #builder>
  <div class="cdm-video-creator__global-toolbar">
    <div class="cdm-video-creator__global-toolbar-content">
      <div class="cdm-video-creator__global-toolbar-content-left">
        <div class="cdm-video-creator__global-toolbar-brand">
          <i class="file video icon"></i>
        </div>

        <div class="ui inverted dropdown" suiDropdown>
          <div class="text">Project</div>
          <i class="dropdown icon"></i>
          <div class="menu" suiDropdownMenu>
            <div class="item" (click)="onCreateNewProject()">
              <i class="plus icon"></i>
              New
            </div>
            <div class="item" suiDropdown *ngIf="projects && projects.length !== 0">
              <i class="folder open icon"></i>
              Load
              <i class="dropdown icon"></i>
              <div class="menu" suiDropdownMenu>
                <div class="item" *ngFor="let project of projects" (click)="onSelectProject(project)">
                  <i class="file video icon"></i>
                  {{ project.name }}
                </div>
              </div>
            </div>
            <div class="item" [ngClass]="{ disabled: !canUpdateContent() }" (click)="saveProject(false)">
              <i class="save icon"></i>
              Save
            </div>
            <div class="item" [ngClass]="{ disabled: !canGenerateVideo() }" (click)="saveProject(true)">
              <i class="save icon"></i>
              Save as...
            </div>
            <div class="item" [ngClass]="{ disabled: !currentProject || (currentProject && !currentProject._id) }"
              (click)="deleteProject(currentProject)">
              <i class="trash icon"></i>
              Delete current project
            </div>
            <div class="item" suiDropdown>
              <i class="cogs icon"></i>
              Output
              <i class="dropdown icon"></i>
              <div class="menu" suiDropdownMenu>
                <div class="item" suiDropdown>
                  <i class="ruler horizontal icon"></i>
                  Orientation
                  <i class="dropdown icon"></i>
                  <div class="menu" suiDropdownMenu>
                    <div class="item" (click)="setOrientation('horizontal')">
                      <i class="arrows alternate horizontal icon"></i>
                      Horizontal (16:9)
                    </div>
                    <div class="item" (click)="setOrientation('vertical')">
                      <i class="arrows alternate vertical icon"></i>
                      Vertical (9:16)
                    </div>
                  </div>
                </div>
                <!-- <div class="item" suiDropdown>
                  <i class="tv icon"></i>
                  Resolution
                  <i class="dropdown icon"></i>
                  <div class="menu" suiDropdownMenu>
                    <div class="item" (click)="setResolution('hd')">
                      HD (720p)
                    </div>
                    <div class="item" (click)="setResolution('fhd')">
                      Full HD (1080p)
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>

        <div class="ui inverted dropdown" suiDropdown>
          <div class="text">Templates</div>
          <i class="dropdown icon"></i>
          <div class="menu" suiDropdownMenu>
            <div class="item" suiDropdown *ngIf="templates && templates.length !== 0">
              <i class="folder open icon"></i>
              Load
              <i class="dropdown icon"></i>
              <div class="menu" suiDropdownMenu>
                <div class="item" *ngFor="let template of templates" (click)="onSelectProject(template)">
                  <i class="file video icon"></i>
                  {{ template.name }}
                </div>
              </div>
            </div>
            <div class="item" [ngClass]="{ disabled: !canUpdateContent() }" (click)="saveTemplate(false)">
              <i class="save icon"></i>
              Save
            </div>
            <div class="item" [ngClass]="{ disabled: !canGenerateVideo() }" (click)="saveTemplate(true)">
              <i class="save icon"></i>
              Save as...
            </div>
            <div class="item" [ngClass]="{ disabled: !currentProject || (currentProject && !currentProject._id) }"
              (click)="deleteTemplate(currentProject)">
              <i class="trash icon"></i>
              Delete current template
            </div>
          </div>
        </div>
      </div>

      <div class="cdm-video-creator__global-toolbar-content-center">
        <ng-container *ngIf="currentProject">
          <span>{{ currentProject.name }}</span>
          <ng-container *ngIf="currentProject.type === 'template'">
            <div class="ui primary tag label">Template</div>
          </ng-container>
        </ng-container>
      </div>

      <div class="cdm-video-creator__global-toolbar-content-right">
        <ng-container *ngFor="let group of globalToolbar">
          <div class="cdm-video-creator__global-toolbar-divider"></div>
          <div class="ui icon basic inverted buttons">
            <button class="ui button" [ngClass]="{ disabled: action.disabled && executeToolbarAction(action.disabled) }"
              *ngFor="let action of group.actions" (click)="executeToolbarAction(action.execute)" suiPopup
              popupText="{{ action.tooltip }}">
              <i class="{{ action.icon }} icon inverted"></i> {{ action.label }}
            </button>
          </div>
        </ng-container>
        <div class="ui secondary button cdm-video-creator__global-toolbar-create"
          [ngClass]="{ disabled: !canGenerateVideo() }" (click)="generateVideo()">Generate Video</div>
      </div>
    </div>
  </div>
  <div class="cdm-video-creator__content">
    <div class="cdm-video-creator__sidebar">
      <div class="cdm-video-creator__sidebar-container">
        <div class="cdm-video-creator__sidebar-menu">
          <div class="cdm-video-creator__sidebar-element"
            [ngClass]="{ 'cdm-video-creator__sidebar-element--active': sidebarIsActive && sidebarActivity === 'elements' }"
            (click)="toggleSidebar('elements'); openLibrary(['image', 'video', 'graphic']);">
            <div class="cdm-video-creator__sidebar-icon">
              <i class="image file icon"></i>
            </div>
            <div class="cdm-video-creator__sidebar-label">Elements</div>
          </div>
          <div class="cdm-video-creator__sidebar-element"
            [ngClass]="{ 'cdm-video-creator__sidebar-element--active': sidebarIsActive && sidebarActivity === 'music' }"
            (click)="toggleSidebar('music'); openLibrary(['music'])">
            <div class="cdm-video-creator__sidebar-icon">
              <i class="music icon"></i>
            </div>
            <div class="cdm-video-creator__sidebar-label">Music</div>
          </div>
          <div class="cdm-video-creator__sidebar-element"
            [ngClass]="{ 'cdm-video-creator__sidebar-element--active': sidebarIsActive && sidebarActivity === 'templates' }"
            (click)="toggleSidebar('templates');">
            <div class="cdm-video-creator__sidebar-icon">
              <i class="images icon"></i>
            </div>
            <div class="cdm-video-creator__sidebar-label">Templates</div>
          </div>
          <!-- <div class="cdm-video-creator__sidebar-element"
            [ngClass]="{ 'cdm-video-creator__sidebar-element--active': sidebarIsActive && sidebarActivity === 'shapes' }"
            (click)="toggleSidebar('shapes');">
            <div class="cdm-video-creator__sidebar-icon">
              <i class="shapes icon"></i>
            </div>
            <div class="cdm-video-creator__sidebar-label">Shapes</div>
          </div> -->
          <div class="cdm-video-creator__sidebar-element" (click)="createTextElement()">
            <div class="cdm-video-creator__sidebar-icon">
              <i class="font icon"></i>
            </div>
            <div class="cdm-video-creator__sidebar-label">Text</div>
          </div>
        </div>
        <div class="cdm-video-creator__sidebar-content"
          [ngClass]="{ 'cdm-video-creator__sidebar-content--hidden': !sidebarIsActive }">
          <ng-container *ngIf="sidebarActivity === 'elements' || sidebarActivity === 'music'">
            <span class="cdm-video-creator__sidebar-header">
              <ng-container *ngIf="librarySearchTypes.length !== 1">
                Elements
              </ng-container>
              <ng-container *ngIf="librarySearchTypes.length === 1">
                <ng-container *ngIf="librarySearchTypes[0] === 'music'">
                  Music
                </ng-container>
                <ng-container *ngIf="librarySearchTypes[0] === 'image'">
                  Images
                </ng-container>
                <ng-container *ngIf="librarySearchTypes[0] === 'video'">
                  Video
                </ng-container>
                <ng-container *ngIf="librarySearchTypes[0] === 'graphic'">
                  Graphics
                </ng-container>
              </ng-container>
              <ng-container *ngIf="librarySearchTypes.length > 1 || sidebarActivity === 'music'">
                <button class="ui icon black button circular cdm-video-creator__sidebar-close"
                  (click)="closeSidebar()"><i class="angle left icon"></i></button>
              </ng-container>
              <ng-container *ngIf="librarySearchTypes.length === 1 && sidebarActivity !== 'music'">
                <button class="ui icon black button circular cdm-video-creator__sidebar-close"
                  (click)="openLibrary(['image', 'video', 'graphic']); searchMediaLibrary();"><i
                    class="angle double left icon"></i></button>
              </ng-container>
            </span>
            <div class="ui inverted left icon input cdm-video-creator__sidebar-search"
              [ngClass]="{ 'loading': librarySearchIsLoading }">
              <input type="text" [value]="librarySearchQuery" [formControl]="librarySearchFormControl"
                placeholder="Search...">
              <i class="search icon"></i>
            </div>
            <div class="cdm-video-creator__sidebar-library">
              <button class="ui fluid mini secondary button" (click)="openContentLibrary()">
                <i class="file icon"></i>
                Select from my Content Library
              </button>
            </div>
            <div class="cdm-video-creator__sidebar-results" infiniteScroll [infiniteScrollDistance]="2"
              [infiniteScrollThrottle]="50" [scrollWindow]="false"
              [infiniteScrollDisabled]="librarySearchTypes.length > 1" (scrolled)="getMediaLibraryResults()">
              <ng-container *ngIf="librarySearchIsLoading">
                <div class="cdm-video-creator__sidebar-result-container">
                  <div class="ui placeholder cdm-video-creator__sidebar-result-placeholder"
                    *ngFor="let number of [].constructor(LIBRARY_DETAILED_VIEW_PAGE_SIZE)">
                    <div class="image"></div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="getLibraryResultKeys().length === 1">
                <div class="cdm-video-creator__sidebar-tags" *ngIf="getCurrentLibraryResultTags().length > 0">
                  <a class="ui basic label" *ngFor="let tag of getCurrentLibraryResultTags()"
                    (click)="triggerLibrarySearch(tag)">{{ tag }}</a>
                </div>
              </ng-container>
              <ng-container *ngFor="let type of getLibraryResultKeys()">
                <ng-container *ngIf="librarySearchResults[type].length > 0">
                  <div class="cdm-video-creator__sidebar-subheader" *ngIf="getLibraryResultKeys().length > 1">
                    <ng-container *ngIf="type === 'image'">
                      <i class="camera retro icon"></i>
                      <span>Images</span>
                    </ng-container>
                    <ng-container *ngIf="type === 'video'">
                      <i class="file video icon"></i>
                      <span>Video</span>
                    </ng-container>
                    <ng-container *ngIf="type === 'graphic'">
                      <i class="paint brush icon"></i>
                      <span>Graphics</span>
                    </ng-container>

                    <a (click)="openLibrary([type])"><i class="angle double right large icon"></i></a>
                  </div>
                  <div class="cdm-video-creator__sidebar-result-container">
                    <div class="cdm-video-creator__sidebar-result" *ngFor="let result of librarySearchResults[type]"
                      [dndDraggable]="result" (click)="addMediaFromLibrary(result)">
                      <ng-container *ngIf="result.type === 'video'">
                        <i class="play circle icon cdm-video-creator__sidebar-result-play"></i>
                        <img [src]="result.thumbnail" />
                      </ng-container>
                      <ng-container *ngIf="result.type === 'image'">
                        <img [src]="result.thumbnail" />
                      </ng-container>
                      <ng-container *ngIf="result.type === 'graphic'">
                        <img [src]="result.thumbnail" />
                      </ng-container>
                      <ng-container *ngIf="result.type === 'music'">
                        <app-waveform [url]="result.url" [controls]="true"></app-waveform>
                      </ng-container>
                      <ng-container *ngIf="result.type !== 'music'">
                        <div class="cdm-video-creator__sidebar-result-author"
                          *ngIf="result.provider && result.attribution.author">
                          Provided by <a target="_blank" [href]="result.attribution.link">{{ result.attribution.author
                            }}</a>
                          <ng-container *ngIf="result.attribution.link">
                            on <a target="_blank" [href]="result.attribution.link"><u>{{ result.provider | titlecase
                                }}</u></a>
                          </ng-container>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="result.type === 'music'">
                        <div class="cdm-video-creator__sidebar-result-author"
                          *ngIf="result.provider && result.attribution.author">
                          {{ result.description }}
                          <ng-container *ngIf="result.attribution.link">
                            by <a target="_blank" [href]="result.attribution.link"><u>{{ result.attribution.author |
                                titlecase }}</u></a>
                          </ng-container>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </ng-container>
              </ng-container>

              <div *ngIf="getTotalLibraryResultCount() === 0 && !librarySearchIsLoading"
                class="cdm-video-creator__sidebar-result-empty">
                <span><i class="info circle icon"></i>No results found</span>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="sidebarActivity === 'templates'">
            <span class="cdm-video-creator__sidebar-header">
              Templates
              <button class="ui icon black button circular cdm-video-creator__sidebar-close" (click)="closeSidebar()"><i
                  class="angle left icon"></i></button>
            </span>
            <div class="ui inverted left icon input cdm-video-creator__sidebar-search">
              <input type="text" [value]="templateSearchQuery" [formControl]="templateSearchFormControl"
                placeholder="Search...">
              <i class="search icon"></i>
            </div>
            <div class="cdm-video-creator__sidebar-templates">
              <div class="cdm-video-creator__sidebar-template-list">
                <div class="cdm-video-creator__sidebar-template" *ngFor="let template of templates"
                  (click)="onSelectTemplate(template)">
                  <div class="cdm-video-creator__sidebar-template-content" [ngStyle]="getTemplateCSS(template)">
                    <ng-container *ngFor="let element of template.body.slides[0].elements">
                      <div class="cdm-video-creator__element"
                        [ngStyle]="element.generateCSS(getViewport(getOrientation(template.body)).template, resolutionOptions[getResolution(template.body)][getOrientation(template.body)])">
                        <ng-container *ngIf="element.type === 'image'">
                          <img
                            [ngStyle]="element.generateImageCSS(getViewport(getOrientation(template.body)).template, resolutionOptions[getResolution(template.body)][getOrientation(template.body)])"
                            [src]="element.previewUrl || element.value | safe" />
                        </ng-container>
                        <ng-container *ngIf="element.type === 'video'">
                          <video
                            [ngStyle]="element.generateVideoCSS(getViewport(getOrientation(template.body)).template, resolutionOptions[getResolution(template.body)][getOrientation(template.body)])"
                            [src]="element.previewUrl || element.value | safe" muted></video>
                        </ng-container>
                        <ng-container *ngIf="element.type === 'text'">
                          <p>{{ element.value }}</p>
                        </ng-container>
                        <ng-container *ngIf="element.type === 'shape'">
                          <div class="cdm-video-creator__element-shape" [innerHtml]="element.svg.rendered | safeHtml"
                            [ngStyle]="getRenderedSVGCSS(getViewport(getOrientation(template.body)).template)"></div>
                        </ng-container>
                      </div>
                    </ng-container>
                  </div>
                  <ng-container *ngIf="template.__global">
                    <div class="ui primary tiny label cdm-video-creator__sidebar-template-type">
                      <i class="star icon"></i> Citadel Template
                    </div>
                    <div class="cdm-video-creator__sidebar-template-name">{{ template.name }}</div>
                  </ng-container>
                  <div class="cdm-video-creator__sidebar-template-name">{{ template.name }}</div>
                </div>
              </div>
              <div *ngIf="templates.length === 0" class="cdm-video-creator__sidebar-result-empty">
                <span><i class="info circle icon"></i>No results found</span>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="sidebarActivity === 'shapes'">
            <span class="cdm-video-creator__sidebar-header">
              Shapes
              <button class="ui icon black button circular cdm-video-creator__sidebar-close" (click)="closeSidebar()"><i
                  class="angle left icon"></i></button>
            </span>
            <div class="cdm-video-creator__sidebar-shapes">
              <div class="cdm-video-creator__sidebar-shape-list">
                <div class="cdm-video-creator__sidebar-shape" *ngFor="let svg of shapes" (click)="addShape(svg)">
                  <div [innerHtml]="svg.model.rendered | safeHtml"></div>
                </div>
              </div>
              <div *ngIf="shapes.length === 0" class="cdm-video-creator__sidebar-result-empty">
                <span><i class="info circle icon"></i>No results found</span>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="cdm-video-creator__main">
      <div class="cdm-video-creator__toolbar">
        <div class="cdm-video-creator__toolbar-buttons">
          <ng-container *ngFor="let group of toolbar">
            <ng-container *ngIf="group.visible()">
              <ng-container *ngIf="group.type === 'button'">
                <div class="cdm-video-creator__toolbar-item ui icon basic buttons">
                  <ng-container *ngFor="let action of group.actions">
                    <button class="ui button"
                      [ngClass]="{ disabled: action.disabled && executeToolbarAction(action.disabled) }"
                      (click)="executeToolbarAction(action.execute)" suiPopup popupText="{{ action.tooltip }}">
                      <i class="{{ action.icon }} icon"></i>
                    </button>
                  </ng-container>
                </div>
              </ng-container>

              <ng-container *ngIf="group.type === 'color'">
                <ng-container *ngFor="let action of group.actions">
                  <div [cpOutputFormat]="'hex'" [cpAlphaChannel]="'forced'" [cpUseRootViewContainer]="false"
                    [cpPosition]="'bottom'" [colorPicker]="executeToolbarAction(action.getCurrentValue)"
                    (colorPickerChange)="executeToolbarAction(action.execute, [$event])"
                    class="ui labeled input cdm-video-creator__toolbar-item cdm-video-creator__color-picker">
                    <div class="cdm-video-creator__color-picker-label">
                      {{ action.label }}
                    </div>
                    <div class="cdm-video-creator__color-picker-display"
                      [style.background]="executeToolbarAction(action.getCurrentValue)"></div>
                  </div>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="group.type === 'dropdown'">
                <ng-container *ngFor="let action of group.actions">
                  <div class="cdm-video-creator__toolbar-icon" *ngIf="action.icon">
                    <i class="{{ action.icon }} icon"></i>
                  </div>
                  <sui-select class="cdm-video-creator__toolbar-item cdm-video-creator__selector"
                    [ngModel]="executeToolbarAction(action.getCurrentValue)" labelField="label" valueField="value"
                    [options]="executeToolbarAction(action.getRelevantPropertyType).options"
                    [optionTemplate]="optionTemplate"
                    (selectedOptionChange)="executeToolbarAction(action.execute, [$event])" #select>
                    <sui-select-option *ngFor="let option of select.filteredOptions"
                      [ngStyle]="executeToolbarAction(action.getToolbarContextualValue, ['style', option])"
                      [value]="option"></sui-select-option>
                  </sui-select>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="group.type === 'input'">
                <ng-container *ngFor="let action of group.actions">
                  <div class="cdm-video-creator__toolbar-item cdm-video-creator__input">
                    <div class="cdm-video-creator__input-label">
                      {{ action.label }}
                    </div>
                    <div class="ui input cdm-video-creator__input-value">
                      <input type="text" [ngModel]="executeToolbarAction(action.getCurrentValue)"
                        (ngModelChange)="executeToolbarAction(action.execute, [$event])">
                    </div>
                    <div class="cdm-video-creator__input-unit">
                      px
                    </div>
                  </div>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="group.type === 'file'">
                <ng-container *ngFor="let action of group.actions">
                  <div class="cdm-video-creator__toolbar-item cdm-video-creator__file-picker">
                    <button class="ui icon primary button" (click)="executeToolbarAction(action.execute)">
                      <i class="{{ action.icon }} icon"></i>
                      {{ action.label }}
                    </button>
                  </div>
                </ng-container>
              </ng-container>

              <!-- <div class="cdm-video-creator__toolbar-divider"></div> -->
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div class="cdm-video-creator__editor" #editor>
        <div class="cdm-video-creator__multiselect" [ngStyle]="getMultiselectCSS()" #selector>
        </div>

        <div class="cdm-video-creator__editor-container" dndDropzone (dndDrop)="onContainerDrop($event)"
          (dndDragover)="onContainerDragover($event)" oncontextmenu="return false" #container>
          <!-- <file-drop dropZoneLabel="" (onFileDrop)="droppedFile($event)">
          </file-drop> -->
          <div class="cdm-video-creator__editor-content" [ngStyle]="getEditorCSS()" [contextMenu]="elementMenu"
            [contextMenuSubject]="undefined"
            [style.background]="currentSlide ? currentSlide.background : defaultSlide.background" #content>
            <div class="cdm-video-creator__clamp cdm-video-creator__clamp--x" [ngStyle]="getClampXLineCSS()"></div>
            <div class="cdm-video-creator__clamp cdm-video-creator__clamp--y" [ngStyle]="getClampYLineCSS()"></div>
            <div class="cdm-video-creator__multiselect-transformer"
              [ngClass]="{ 'cdm-video-creator__multiselect-transformer--dragging': isDraggingSelectionMoveBox }"
              (mousedown)="onClickMultiselectTransformer($event)" [ngStyle]="getMultiselectTransformerCSS()"
              #selectorTransformer>
            </div>
            <ng-container *ngIf="currentSlide">
              <p class="cdm-video-creator__placeholder" *ngIf="currentSlide.elements.length === 0"><i
                  class="info circle icon"></i><i>Get started by adding an element!</i></p>
              <ng-container *ngFor="let element of currentSlide.elements">
                <div class="cdm-video-creator__element" [ngClass]="{
                    'cdm-video-creator__element--shape': element.type === 'shape',
                    'cdm-video-creator__element--multi-selected': isMultiselected(element),
                    'cdm-video-creator__element--hovered': hoveredElement === element,
                    'cdm-video-creator__element--selected': selectedElement === element,
                    'cdm-video-creator__element--dragging': selectedElement === element && dragging,
                    'cdm-video-creator__element--rotating': selectedElement === element && rotating,
                    'cdm-video-creator__element--shaping': selectedElement === element && shaping,
                    'cdm-video-creator__element--transforming': selectedElement === element && transforming
                  }"
                  [ngStyle]="element.generateCSS(getViewport().editor, resolutionOptions[getResolution(creation)][getOrientation(creation)])"
                  (mousedown)="onClickElement(element, $event)" [contextMenu]="elementMenu"
                  (mouseenter)="onHoverElement(element)"
                  (mouseleave)="onUnhoverElement(element)"
                  [contextMenuSubject]="element">
                  <div class="cdm-video-creator__rotation" [ngClass]=" {'cdm-video-creator__rotation--fill': element.type === 'image' || element.type === 'video' }" [ngStyle]="getElementRotationCSS(element)">
                    <ng-container *ngIf="element.type === 'image'">
                      <img
                        [ngStyle]="element.generateImageCSS(getViewport().editor, resolutionOptions[getResolution(creation)][getOrientation(creation)])"
                        [src]="element.previewUrl || element.value | safe" />
                    </ng-container>
                    <ng-container *ngIf="element.type === 'video'">
                      <video
                        [ngStyle]="element.generateVideoCSS(getViewport().editor, resolutionOptions[getResolution(creation)][getOrientation(creation)])"
                        [src]="element.previewUrl || element.value | safe" loop [volume]="0" autoplay></video>
                    </ng-container>
                    <ng-container *ngIf="element.type === 'text'">
                      <div class="cdm-video-creator__element-text">
                        <p contenteditable="plaintext-only" spellcheck="false" #text
                          (mousedown)="onStartEditingText(element, text)" (blur)="onEndEditingText(element, text)"
                          (onCopy)="$event.preventDefault()" (onPaste)="$event.preventDefault()">{{ element.value }}</p>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="element.type === 'shape'">
                      <div [innerHtml]="element.svg.rendered | safeHtml"
                        [ngStyle]="getRenderedSVGCSS(getViewport().editor)"></div>
                    </ng-container>
                  </div>
                  <ng-container *ngIf="element === selectedElement && element.type === 'shape'">
                    <div class="cdm-video-creator__element-move-box" *ngIf="!rotating"
                      (mousedown)="startDragging(element, $event)"></div>
                    <div class="cdm-video-creator__element-transform-box">
                      <ng-container *ngFor="let point of element.svg.points">
                        <ng-container *ngIf="point.transformable">
                          <div (mousedown)="startShaping($event, point)"
                            class="cdm-video-creator__element-transform-handle cdm-video-creator__element-transform-handle--shape"
                            [ngStyle]="getShapePointTransformerCSS(element, point)">
                            <div></div>
                          </div>
                        </ng-container>
                      </ng-container>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="element === selectedElement && element.type !== 'shape'">
                    <div class="cdm-video-creator__element-rotator" *ngIf="element === selectedElement && !rotating"
                      (mousedown)="onStartElementRotate(element, $event)">
                      <i class="sync alternate icon"></i>
                    </div>
                    <div class="cdm-video-creator__element-rotator-box" *ngIf="element === selectedElement && rotating"
                      [ngStyle]="getRotatorBoxCSS(element)">
                      <div class="cdm-video-creator__element-rotator-indicator">

                      </div>
                    </div>
                    <div class="cdm-video-creator__element-move-box" *ngIf="!rotating"
                      (mousedown)="startDragging(element, $event)"></div>
                    <div class="cdm-video-creator__element-transform-box"
                      *ngIf="element === selectedElement && !rotating">
                      <div (mousedown)="startTransforming($event, 0)"
                        class="cdm-video-creator__element-transform-handle cdm-video-creator__element-transform-handle--top cdm-video-creator__element-transform-handle--left">
                        <div></div>
                      </div>
                      <div (mousedown)="startTransforming($event, 1)"
                        class="cdm-video-creator__element-transform-handle cdm-video-creator__element-transform-handle--top cdm-video-creator__element-transform-handle--right">
                        <div></div>
                      </div>
                      <div (mousedown)="startTransforming($event, 2)"
                        class="cdm-video-creator__element-transform-handle cdm-video-creator__element-transform-handle--bottom cdm-video-creator__element-transform-handle--left">
                        <div></div>
                      </div>
                      <div (mousedown)="startTransforming($event, 3)"
                        class="cdm-video-creator__element-transform-handle cdm-video-creator__element-transform-handle--bottom cdm-video-creator__element-transform-handle--right">
                        <div></div>
                      </div>

                      <!-- <div (mousedown)="startTransforming($event, 4)" class="cdm-video-creator__element-transform-border cdm-video-creator__element-transform-border--top">

                      </div>
                      <div (mousedown)="startTransforming($event, 5)" class="cdm-video-creator__element-transform-border cdm-video-creator__element-transform-border--right">

                      </div>
                      <div (mousedown)="startTransforming($event, 6)" class="cdm-video-creator__element-transform-border cdm-video-creator__element-transform-border--bottom">

                      </div>
                      <div (mousedown)="startTransforming($event, 7)" class="cdm-video-creator__element-transform-border cdm-video-creator__element-transform-border--left">

                      </div> -->
                    </div>
                  </ng-container>
                  <div class="cdm-video-creator__element-hotbar" *ngIf="element === selectedElement && !rotating">
                    <div class="ui icon mini compact buttons cdm-video-creator__element-hotbar-buttons">
                      <ng-container *ngFor="let group of hotbar">
                        <ng-container *ngIf="group.visible()">
                          <ng-container *ngFor="let action of group.actions">
                            <button class="ui icon basic button"
                              [ngClass]="{ disabled: action.disabled && executeToolbarAction(action.disabled) }"
                              (click)="executeToolbarAction(action.execute)">
                              <i class="{{ action.icon }} icon"></i>
                            </button>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>

        <div class="cdm-video-creator__activity-bar">
          <div class="cdm-video-creator__activity-bar-container">
            <div class="cdm-video-creator__activity-bar-menu">
              <div class="cdm-video-creator__activity-bar-element"
                [ngClass]="{ 'cdm-video-creator__activity-bar-element--active': activityBarActivity === 'animate' && activityBarIsActive }"
                (click)="toggleActivityBar('animate')">
                <div class="cdm-video-creator__activity-bar-icon">
                  <i class="play circle icon"></i>
                </div>
                <div class="cdm-video-creator__activity-bar-label">Animate</div>
              </div>
            </div>
            <div class="cdm-video-creator__activity-bar-content"
              [ngClass]="{ 'cdm-video-creator__activity-bar-content--hidden': !activityBarIsActive }">
              <ng-container *ngIf="activityBarActivity === 'animate'">
                <div class="cdm-video-creator__animate" *ngIf="currentSlide">
                  <div class="cdm-video-creator__animation-step">
                    <span class="cdm-video-creator__animation-step-header">Slide animation</span>

                    <div class="ui form">
                      <div class="two fields">
                        <div class="field">
                          <label>Intro Transition <i *ngIf="isSlideIntroTransitionLinked(currentSlide)"
                              class="linkify icon"></i></label>
                          <div class="ui dropdown button" suiDropdown>
                            <div class="text">{{ getNestedOptionLabel(slideTransitions, currentSlide.transition.in) }}
                            </div>
                            <i class="dropdown icon"></i>
                            <div class="menu" suiDropdownMenu>
                              <ng-container *ngFor="let transition of slideTransitions">
                                <ng-container>
                                  <div class="item" (click)="setSlideIntroTransition(currentSlide, transition.value)">{{
                                    transition.label }}</div>
                                </ng-container>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                        <div class="field">
                          <label>Outro Transition <i *ngIf="isSlideOutroTransitionLinked(currentSlide)"
                              class="linkify icon"></i></label>
                          <div class="ui dropdown button" suiDropdown>
                            <div class="text">{{ getNestedOptionLabel(slideTransitions, currentSlide.transition.out) }}
                            </div>
                            <i class="dropdown icon"></i>
                            <div class="menu" suiDropdownMenu>
                              <ng-container *ngFor="let transition of slideTransitions">
                                <ng-container>
                                  <div class="item" (click)="setSlideOutroTransition(currentSlide, transition.value)">{{
                                    transition.label }}</div>
                                </ng-container>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="field">
                        <sui-checkbox class="toggle label" [ngModel]="currentSlide.transition.crossfade"
                          (ngModelChange)="setSlideCrossfade(currentSlide, $event)">Enable crossfade <i suiPopup
                            popupPlacement="left" popupText="Crossfading will blend the transitions between slides."
                            class="question circle outline icon"></i></sui-checkbox>
                      </div>
                    </div>
                  </div>

                  <div class="cdm-video-creator__animation-step-divider">

                  </div>

                  <div class="cdm-video-creator__animation-steps" cdkDropList cdkDropListOrientation="vertical"
                    (cdkDropListDropped)="dropAnimation($event)">
                    <div class="cdm-video-creator__animation-step" cdkDrag cdkDragLockAxis="y"
                      *ngFor="let animationStep of currentSlide.animations; index as i"
                      (mouseenter)="setActiveElement(getElementById(animationStep.elementId))"
                      [ngClass]="{ 'cdm-video-creator__animation-step--selected': selectedElement === getElementById(animationStep.elementId) } ">
                      <span class="cdm-video-creator__animation-step-header">
                        {{ getElementById(animationStep.elementId).type | titlecase }} Animation
                        <div class="cdm-video-creator__animation-step-delete"
                          (click)="removeAnimation(getElementById(animationStep.elementId))">
                          <i class="times icon"></i>
                        </div>
                      </span>
                      <div class="ui form">
                        <div class="field">
                          <label>When to trigger this animation?</label>
                          <sui-select class="selection" [(ngModel)]="animationStep.trigger"
                            [options]="animationTriggers" labelField="label" valueField="value" #triggerSelect>
                            <sui-select-option *ngFor="let option of triggerSelect.filteredOptions" [value]="option">
                            </sui-select-option>
                          </sui-select>
                        </div>
                        <div class="field">
                          <label>Animation speed</label>
                          <sui-select class="selection" [(ngModel)]="animationStep.speed" [options]="animationSpeed"
                            labelField="label" valueField="value" #speedSelect>
                            <sui-select-option *ngFor="let option of speedSelect.filteredOptions" [value]="option">
                            </sui-select-option>
                          </sui-select>
                        </div>
                        <div class="field" *ngIf="animationStep.trigger === 'delay'">
                          <label>Delay time (seconds)</label>
                          <input type="number" [(ngModel)]="animationStep.delay" placeholder="3 seconds">
                        </div>
                        <div class="two fields">
                          <div class="field">
                            <label>Intro Animation</label>
                            <div class="ui dropdown button" suiDropdown>
                              <div class="text">{{ getNestedOptionLabel(transitions, animationStep.transition.in) }}
                              </div>
                              <i class="dropdown icon"></i>
                              <div class="menu" suiDropdownMenu>
                                <ng-container *ngFor="let transition of transitions">
                                  <ng-container *ngIf="transition.options && transition.options.length > 0">
                                    <div class="item" suiDropdown>
                                      <i class="dropdown icon"></i>
                                      {{ transition.label }}
                                      <div class="menu" suiDropdownMenu>
                                        <div class="item" *ngFor="let innerTransition of transition.options"
                                          (click)="animationStep.transition.in = innerTransition.value">{{
                                          innerTransition.label }}</div>
                                      </div>
                                    </div>
                                  </ng-container>
                                  <ng-container *ngIf="!transition.options || transition.options.length === 0">
                                    <div class="item" (click)="animationStep.transition.in = transition.value">{{
                                      transition.label }}</div>
                                  </ng-container>
                                </ng-container>
                              </div>
                            </div>
                          </div>
                          <div class="field">
                            <label>Outro Animation</label>
                            <div class="ui dropdown button" suiDropdown>
                              <div class="text">{{ getNestedOptionLabel(transitions, animationStep.transition.out) }}
                              </div>
                              <i class="dropdown icon"></i>
                              <div class="menu" suiDropdownMenu>
                                <ng-container *ngFor="let transition of transitions">
                                  <ng-container *ngIf="transition.options && transition.options.length > 0">
                                    <div class="item" suiDropdown>
                                      <i class="dropdown icon"></i>
                                      {{ transition.label }}
                                      <div class="menu" suiDropdownMenu>
                                        <div class="item" *ngFor="let innerTransition of transition.options"
                                          (click)="animationStep.transition.out = innerTransition.value">{{
                                          innerTransition.label }}</div>
                                      </div>
                                    </div>
                                  </ng-container>
                                  <ng-container *ngIf="!transition.options || transition.options.length === 0">
                                    <div class="item" (click)="animationStep.transition.out = transition.value">{{
                                      transition.label }}</div>
                                  </ng-container>
                                </ng-container>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="field">
                          <label>Duration (leave empty to show for entire slide)</label>
                          <input type="number" [(ngModel)]="animationStep.duration" placeholder="3 seconds">
                        </div> -->
                      </div>
                    </div>
                  </div>

                  <div class="cdm-video-creator__animation-step-helper">
                    <span>Want to animate a new element? Right click any element and click "Animate".</span>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="cdm-video-creator__slides-wrapper">
        <div class="cdm-video-creator__slides-buttons">
          <i class="search minus icon" (click)="zoomTimelineOutBy(0.25)"></i>
          <i class="search plus icon" (click)="zoomTimelineInBy(0.25)"></i>
        </div>
        <div class="cdm-video-creator__slides" (mousewheel)="onTimelineScroll($event)">
          <div cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="dropSlide($event)">
            <div *ngFor="let slide of creation.slides" class="cdm-video-creator__slide-wrapper"
              (click)="selectSlide(slide)" cdkDrag cdkDragLockAxis="x">
              <div class="cdm-video-creator__slide"
                [ngClass]="{ 'cdm-video-creator__slide--selected': currentSlide === slide }"
                [ngStyle]="getSlideCSS(slide, getViewport().track)">
                <div class="cdm-video-creator__slide-content" [ngStyle]="getTrackSlideCSS()">
                  <ng-container *ngFor="let element of slide.elements">
                    <div class="cdm-video-creator__element"
                      [ngStyle]="element.generateCSS(getViewport().track, resolutionOptions[getResolution(creation)][getOrientation(creation)])">
                      <div class="cdm-video-creator__rotation" [ngClass]=" {'cdm-video-creator__rotation--fill': element.type === 'image' || element.type === 'video' }" [ngStyle]="getElementRotationCSS(element)">
                        <ng-container *ngIf="element.type === 'image'">
                          <img
                            [ngStyle]="element.generateImageCSS(getViewport().track, resolutionOptions[getResolution(creation)][getOrientation(creation)])"
                            [src]="element.previewUrl || element.value | safe" />
                        </ng-container>
                        <ng-container *ngIf="element.type === 'video'">
                          <video
                            [ngStyle]="element.generateVideoCSS(getViewport().track, resolutionOptions[getResolution(creation)][getOrientation(creation)])"
                            [src]="element.previewUrl || element.value | safe" muted></video>
                        </ng-container>
                        <ng-container *ngIf="element.type === 'text'">
                          <p>{{ element.value }}</p>
                        </ng-container>
                        <ng-container *ngIf="element.type === 'shape'">
                          <div [innerHtml]="element.svg.rendered | safeHtml"
                            [ngStyle]="getRenderedSVGCSS(getViewport().track)"></div>
                        </ng-container>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <div class="cdm-video-creator__slide-delete" (click)="removeSlide(slide)">
                  <i class="close icon"></i>
                </div>
                <div (mousedown)="onSliderHandlePull($event, slide, 'left')"
                  class="cdm-video-creator__slide-slider cdm-video-creator__slide-slider--left">
                  <div></div>
                </div>
                <div (mousedown)="onSliderHandlePull($event, slide, 'right')"
                  class="cdm-video-creator__slide-slider cdm-video-creator__slide-slider--right">
                  <div></div>
                </div>
                <span class="cdm-video-creator__slide-duration">{{ slide.duration | number: '1.0-0' }}s</span>
              </div>
            </div>
            <div class="cdm-video-creator__slide cdm-video-creator__slide--new" (click)="addSlide()">
              <p><i class="plus icon"></i>New slide</p>
            </div>
          </div>
        </div>
        <div class="cdm-video-creator__music-bar-wrapper" *ngIf="creation.music.duration"
          [ngStyle]="{ cursor: croppingMusic ? 'ew-resize' : 'unset' }">
          <div #musicBar>
            <div class="cdm-video-creator__music-bar" [ngStyle]="getMusicCSS()">
              <span class="cdm-video-creator__music-bar-music-name"><i class="icon music"></i>{{ creation.music.name}}<i
                  (click)="clearMusic()" class="trash icon cdm-video-creator__music-bar-delete"></i></span>
              <app-waveform class="cdm-video-creator__music-bar-waveform" [pxPerSecond]="getMusicPxPerSecond()"
                [heightScalingFactor]="0.5" [url]="creation.music.value"></app-waveform>
              <div class="cdm-video-creator__music-bar-music-bar-slider"
                [ngClass]="{ 'cdm-video-creator__music-bar-music-bar-slider--active': croppingMusic}"
                (mousedown)="croppingMusic = true">
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template let-popup let-context="context" #templateActions>
    <div class="ui selection list cdm-video-creator__popup-list">
      <a class="item"
        (click)="popup.close(); saveContent(context.type === 'project' ? 'template' : 'project', true, context.content)">
        <div class="description">
          <i class="save icon"></i>
          Save as {{ context.type === 'project' ? 'Template' : 'Project' }}
        </div>
      </a>
      <a class="item" (click)="popup.close(); deleteContent($event, context.content)"
        *ngIf="canDelete(context.content)">
        <div class="description">
          <i class="trash icon"></i>
          Delete
        </div>
      </a>
    </div>
  </ng-template>
</div>

<context-menu #elementMenu>
  <ng-container *ngFor="let group of contextMenu">
    <ng-container *ngFor="let action of group.actions">
      <ng-template contextMenuItem let-item [visible]="group.visible" (execute)="executeToolbarAction(action.execute)">
        <i class="{{ action.icon }} icon"></i>
        {{ action.label }}
      </ng-template>
    </ng-container>
  </ng-container>
</context-menu>