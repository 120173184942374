import { AfterViewInit, Component, OnDestroy, OnInit, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { LoaderService } from '@citadel/common-frontend/_services/loader.service';
import {UserService} from '../_services/user.service';
import {LoggedInUserUpdateService} from '../_services/logged-in-user-update.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	encapsulation: ViewEncapsulation.None,
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {
  private subscriptions = new Set<Subscription>();
	public notificationOptions: any = {
		timeOut: 6000,
		position: ['top', 'right'],
		preventDuplicates: 'true',
		showProgressBar: false,
	};

	constructor(
    private loaderService: LoaderService,
    private userService: UserService,
    private loggedInUserNotifs: LoggedInUserUpdateService
  ) { }

	ngAfterViewInit() {
		this.loaderService.initializeOnPageload();
	}

  ngOnInit() {
    this.subscriptions.add(
      this.loggedInUserNotifs.loggedInUserUpdates$.subscribe(() => {
        if(!this.userService.isSessionValidSync()){
          // logout if the session is invalid
          this.userService.logout();
        }
      })
    );
  }

  ngOnDestroy(){
    for(const sub of this.subscriptions){
      sub.unsubscribe();
    }
  }

  getLoadingText() {
    return this.loaderService.getLoadingText();
  }

  getPercentageLoadingValue() {
    return this.loaderService.getPercentageLoadingValue();
  }

  isIndeterminateLoading() {
    return this.loaderService.isIndeterminateLoading();
  }

  isLoading() {
    return this.loaderService.isLoading();
  }

  isDeterminateLoading() {
    return this.loaderService.isDeterminateLoading();
  }
}
