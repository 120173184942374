import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {SocketService} from '@citadel/common-frontend/_services/socket.service';
import Events from 'src/_util/events';
import INetwork from 'src/_interfaces/INetwork';
import IScreen from 'src/_interfaces/IScreen';
import {NetworkUpdateService} from './network.update.service';
import {ScreensUpdateService} from './screen.update.service';

// declare const google: any;
type GooglePlace = any; // google.maps.places.PlaceResult;

@Injectable()
export class NetworkService {
  private networks: INetwork[] = [];
  private screensByNetworkId = new Map<string, Map<string, IScreen>>();

  constructor(
    private socketService: SocketService,
    private networkUpdateService: NetworkUpdateService,
    private screenUpdateService: ScreensUpdateService
  ) {
  }

  public init(): Observable<any> {
    return this.socketService.call(
      Events.GET_ALL_NETWORKS,
      async (networks: INetwork[]) => {
        this.networks = networks;
        this.networkUpdateService.notifyNetworksUpdate();
      }
    );
  }

  public getMyNetwork(): Observable<INetwork> {
    return this.socketService.call(
      Events.GET_MY_NETWORK,
      () => {
        this.networkUpdateService.notifyNetworksUpdate();
      },
      {}
    );
  }

  public editNetworkScreen(screenId, options: { enabled: boolean, classification: any, notes: string }): Observable<IScreen[]> {
    return this.socketService.call(
      Events.EDIT_SCREEN,
      () => {
        this.networkUpdateService.notifyNetworksUpdate();
        this.screenUpdateService.notifyScreensUpdated();
      },
      {screenId, ...options}
    );
  }

  public getScreensFromNetworkSync(networkId: string): IScreen[] {
    const s = this.screensByNetworkId.get(networkId);
    return !s ? [] : Array.from(s.values());
  }

  public getScreensFromNetwork(networkId: string): Observable<IScreen[]> {
    return this.socketService.call(
      Events.GET_SCREENS_FROM_NETWORK,
      (screens) => {
        if (!this.screensByNetworkId.has(networkId)) {
          this.screensByNetworkId.set(networkId, new Map());
        }
        const screensByNetworkId = this.screensByNetworkId.get(networkId);
        for (const s of screens) {
          screensByNetworkId.set(s._id, s);
        }

        this.networkUpdateService.notifyNetworksUpdate();
        this.screenUpdateService.notifyScreensUpdated();
      },
      {networkId}
    );
  }

  public approveAdForNetwork(networkId: string, options: { adId: string }): Observable<INetwork> {
    return this.socketService.call(
      Events.APPROVE_AD_FOR_NETWORK,
      async (network: INetwork) => {
        const existingNetwork = this.getNetworkById(networkId);
        Object.assign(existingNetwork, network);
        this.networkUpdateService.notifyNetworksUpdate();
      },
      {networkId, ...options}
    );
  }

  public rejectAdForNetwork(networkId: string, options: { adId: string }): Observable<INetwork> {
    return this.socketService.call(
      Events.REJECT_AD_FOR_NETWORK,
      async (network: INetwork) => {
        const existingNetwork = this.getNetworkById(networkId);
        Object.assign(existingNetwork, network);
        this.networkUpdateService.notifyNetworksUpdate();
      },
      {networkId, ...options}
    );
  }

  public editNetwork(networkId: string, options: {}): Observable<INetwork> {
    return this.socketService.call(
      Events.EDIT_NETWORK,
      async (network: INetwork) => {
        const existingNetwork = this.getNetworkById(networkId);
        Object.assign(existingNetwork, network);
        this.networkUpdateService.notifyNetworksUpdate();
      },
      {networkId, ...options}
    );
  }

  public getNetworks(): INetwork[] {
    return this.networks;
  }

  public getNetworkById(id: string): INetwork {
    return this.networks.find(network => network._id === id);
  }

  public updateScreenLocation(screenId: string, networkId: string, address: string, longitude: number, latitude: number, googlePlace: GooglePlace): Observable<any> {
    return this.socketService.call(
      Events.UPDATE_SCREEN_LOCATION,
      () => {
        this.networkUpdateService.notifyNetworksUpdate();
        this.screenUpdateService.notifyScreensUpdated();
      },
      {
        screenId,
        networkId,
        address,
        longitude,
        latitude,
        googlePlace
      }
    );
  }
}
