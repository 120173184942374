import * as i0 from "@angular/core";
export class LoaderService {
    constructor() {
        this.LOADER_FADE_TIME = 250;
        this.loading = false;
        this.pageLoaded = false;
        // before page load
        this.unloadedStatus = false;
        // percentage loader
        this.isPercentageLoading = false;
        this.percentageLoadingValue = 0;
    }
    initializeOnPageload() {
        this.pageLoaded = true;
        this.loading = this.unloadedStatus;
        this.loaderText = this.unloadedText;
    }
    setLoading(value, loaderText = '') {
        if (this.pageLoaded) {
            this.loading = value;
            this.loaderText = loaderText;
        }
        else {
            this.unloadedStatus = value;
            this.unloadedText = loaderText;
        }
    }
    createPercentageLoader(message) {
        this.isPercentageLoading = true;
        this.loaderText = message;
        this.percentageLoadingValue = 0;
        return (value) => {
            this.percentageLoadingValue = value;
            if (value >= 100) {
                this.stopPercentageLoader();
            }
        };
    }
    stopPercentageLoader() {
        setTimeout(() => {
            this.isPercentageLoading = false;
        }, this.LOADER_FADE_TIME);
    }
    isDeterminateLoading() {
        return this.isPercentageLoading;
    }
    isIndeterminateLoading() {
        return this.isLoading && !this.isPercentageLoading;
    }
    isLoading() {
        if (!this.pageLoaded) {
            return false;
        }
        return this.loading || this.isPercentageLoading;
    }
    getLoadingText() {
        return this.loaderText;
    }
    getPercentageLoadingValue() {
        // Clamp from 0-100
        return Math.min(Math.max(this.percentageLoadingValue, 0), 100);
    }
}
LoaderService.ngInjectableDef = i0.defineInjectable({ factory: function LoaderService_Factory() { return new LoaderService(); }, token: LoaderService, providedIn: "root" });
