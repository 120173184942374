import { DropEvent } from 'angular-draggable-droppable';
import { ModalService } from '@citadel/common-frontend/_services/modal.service';
import { ContextMenuComponent } from 'ngx-contextmenu';
import { FileSystemFileEntry, UploadEvent } from 'ngx-file-drop';
import { catchError } from 'rxjs/internal/operators';

import { HttpEventType } from '@angular/common/http';
import {
	Component, ElementRef, ChangeDetectorRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation
} from '@angular/core';

import IDirectory from '../../_interfaces/IDirectory';
import IFile from '../../_interfaces/IFile';
import { DeleteModal } from '../../_modals/delete/delete.export';
import { NewDirectoryModal } from '../../_modals/newDirectory/newDirectory.export';
import { ResourceModal } from '../../_modals/resource/resource.export';
import { ResourceViewModal } from '../../_modals/resourceView/resourceView.export';
import { FilesystemService } from '../../_services/filesystem.service';
import { LoaderService } from '../../_services/loader.service';
import { NotificationService } from '../../_services/notification.service';
import { PreferencesService } from '../../_services/preferences.service';

import { downloadResourceToDisk } from '../../_util/functions';
import { Router } from '@angular/router';
import { validateMimetype } from '../../_util/files';

@Component({
	selector: 'app-fs',
	templateUrl: './fs.component.html',
	styleUrls: ['./fs.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class FSComponent implements OnInit {
	@ViewChild('fileMenu') public fileMenu: ContextMenuComponent;
	@ViewChild('directoryMenu') public directoryMenu: ContextMenuComponent;
	@ViewChild('parentMenu') public parentMenu: ContextMenuComponent;

	@ViewChild('uploadInput')
	public uploadInput: ElementRef;

	@Input()
	public mode: string;

	@Input()
	public defaultVisualMode: string = 'grid';

	@Input()
	public metadata: any = {};

	@Input()
	public type: string = '*';

	@Input()
	public sessionToken: string;

	@Output()
	public onDirectoryEntitlementChange = new EventEmitter();

	@Output()
	public onFileEntitlementChange = new EventEmitter();

	@Input()
	public allowSystemFolderCreation: boolean = false;

	@Input()
	public isCompact: boolean = false;

	@Output()
	public selectedResourcesChange = new EventEmitter();

	// handle view state and commands
	public currentPath = '';
	public currentDirectory: IDirectory = null;

	public currentVisualMode: string = '';

	public activeChildrenFiles: IFile[] = [];
	public activeSubdirectories: IDirectory[] = [];
	public clipboard: IFile;
	public selectedResources: any[] = [];

	public currentUploads: any[] = [];

	public refreshing = false;
	public fileHovering = false;

	public directoryHistory: IDirectory[] = [];
	public directoryHistoryIndex: number = -1;

	// handle search
	public searchFilter = '';
	public isShowingSearchResults: boolean = false;

	// handle modal queue
	private uploadEditQueue: any[] = [];
	private showingEditModal = false;

	constructor(private modal: ModalService,
              private changeDetectorRef: ChangeDetectorRef,
              private router: Router,
              private preferencesService: PreferencesService,
              private loaderService: LoaderService,
              private notificationService: NotificationService,
              private filesystemService: FilesystemService
  ) { }


	async ngOnInit() {
		this.refresh(true);
		this.currentVisualMode = this.preferencesService.getPreference(['fs', 'visual-mode'], this.defaultVisualMode);
	}

	public setRefreshing(refreshing: boolean) {
		this.refreshing = refreshing;
		this.changeDetectorRef.detectChanges();
	}

	public hasResourcesToDisplay(): boolean {
		return this.refreshing || !!this.currentDirectory || this.activeChildrenFiles.length > 0 || this.activeSubdirectories.length > 0;
	}

	public validateDrag({ x, y }) {
		const MIN_DRAG_THRESHOLD = 8;
		return Math.abs(x) > MIN_DRAG_THRESHOLD || Math.abs(y) > MIN_DRAG_THRESHOLD;
	}

	public setVisualMode(mode: string): void {
		this.currentVisualMode = mode;
		this.preferencesService.setPreference(['fs', 'visual-mode'], mode);
	}

	public async openDeleteModal(resources: any) {
		let resourceType;

		if (resources.length > 1) {
			resourceType = 'resource';
		} else {
			if (resources['path']) {
				resourceType = 'directory';
			} else {
				resourceType = 'file';
			}
		}

		this.modal
			.open(new DeleteModal('these resources', resourceType, false))
			.onApprove(async () => {
				this.loaderService.setLoading(true, `Deleting resource`);
				try {
					if (resources.length > 0) {
						for (const resource of resources) {
							const type = resource.path ? 'directory' : 'file';
							await this.filesystemService.delete(type, resource).toPromise();
						}
					} else {
						await this.filesystemService.delete(resourceType, resources).toPromise();
					}
					this.refresh(true);
				} catch (e) {
					this.notificationService.displayError(e);
				}
				this.loaderService.setLoading(false);
			})
			.onDeny(() => { });
	}

	public upload() {
		if (this.uploadInput) {
			this.uploadInput.nativeElement.click();
		}
	}

	public async openDirectoryEntitlementsModal(directory: IDirectory): Promise<void> {
		// const entitlementsModal = new EntitlementsModal(directory, 'directory');

		// this.modal.open(entitlementsModal);
	}

	public async openFileEntitlementsModal(file: IFile): Promise<void> {
		// const entitlementsModal = new EntitlementsModal(file, 'file');

		// this.modal.open(entitlementsModal);
	}


	public getPsuedoParentDirectory() {
		// slightly hacky
		if (this.currentDirectory === null) {
			return null;
		}
		return this.currentDirectory.parent
			? ((<unknown>{
				_id: this.currentDirectory.parent,
			}) as IDirectory)
			: null;
	}

	public makeDirectory() {
		this.modal
			.open(new NewDirectoryModal(this.currentDirectory, 'user'))
			.onApprove(() => {
				this.refresh();
			})
			.onDeny(() => { });
	}

	public makeSystemDirectory() {
		this.modal
			.open(new NewDirectoryModal(this.currentDirectory, 'system'))
			.onApprove(() => {
				this.refresh();
			})
			.onDeny(() => { });
	}

	public hasThumbnail(resource: IFile): boolean {
		return !!(resource.urls && resource.urls.thumbnail);
	}

	public getIcon(resource: IFile | IDirectory) {
		if (resource['path']) {
			return 'folder';
		}

		const file = resource as IFile;

		const genericMimeType = file.mimeType.split('/')[0];

		switch (genericMimeType) {
			case 'video':
				return 'file video outline';
			case 'image':
				return 'file image outline';
			case 'audio':
				return 'file audio outline';
			case 'text':
				return 'file alternate outline';
		}
		return 'file outline';
	}

	public isAudio(resource: IFile) {
		return resource.mimeType.startsWith('audio');
	}

	public isImage(resource: IFile) {
		return resource.mimeType.startsWith('image');
	}

	public isVideo(resource: IFile) {
		return resource.mimeType.startsWith('video');
	}

	public isPDF(resource: IFile) {
		return resource.mimeType === 'application/pdf';
	}

	public isSelected(resource: IFile | IDirectory) {
		const indexOf = this.selectedResources.indexOf(resource);
		return indexOf !== -1;
	}

	public isDisabled(resource: IFile | IDirectory) {
		if (!this.type || !resource['mimeType']) {
			return false;
		}

		return !validateMimetype(resource['mimeType'], this.type);
	}

	public isGridViewElementDisabled(resource: IFile | IDirectory) {
		if (this.mode !== 'filepicker') {
			return false;
		}

		if (!this.type || !resource['mimeType']) {
			return false;
		}

		return !validateMimetype(resource['mimeType'], this.type);
	}

	public areAllSelected() {
		return this.selectedResources.length === this.activeChildrenFiles.length + this.activeSubdirectories.length;
	}

	public onAllCheckChange($event: any) {
		const value = !!$event;
		if (value) {
			this.selectedResources = [];
			for (const subdirectory of this.activeSubdirectories) {
				this.selectedResources.push(subdirectory);
			}
			for (const file of this.activeChildrenFiles) {
				this.selectedResources.push(file);
			}
		} else {
			this.selectedResources = [];
		}
		this.selectedResourcesChange.emit(this.selectedResources);
	}

	public onResourceCheckChange($event: any, resource: IFile | IDirectory) {
		const value = !!$event;
		if (value) {
			this.selectedResources.push(resource);
		} else {
			const indexOf = this.selectedResources.indexOf(resource);
			if (indexOf !== -1) {
				this.selectedResources.splice(indexOf, 1);
			}
		}
		this.selectedResourcesChange.emit(this.selectedResources);
	}

	public async onDirectoryDrop(directory: IDirectory, { dropData }: DropEvent<any>) {
		if (directory === dropData) {
			return;
		}
		let type;
		if (dropData['path']) {
			type = 'directory';
		} else {
			type = 'file';
		}

		this.setRefreshing(true);

		try {
			await this.filesystemService.move(type, dropData, directory).toPromise();
		} catch (e) {
			this.notificationService.displayError(e.toString());
		}

		await this.refresh(false);
		this.setRefreshing(false);
	}

	public navigateBack(): void {
		if (this.directoryHistoryIndex === -1) {
			return;
		}

		this.currentDirectory = this.directoryHistory[--this.directoryHistoryIndex];
		this.refresh();
	}

	public navigateForward(): void {
		if (this.directoryHistoryIndex === this.directoryHistory.length - 1) {
			return;
		}

		this.currentDirectory = this.directoryHistory[++this.directoryHistoryIndex];
		this.refresh();
	}

	public async navigateTo(directory: IDirectory) {
		if (directory && !directory.path) {
			// this is probably a file
			return;
		}

		this.directoryHistory.splice(this.directoryHistoryIndex + 1, this.directoryHistory.length - this.directoryHistoryIndex);
		this.directoryHistory.push(directory);

		this.directoryHistoryIndex++;

		this.currentDirectory = directory;
		this.refresh();
	}

	public async navigateToParent() {
		this.setRefreshing(true);
		const directoryDetails = await this.filesystemService.getDirectoryDetails(this.getPsuedoParentDirectory()).toPromise();
		this.currentDirectory = directoryDetails.parent;
		this.activeChildrenFiles = directoryDetails.files;
		this.activeSubdirectories = directoryDetails.subDirectories;
		this.setRefreshing(false);
	}

	public onGridFileClick(file: IFile): void {
		if (this.mode === 'filepicker') {
			this.toggleResource(file);
		} else {
			this.openPreviewModal(file);
		}
	}

	public async refresh(triggerLoading: boolean = true) {
		this.isShowingSearchResults = false;
		if (triggerLoading) {
			this.setRefreshing(true);
		}
		this.selectedResources = [];
		const directoryDetails = await this.filesystemService.getDirectoryDetails(this.currentDirectory).toPromise();
		this.currentDirectory = directoryDetails.parent;
		this.activeChildrenFiles = directoryDetails.files;
		this.activeSubdirectories = directoryDetails.subDirectories;

		if (triggerLoading) {
			this.setRefreshing(false);
		}

		this.selectedResourcesChange.emit(this.selectedResources);
	}

	public async search() {
		if (!this.searchFilter) {
			this.refresh();
			return;
		}
		const searchFilter = this.searchFilter.trim();
		if (searchFilter.length === 0) {
			this.refresh();
			return;
		}

		this.setRefreshing(true);
		this.selectedResources = [];

		const searchDetails = await this.filesystemService.search(searchFilter).toPromise();
		this.currentDirectory = searchDetails.parent;
		this.activeChildrenFiles = searchDetails.files;
		this.activeSubdirectories = searchDetails.subDirectories;

		this.isShowingSearchResults = true;
		this.setRefreshing(false);
		this.selectedResourcesChange.emit(this.selectedResources);
  }

	public async download(file: IFile) {
		const fileName = file.name + (file.extension ? `.${file.extension}` : '');

		this.loaderService.setLoading(true, 'Downloading...');

		try {
			await downloadResourceToDisk(file.cdnUrl, fileName);
		} catch (e) {
			this.notificationService.displayError(e);
		}

		this.loaderService.setLoading(false);
	}

	public async gotoBulkAction(file: IFile) {
		this.router.navigate(
			[`/dashboard/bulk-management`],
			{
				queryParams: {
					sourceResourceType: 'video',
					sourceResource: btoa(JSON.stringify(file))
				}
			});
	}

	public async toggleResource(resource: IFile | IDirectory) {
		const value = this.selectedResources.indexOf(resource) === -1;
		if (value) {
			this.selectedResources.push(resource);
		} else {
			const indexOf = this.selectedResources.indexOf(resource);
			if (indexOf !== -1) {
				this.selectedResources.splice(indexOf, 1);
			}
		}
		this.selectedResourcesChange.emit(this.selectedResources);
	}

	public async copyUrl(file: IFile) {
		document.addEventListener('copy', (e: ClipboardEvent) => {
			e.clipboardData.setData('text/plain', file.cdnUrl);
			e.preventDefault();
			document.removeEventListener('copy', null);
		});
		document.execCommand('copy');
	}

	public async openEditModal(resource: IFile | IDirectory, justUploaded = false) {
		const onComplete = () => {
			if (this.uploadEditQueue.length > 0) {
				this.openEditModal(this.uploadEditQueue.shift(), justUploaded);
			} else {
				this.refresh();
				this.showingEditModal = false;
			}
		};

		this.showingEditModal = true;
		this.modal
			.open(new ResourceModal(resource, justUploaded, this.metadata ? this.metadata['tags'] : []))
			.onApprove(() => {
				onComplete();
			})
			.onDeny(() => {
				onComplete();
			});
	}

	public async openPreviewModal(resource: IFile) {
		this.modal.open(new ResourceViewModal(resource));
	}

	public getCurrentDirectoryPath(): string[] {
		if (!this.currentDirectory) {
			return [];
		}
		return this.currentDirectory.path.split('/');
	}

	// events
	public async onFileOver($event: any) {
		this.fileHovering = true;
	}

	public async onFileLeave($event: any) {
		this.fileHovering = false;
	}

	public async onFileDrop($event: UploadEvent) {
		const files = $event.files;
		if (files && files.length > 0) {
			const uploadFile = files[0];
			if (!uploadFile.fileEntry.isFile) {
				this.notificationService.displayError('Not a file!');
				return;
			}
			const fileEntry = uploadFile.fileEntry as FileSystemFileEntry;
			fileEntry.file((file: File) => {
				this.handleFileInput(file);
			});
		}
	}

	public async onFileChange($event) {
		if ($event.target.files && $event.target.files.length > 0) {
			for (const file of $event.target.files) {
				this.handleFileInput(file);
			}
		}
	}

	private async handleFileInput(file: any) {
		// create pending upload
		const pendingUpload = { name: file.name, progress: 0 };
		this.currentUploads.push(pendingUpload);

		this.filesystemService
			.upload(file, this.currentDirectory, this.sessionToken)
			.pipe(
				catchError(async (event) => {
					const errorMessage = event.error.error || event.error.data;
					this.notificationService.displayError(errorMessage);

					// remove pending upload
					const indexOf = this.currentUploads.indexOf(pendingUpload);
					if (indexOf !== -1) {
						this.currentUploads.splice(indexOf, 1);
					}
				})
			)
			.subscribe(async (event) => {
				if (!event) {
					return;
				}
				if (event.type === HttpEventType.UploadProgress) {
					const progress = Math.round((100 * event.loaded) / event.total);
					pendingUpload.progress = progress;
				}

				if (event.type === HttpEventType.Response) {
					// get response
					const upload = event.body;
					if (upload.meta && upload.meta.length > 0) {
						if (this.showingEditModal || this.uploadEditQueue.length > 0) {
							this.uploadEditQueue.push(upload);
						} else {
							this.openEditModal(upload, true);
						}
					}

					// remove pending upload
					const indexOf = this.currentUploads.indexOf(pendingUpload);
					if (indexOf !== -1) {
						this.currentUploads.splice(indexOf, 1);
					}
					await this.refresh();
				}
			});
	}
}
