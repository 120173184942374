import {Observable} from 'rxjs';

import {Injectable} from '@angular/core';
import {SocketService} from '@citadel/common-frontend/_services/socket.service';
import ICampaign from 'src/_interfaces/ICampaign';
import Events from 'src/_util/events';
import IAd from 'src/_interfaces/IAd';
import {AdsUpdateService} from './ads.update.service';

@Injectable()
export class AdService {
  private ads: IAd[] = [];

  constructor(private socketService: SocketService, private adsUpdateService: AdsUpdateService) {
  }

  public init(): Observable<any> {
    return this.socketService.call(
      Events.GET_ADS,
      async (ads: IAd[]) => {
        this.ads = ads;
        this.adsUpdateService.notifyAdsUpdate();
      }
    );
  }

  public createAd(options: { campaignId: string, fileId: string }): Observable<IAd> {
    return this.socketService.call(
      Events.CREATE_AD,
      async (ad: IAd) => {
        this.ads.push(ad);
        this.adsUpdateService.notifyAdsUpdate();
      },
      options
    );
  }

  public editAd(adId: string, options: {}): Observable<IAd> {
    return this.socketService.call(
      Events.EDIT_AD,
      async (ad: IAd) => {
        const existingAd = this.getAdById(adId);
        Object.assign(existingAd, ad);
        this.adsUpdateService.notifyAdsUpdate();
      },
      {adId, ...options}
    );
  }

  public deleteAd(adId: string): Observable<ICampaign> {
    return this.socketService.call(
      Events.DELETE_AD,
      async (ad: IAd) => {
        const existingIndex = this.ads.findIndex(existingAd => existingAd._id === ad._id);

        if (existingIndex === -1) {
          this.adsUpdateService.notifyAdsUpdate();
          return;
        }

        this.ads.splice(existingIndex, 1);
        this.adsUpdateService.notifyAdsUpdate();

      },
      {adId}
    );
  }

  public getAdsForCampaign(campaignId: string): Observable<any> {
    return this.socketService.call(
      Events.GET_ADS_FOR_CAMPAIGN,
      () => {
        this.adsUpdateService.notifyAdsUpdate();
      },
      {campaignId}
    );
  }

  public getAllAds(): Observable<IAd[]> {
    return this.socketService.call(
      Events.GET_ALL_ADS,
      () => {
        this.adsUpdateService.notifyAdsUpdate();
      },
      {}
    );
  }

  public getAds(): IAd[] {
    return this.ads;
  }

  public getAdById(id: string): IAd {
    return this.ads.find(ad => ad._id === id);
  }
}
