export default {
	AUTHENTICATE_TOKEN_EVENT: 'token/authenticate',

	VERSION_EVENT: 'version',

	LOGIN: 'self/login',

	GET_CAMPAIGNS: 'campaigns/list',
	CREATE_CAMPAIGN: 'campaign/create',
	EDIT_CAMPAIGN: 'campaign/edit',
	DELETE_CAMPAIGN: 'campaign/delete',
	GET_IMPRESSIONS_FOR_CAMPAIGN: 'campaign/impressions',
	GET_COST_FOR_CAMPAIGN: 'campaign/cost',
	GET_IMPRESSION_LOCATIONS_FOR_CAMPAIGN: 'campaign/locations',
	GET_IMPRESSION_LOCATIONS_FOR_ME: 'campaigns/location/me',

	GET_ADS: 'ad/list',
	GET_ALL_ADS: 'ad/list-all',
	GET_ADS_FOR_CAMPAIGN: 'ad/list-by-campaign',
	CREATE_AD: 'ad/create',
	EDIT_AD: 'ad/edit',
	DELETE_AD: 'ad/delete',

	GENERATE_REPORT: 'statistic/generate',
	GET_PROGRESS: 'statistic/progress',
	GET_REPORT_SERVER_METADATA: 'statistic/meta',

	OPEN_BILLING_PORTAL: 'billing/portal',
	OPEN_CONNECT_ONBOARDING_PORTAL: 'billing/connect-onboarding',
	OPEN_CONNECT_ACCOUNT_PORTAL: 'billing/connect-account',
	CREATE_STRIPE_ACCOUNT: 'billing/create-account',

	EDIT_SCREEN: 'screen/edit',
	GET_SCREENS_FROM_NETWORK: 'screen/by-network',
  UPDATE_SCREEN_LOCATION: 'screen/update-location',

	GET_ALL_NETWORKS: 'network/list',
	GET_MY_NETWORK: 'network/self',
	EDIT_NETWORK: 'network/edit',
	APPROVE_AD_FOR_NETWORK: 'network/approve-ad',
	REJECT_AD_FOR_NETWORK: 'network/reject-ad',

	CREATE_NETWORK_GROUP: 'network-group/create',
	EDIT_NETWORK_GROUP: 'network-group/edit',
	DELETE_NETWORK_GROUP: 'network-group/delete',
	GET_ALL_NETWORK_GROUPS: 'network-group/list',
	GET_NETWORK_GROUPS_FOR_NETWORK: 'network-group/list-by-network'
};
