import {Observable} from 'rxjs';

import {Injectable} from '@angular/core';
import {SocketService} from '@citadel/common-frontend/_services/socket.service';
import ICampaign from 'src/_interfaces/ICampaign';
import Events from 'src/_util/events';
import {CampaignsUpdateService} from './campaign.update.service';

@Injectable()
export class CampaignService {
  private campaigns: ICampaign[] = [];

  constructor(private socketService: SocketService, private campaignsUpdate: CampaignsUpdateService) {
  }

  public init(): Observable<any> {
    return this.socketService.call(
      Events.GET_CAMPAIGNS,
      async (campaigns: ICampaign[]) => {
        this.campaigns = campaigns;
        this.campaignsUpdate.notifyCampaignsUpdate();
      }
    );
  }

  public getImpressionLocationsForCampaign(campaignId: string, options: { startDate: Date, endDate: Date }): Observable<any[]> {
    return this.socketService.call(
      Events.GET_IMPRESSION_LOCATIONS_FOR_CAMPAIGN,
      () => {
        this.campaignsUpdate.notifyCampaignsUpdate();
      },
      {campaignId, ...options}
    );
  }

  public getImpressionLocations(options: { startDate: Date, endDate: Date }): Observable<any[]> {
    return this.socketService.call(
      Events.GET_IMPRESSION_LOCATIONS_FOR_CAMPAIGN,
      () => {
        this.campaignsUpdate.notifyCampaignsUpdate();
      },
      {...options}
    );
  }

  public getImpressionsForCampaign(campaignId: string, options: { startDate: Date, endDate: Date }): Observable<any[]> {
    return this.socketService.call(
      Events.GET_IMPRESSIONS_FOR_CAMPAIGN,
      () => {
        this.campaignsUpdate.notifyCampaignsUpdate();
      },
      {campaignId, ...options}
    );
  }

  public getImpressionsForNetwork(networkId: string, options: { startDate: Date, endDate: Date }): Observable<any[]> {
    return this.socketService.call(
      Events.GET_IMPRESSIONS_FOR_CAMPAIGN,
      () => {
        this.campaignsUpdate.notifyCampaignsUpdate();
      },
      {networkId, ...options}
    );
  }

  public getCostForCampaign(campaignId: string, options: { startDate: Date, endDate: Date }): Observable<any[]> {
    return this.socketService.call(
      Events.GET_COST_FOR_CAMPAIGN,
      undefined,
      {campaignId, ...options}
    );
  }

  public createCampaign(options: { name: string }): Observable<ICampaign> {
    return this.socketService.call(
      Events.CREATE_CAMPAIGN,
      async (campaign: ICampaign) => {
        this.campaigns.push(campaign);
        this.campaignsUpdate.notifyCampaignsUpdate();
      },
      options
    );
  }

  public editCampaign(campaignId: string, options: {
    name: string,
    enabled: boolean,
    notes: string,
    configuration: string
  }): Observable<ICampaign> {
    return this.socketService.call(
      Events.EDIT_CAMPAIGN,
      async (campaign: ICampaign) => {
        const existingCampaign = this.getCampaignById(campaignId);
        Object.assign(existingCampaign, campaign);
        this.campaignsUpdate.notifyCampaignsUpdate();
      },
      {campaignId, ...options}
    );
  }

  public deleteCampaign(campaignId: string): Observable<ICampaign> {
    return this.socketService.call(
      Events.DELETE_CAMPAIGN,
      async (campaign: ICampaign) => {
        const existingIndex = this.campaigns.findIndex(existingCampaign => existingCampaign._id === campaign._id);

        if (existingIndex === -1) {
          this.campaignsUpdate.notifyCampaignsUpdate();
          return;
        }

        this.campaigns.splice(existingIndex, 1);
        this.campaignsUpdate.notifyCampaignsUpdate();

      },
      {campaignId}
    );
  }

  public getCampaignsSync(): ICampaign[] {
    return this.campaigns;
  }

  public getCampaignById(id: string): ICampaign {
    return this.campaigns.find(campaign => campaign._id === id);
  }
}
