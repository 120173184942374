import { FileSystemFileEntry } from 'ngx-file-drop';
import { catchError } from 'rxjs/internal/operators';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ParallelHasher } from 'ts-md5';
import { DndDropEvent } from 'ngx-drag-drop';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpEventType } from '@angular/common/http';
import {
  ApplicationRef, Component, ElementRef, HostListener, Input, OnInit, ViewChild
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormControl } from '@angular/forms';

import { ILibraryResult } from '../../_interfaces/ILibraryResult';
import { BuilderModal } from '../../_modals/builder/builder.export';
import { BuildingModal } from '../../_modals/building/building.export';
import { ConfirmModal } from '../../_modals/confirm/confirm.export';
import { FilePickerModal } from '../../_modals/filePicker/filePicker.export';
import { BuilderService } from '../../_services/builder.service';
import { FilesystemService } from '../../_services/filesystem.service';
import { LoaderService } from '../../_services/loader.service';
import { NotificationService } from '../../_services/notification.service';
import { LibraryService } from '../../_services/library.service';
import { checkForClamps, clamp, createClampInterval, naiveDeepCopy, naiveDeepEqualityCheck, roundToNearest, sleep } from '../../_util/functions';
import { ElementPropertyDisplayType, ElementType, TransformHandle, ToolbarActionType, AnimationTriggerType, AnimationType, SlideTransition, SavedProjectType, ProjectOrientation, ProjectResolution, AnimationSpeed } from './builder.enum';
import {
  IElement, IElementProperty, IElementPropertyCategory, IElementStyleSheet, IMultiselectElementEntry, IMusic,
  IVideoBuilderProject, IVideoBuilderContext, IVideoBuilderEnumOption, IVideoBuilderNestedEnumOption, IVideoScaffold,
  IVideoSlide, IVideoStateHistory, IViewportConfiguration, IViewportDimensions
} from './builder.interface';
import {
  BackgroundProperty, HeightProperty, LayerProperty, PositionXProperty, PositionYProperty,
  RotationProperty,
  WidthProperty
} from './elements/generic/generic.properties';
import { ImageElement } from './elements/image/image.element';
import { ImageCropProperty, ImageValueProperty } from './elements/image/image.properties';
import { ShapeElement } from './elements/shape/shape.element';
import { TextElement } from './elements/text/text.element';
import {
  TextAligmentProperty, TextFontColorProperty, TextFontProperty, TextFontEffectColorProperty, TextFontEffectSizeProperty, TextFontSizeProperty,
  TextFontStyleProperty, TextFontUnderlineProperty, TextFontWeightProperty, TextValueProperty, TextFontEffectProperty
} from './elements/text/text.properties';
import { VideoElement } from './elements/video/video.element';
import { VideoCropProperty, VideoMutedProperty, VideoValueProperty } from './elements/video/video.properties';
import { AddImageToolbarAction } from './toolbar/add-image.toolbar';
import { AddShapeToolbarAction } from './toolbar/add-shape.toolbar';
import { AddTextToolbarAction } from './toolbar/add-text.toolbar';
import { AddVideoToolbarAction } from './toolbar/add-video.toolbar';
import { AlignTextCenterToolbarAction } from './toolbar/align-text-center.toolbar';
import { AlignTextLeftToolbarAction } from './toolbar/align-text-left.toolbar';
import { AlignTextRightToolbarAction } from './toolbar/align-text-right.toolbar';
import { CenterToolbarAction } from './toolbar/center.toolbar.toolbar';
import { DeleteToolbarAction } from './toolbar/delete.toolbar';
import { LayerDownToolbarAction } from './toolbar/layer-down.toolbar';
import { LayerUpToolbarAction } from './toolbar/layer-up.toolbar';
import { LayerFrontToolbarAction } from './toolbar/layer-front.toolbar';
import { LayerBackToolbarAction } from './toolbar/layer-back.toolbar';

import { ToggleBoldToolbarAction } from './toolbar/toggle-bold.toolbar';
import { ToggleItalicToolbarAction } from './toolbar/toggle-italic.toolbar';
import { ToggleUnderlineToolbarAction } from './toolbar/toggle-underline.toolbar';
import { FontColorToolbarAction } from './toolbar/font-color.toolbar';
import { BackgroundColorToolbarAction } from './toolbar/background-color.toolbar';
import { FontTypeToolbarAction } from './toolbar/font-type.toolbar';
import { CloneToolbarAction } from './toolbar/clone.toolbar';
import { FontSizeToolbarAction } from './toolbar/font-size.toolbar';
import { SelectImageToolbarAction } from './toolbar/select-image.toolbar';
import { SelectVideoToolbarAction } from './toolbar/select-video.toolbar';
import { SlideColorToolbarAction } from './toolbar/slide-color.toolbar';
import { FontEffectColorToolbarAction } from './toolbar/font-effect-color.toolbar';
import { FontEffectSizeToolbarAction } from './toolbar/font-effect-radius.toolbar';
import { FontEffectToolbarAction } from './toolbar/font-effect.toolbar';
import { ExpandToolbarAction } from './toolbar/expand.toolbar';
import { AddAnimationToolbarAction } from './toolbar/add-animation.toolbar';
import IFile from '../../_interfaces/IFile';
import { WaveformService } from '../../_services/waveform.service';
import { InputModal } from '../../_modals/input/input.export';
import { ModalService } from '@citadel/common-frontend/_services/modal.service';
import SVGBox from './svg/types/box.definition';
import SVGArrow from './svg/types/arrow.definition';
import { ISVG, ISVGPoint, SVGType } from './svg/svg.definition';
import { ShapeFillProperty, ShapeStrokeProperty, ShapeThicknessProperty } from './elements/shape/shape.properties';
import { ShapeFillToolbarAction } from './toolbar/shape-fill.toolbar';
import { ShapeStrokeToolbarAction } from './toolbar/shape-stroke.toolbar';
import { ShapeThicknessToolbarAction } from './toolbar/shape-thickness.toolbar';
import SVGCircle from './svg/types/circle.definition';
import { ImageCropToolbarAction } from './toolbar/image-crop.toolbar.';
import { VideoCropToolbarAction } from './toolbar/video-crop.toolbar';

@Component({
  selector: 'app-builder',
  templateUrl: './builder.component.html',
  styleUrls: ['./builder.component.scss'],
})
export class BuilderComponent implements OnInit {
  public DEFAULT_TRANSFORM_CLAMP_THRESHOLD: number = 8;
  public DEFAULT_ROTATION_CLAMP_THRESHOLD: number = 2;
  public MINIMUM_SELECTION_THRESHOLD: number = 8;

  public ROTATION_WIDGET_OFFSET: number = 58;

  public MEDIA_LIBRARY_MAX_DEFAULT_WIDTH: number = 480;
  public DEFAULT_SLIDE_LENGTH: number = 10;

  public LIBRARY_SUMMARY_VIEW_PAGE_SIZE = 4;
  public LIBRARY_DETAILED_VIEW_PAGE_SIZE = 20;

  public isEditorDirty: boolean = false;

  @Input()
  public sessionToken: string;

  public stateHistory: IVideoStateHistory = {
    index: 0,
    history: [],
  };

  public defaultSlide: IVideoSlide = {
    transition: {
      in: '',
      out: '',
      crossfade: false
    },
    background: '#f2f2f2',
    duration: this.DEFAULT_SLIDE_LENGTH,
    animations: [],
    elements: [],
  };

  public currentSlide?: IVideoSlide;

  public types: IElementPropertyCategory[] = [
    {
      type: ElementType.SHAPE,
      properties: [
        new ShapeFillProperty(),
        new ShapeThicknessProperty(),
        new ShapeStrokeProperty()
      ]
    },
    {
      type: ElementType.IMAGE,
      properties: [new ImageValueProperty(), new ImageCropProperty()],
    },
    {
      type: ElementType.VIDEO,
      properties: [new VideoValueProperty(), new VideoMutedProperty(), new VideoCropProperty()],
    },
    {
      type: ElementType.TEXT,
      properties: [
        new TextValueProperty(),
        new TextAligmentProperty(),
        new TextFontProperty(),
        new TextFontSizeProperty(),
        new TextFontColorProperty(),
        new TextFontStyleProperty(),
        new TextFontWeightProperty(),
        new TextFontUnderlineProperty(),
        new TextFontEffectColorProperty(),
        new TextFontEffectSizeProperty(),
        new TextFontEffectProperty()
      ],
    },
    {
      type: ElementType.ANY,
      properties: [new BackgroundProperty(), new RotationProperty(), new WidthProperty(), new HeightProperty(), new PositionXProperty(), new PositionYProperty(), new LayerProperty()],
    },
  ];

  public elements: any[] = [
    {
      group: 'Media',
      visible: () => true,
      actions: [new AddImageToolbarAction(), new AddVideoToolbarAction(), new AddTextToolbarAction()],
    },
    {
      group: 'Shapes',
      visible: () => true,
      actions: [new AddShapeToolbarAction()],
    },
  ];

  public hotbar: any[] = [
    {
      group: 'Edit Video',
      visible: () => !!this.selectedElement && this.selectedElement.type === ElementType.VIDEO,
      type: ToolbarActionType.BUTTON,
      actions: [
        new SelectVideoToolbarAction(),
      ],
    },
    {
      group: 'Edit Image',
      visible: () => !!this.selectedElement && this.selectedElement.type === ElementType.IMAGE,
      type: ToolbarActionType.BUTTON,
      actions: [
        new SelectImageToolbarAction(),
      ],
    },
    {
      group: 'Clone',
      visible: () => !!this.selectedElement,
      type: ToolbarActionType.BUTTON,
      actions: [
        new CloneToolbarAction(),
      ],
    },
    {
      group: 'Font Style',
      visible: () => !!this.selectedElement && this.selectedElement.type === ElementType.TEXT,
      type: ToolbarActionType.BUTTON,
      actions: [
        new ToggleBoldToolbarAction(),
        new ToggleItalicToolbarAction(),
        new ToggleUnderlineToolbarAction()
      ],
    },
    {
      group: 'Center',
      visible: () => !!this.selectedElement,
      type: ToolbarActionType.BUTTON,
      actions: [
        new CenterToolbarAction(),
      ],
    },
    {
      group: 'Expand',
      visible: () => !!this.selectedElement,
      type: ToolbarActionType.BUTTON,
      actions: [
        new ExpandToolbarAction(),
      ],
    },
    {
      group: 'Delete',
      visible: () => !!this.selectedElement,
      type: ToolbarActionType.BUTTON,
      actions: [
        new DeleteToolbarAction(),
      ],
    }
  ]

  public contextMenu: any[] = [
    {
      group: 'Add Elements',
      visible: (element) => false,
      type: ToolbarActionType.BUTTON,
      actions: [
        new AddImageToolbarAction(),
        new AddVideoToolbarAction(),
        new AddTextToolbarAction(),
        new AddShapeToolbarAction()
      ],
    },
    {
      group: 'Clone',
      visible: (element) => this.checkContextMenuElement(element),
      type: ToolbarActionType.BUTTON,
      actions: [
        new CloneToolbarAction(),
      ],
    },
    {
      group: 'Image File',
      visible: (element) => this.checkContextMenuElement(element, ElementType.IMAGE),
      type: ToolbarActionType.FILE,
      actions: [
        new SelectImageToolbarAction()
      ]
    },
    {
      group: 'Video File',
      visible: (element) => this.checkContextMenuElement(element, ElementType.VIDEO),
      type: ToolbarActionType.FILE,
      actions: [
        new SelectVideoToolbarAction()
      ]
    },
    {
      group: 'Sizing',
      visible: (element) => this.checkContextMenuElement(element),
      type: ToolbarActionType.BUTTON,
      actions: [
        new CenterToolbarAction()
      ],
    },
    {
      group: 'Animate',
      visible: (element) => this.checkContextMenuElement(element),
      type: ToolbarActionType.BUTTON,
      actions: [
        new AddAnimationToolbarAction()
      ],
    },
    {
      group: 'Layers',
      visible: (element) => this.checkContextMenuElement(element),
      actions: [
        new LayerFrontToolbarAction(),
        new LayerBackToolbarAction()
      ],
    },
    {
      group: 'Delete',
      visible: (element) => this.checkContextMenuElement(element),
      actions: [
        new DeleteToolbarAction()
      ],
    }
  ]

  public globalToolbar: any[] = [
    // {
    //   group: 'State management',
    //   visible: () => true,
    //   buttons: [new UndoToolbarAction(), new RedoToolbarAction()],
    // },
  ];

  public toolbar: any[] = [
    {
      group: 'Slide Background',
      visible: () => !!this.currentSlide,
      type: ToolbarActionType.COLOR,
      actions: [
        new SlideColorToolbarAction()
      ],
    },
    {
      group: 'Layers',
      visible: () => !!this.selectedElement,
      type: ToolbarActionType.BUTTON,
      actions: [
        new LayerFrontToolbarAction(),
        new LayerUpToolbarAction(),
        new LayerDownToolbarAction(),
        new LayerBackToolbarAction()
      ],
    },
    {
      group: 'Alignment',
      visible: () => !!this.selectedElement && this.selectedElement.type === ElementType.TEXT,
      type: ToolbarActionType.BUTTON,
      actions: [
        new AlignTextLeftToolbarAction(),
        new AlignTextCenterToolbarAction(),
        new AlignTextRightToolbarAction()
      ],
    },
    {
      group: 'Font Color',
      visible: () => !!this.selectedElement && this.selectedElement.type === ElementType.TEXT,
      type: ToolbarActionType.COLOR,
      actions: [
        new FontColorToolbarAction()
      ],
    },
    {
      group: 'Font Type',
      visible: () => !!this.selectedElement && this.selectedElement.type === ElementType.TEXT,
      type: ToolbarActionType.DROPDOWN,
      actions: [
        new FontTypeToolbarAction()
      ],
    },
    {
      group: 'Font Size',
      visible: () => !!this.selectedElement && this.selectedElement.type === ElementType.TEXT,
      type: ToolbarActionType.INPUT,
      actions: [
        new FontSizeToolbarAction()
      ]
    },
    {
      group: 'Font Effect',
      visible: () => !!this.selectedElement && this.selectedElement.type === ElementType.TEXT,
      type: ToolbarActionType.DROPDOWN,
      actions: [
        new FontEffectToolbarAction()
      ]
    },
    {
      group: 'Font Effect Color',
      visible: () => !!this.selectedElement && this.selectedElement.type === ElementType.TEXT && this.selectedElement.properties['font::effect'] !== 'none',
      type: ToolbarActionType.COLOR,
      actions: [
        new FontEffectColorToolbarAction()
      ]
    },
    {
      group: 'Font Effect Size',
      visible: () => !!this.selectedElement && this.selectedElement.type === ElementType.TEXT && this.selectedElement.properties['font::effect'] !== 'none',
      type: ToolbarActionType.INPUT,
      actions: [
        new FontEffectSizeToolbarAction()
      ]
    },
    {
      group: 'Background',
      visible: () => !!this.selectedElement,
      type: ToolbarActionType.COLOR,
      actions: [
        new BackgroundColorToolbarAction()
      ]
    },
    {
      group: 'Image crop',
      visible: () => !!this.selectedElement && this.selectedElement.type === ElementType.IMAGE,
      type: ToolbarActionType.DROPDOWN,
      actions: [
        new ImageCropToolbarAction()
      ]
    },
    {
      group: 'Video crop',
      visible: () => !!this.selectedElement && this.selectedElement.type === ElementType.VIDEO,
      type: ToolbarActionType.DROPDOWN,
      actions: [
        new VideoCropToolbarAction()
      ]
    },
    {
      group: 'Image File',
      visible: () => !!this.selectedElement && this.selectedElement.type === ElementType.IMAGE,
      type: ToolbarActionType.FILE,
      actions: [
        new SelectImageToolbarAction()
      ]
    },
    {
      group: 'Video File',
      visible: () => !!this.selectedElement && this.selectedElement.type === ElementType.VIDEO,
      type: ToolbarActionType.FILE,
      actions: [
        new SelectVideoToolbarAction()
      ]
    },
    {
      group: 'Shape Fill',
      visible: () => !!this.selectedElement && this.selectedElement.type === ElementType.SHAPE,
      type: ToolbarActionType.COLOR,
      actions: [
        new ShapeFillToolbarAction(),
        new ShapeStrokeToolbarAction(),
      ]
    },
    {
      group: 'Shape Thickness',
      visible: () => !!this.selectedElement && this.selectedElement.type === ElementType.SHAPE,
      type: ToolbarActionType.INPUT,
      actions: [
        new ShapeThicknessToolbarAction(),
      ]
    }
  ];

  public transitions: IVideoBuilderNestedEnumOption[] = [
    {
      label: 'None', value: AnimationType.NONE
    },
    {
      label: 'Fade', value: AnimationType.FADE
    },
    {
      label: 'Reveal', value: AnimationType.REVEAL
    },
    {
      label: 'Wipe',
      options: [
        {
          label: 'Wipe Right',
          value: AnimationType.WIPE_RIGHT
        },
        {
          label: 'Wipe Left',
          value: AnimationType.WIPE_LEFT
        }
      ]
    },
    {
      label: 'Slide',
      options: [
        {
          label: 'Slide Right',
          value: AnimationType.SLIDE_RIGHT
        },
        {
          label: 'Slide Left',
          value: AnimationType.SLIDE_LEFT
        },
        {
          label: 'Slide Up',
          value: AnimationType.SLIDE_UP
        },
        {
          label: 'Slide Down',
          value: AnimationType.SLIDE_DOWN
        }
      ]
    },
    {
      label: 'Carousel',
      options: [
        {
          label: 'Carousel Right',
          value: AnimationType.CAROUSEL_RIGHT
        },
        {
          label: 'Carousel Left',
          value: AnimationType.CAROUSEL_LEFT
        },
        {
          label: 'Carousel Up',
          value: AnimationType.CAROUSEL_RIGHT
        },
        {
          label: 'Carousel Down',
          value: AnimationType.CAROUSEL_DOWN
        }
      ]
    },
    {
      label: 'Zoom', value: AnimationType.ZOOM
    }
  ];

  public slideTransitions: IVideoBuilderEnumOption[] = [
    { label: 'None', value: SlideTransition.NONE },
    { label: 'Blocks', value: SlideTransition.BLOCKS },
    { label: 'Lines', value: SlideTransition.LINES },
    { label: 'Radial', value: SlideTransition.RADIAL }
  ];

  public animationTriggers: IVideoBuilderEnumOption[] = [
    { label: 'When the slide begins', value: AnimationTriggerType.INITIAL },
    { label: 'With previous animation', value: AnimationTriggerType.CONCURRENT },
    { label: 'After previous animation', value: AnimationTriggerType.DELAY },
  ];

  public animationSpeed: IVideoBuilderEnumOption[] = [
    { label: 'Slow', value: AnimationSpeed.SLOW },
    { label: 'Normal', value: AnimationSpeed.NORMAL },
    { label: 'Fast', value: AnimationSpeed.FAST }
  ];

  public music: IMusic[] = [{ name: 'No music', value: undefined, duration: 0 }];

  public selectedElement: IElement;
  public hoveredElement: IElement;

  public multiSelectedElements: IMultiselectElementEntry[] = [];

  // handle dragging and transforming
  @ViewChild('editor') videoEditorElement: ElementRef;
  @ViewChild('builder') videoBuilderElement: ElementRef;
  @ViewChild('content') videoContentElement: ElementRef;
  @ViewChild('container') videoContainerElement: ElementRef;
  @ViewChild('selector') selectorElement: ElementRef;
  @ViewChild('selectorTransformer') selectorTransformerElement: ElementRef;
  @ViewChild('musicBar') musicBarElement: ElementRef;

  public dragging: boolean = false;
  public transforming: boolean = false;
  public rotating: boolean = false;
  public editingText: boolean = false;
  public shaping: boolean = false;

  public transformHandle: TransformHandle;
  public shapingPoint: ISVGPoint;

  public mouseDownX: number;
  public mouseDownY: number;

  public lastMouseX: number;
  public lastMouseY: number;

  public lastRawMouseX: number;
  public lastRawMouseY: number;

  public mousePivotPointX: number = 0;
  public mousePivotPointY: number = 0;

  public clampLineX: number | undefined;
  public clampLineY: number | undefined;

  public initialRotation: number = 0;

  public timelineZoomFactor: number = 1;

  public lastTextClick: Date;

  public isControlPressed: boolean = false;

  public isExpandingSlideDurationLeft: boolean = false;
  public isExpandingSlideDurationRight: boolean = false;

  public isDraggingSelectionBox: boolean = false;
  public isDraggingSelectionMoveBox: boolean = false;

  public croppingMusic: boolean = false;
  private lastMouseXonMusicBar: number;
  public currentMusic: IMusic;

  public viewportOptions: { [key in ProjectOrientation]: IViewportConfiguration } = {
    [ProjectOrientation.HORIZONTAL]: {
      editor: {
        width: 1024,
        height: 576,
      },
      thumbnail: {
        width: 288,
        height: 162,
      },
      track: {
        width: 192,
        height: 108,
      },
      template: {
        width: 140,
        height: 78.7,
      },
    },
    [ProjectOrientation.VERTICAL]: {
      editor: {
        width: 405,
        height: 720
      },
      thumbnail: {
        width: 162,
        height: 288
      },
      track: {
        width: 60,
        height: 108
      },
      template: {
        width: 44.3,
        height: 78.7
      },
    }
  };

  public resolutionOptions: { [key in ProjectResolution]: { [key in ProjectOrientation]: IViewportDimensions } } = {
    [ProjectResolution.HD]: {
      [ProjectOrientation.HORIZONTAL]: {
        width: 1280,
        height: 720
      },
      [ProjectOrientation.VERTICAL]: {
        width: 720,
        height: 1280
      }
    },
    [ProjectResolution.FULL_HD]: {
      [ProjectOrientation.HORIZONTAL]: {
        width: 1920,
        height: 1080
      },
      [ProjectOrientation.VERTICAL]: {
        width: 1080,
        height: 1920
      }
    }
  };

  public creation: IVideoScaffold = {
    music: {
      name: '',
      value: undefined,
      duration: 0,
    },
    resources: {},
    slides: [],
    output: {
      orientation: ProjectOrientation.HORIZONTAL,
      resolution: {
        type: ProjectResolution.HD,
        width: this.resolutionOptions[ProjectResolution.HD][ProjectOrientation.HORIZONTAL].width,
        height: this.resolutionOptions[ProjectResolution.HD][ProjectOrientation.HORIZONTAL].height
      }
    }
  };

  public templates: IVideoBuilderProject[] = [];
  public projects: IVideoBuilderProject[] = [];
  public currentProject?: IVideoBuilderProject;

  public currentlyCopiedElement: IElement;

  public sidebarIsActive: boolean = false;
  public sidebarActivity: string = '';

  public shapes: { model: ISVG, generator: Function, label: string, type: SVGType }[] = [];

  public librarySearchTypes: string[] = [];
  public librarySearchResults: { [key: string]: ILibraryResult[] } = {};
  public librarySearchPage: number = 1;
  public librarySearchHasMore: boolean = true;
  public librarySearchIsLoading: boolean = false;
  public librarySearchQuery: string = 'landscape';
  public librarySearchFormControl: FormControl = new FormControl();

  public templateSearchQuery: string = '';
  public templateSearchFormControl: FormControl = new FormControl();

  public activityBarIsActive: boolean = false;
  public activityBarActivity: string = '';

  constructor(
    public sanitizer: DomSanitizer,
    private loader: LoaderService,
    private modal: ModalService,
    private filesystem: FilesystemService,
    private waveform: WaveformService,
    private notification: NotificationService,
    private builder: BuilderService,
    private library: LibraryService,
    private ref: ApplicationRef
  ) { }

  async ngOnInit(): Promise<void> {
    this.loadShapes();
    this.resetCreation();
    this.loadProjects();
    this.loadTemplates();

    this.registerLibrarySearchListener();
  }

  public loadShapes(): void {
    this.shapes = [
      {
        label: 'Box',
        type: SVGType.BOX,
        model: new SVGBox(0, 0, 100),
        generator: (x, y) => new SVGBox(x, y, 100)
      },
      {
        label: 'Arrow',
        type: SVGType.ARROW,
        model: new SVGArrow(10, 10, 90, 90),
        generator: (x, y) => new SVGArrow(x, y, x + 80, y + 80)
      },
      {
        label: 'Circle',
        type: SVGType.CIRCLE,
        model: new SVGCircle(0, 0, 50),
        generator: (x, y) => new SVGCircle(x, y, 50)
      }
    ]
  }

  public registerLibrarySearchListener(): void {
    this.librarySearchFormControl.valueChanges.
      pipe(debounceTime(750), distinctUntilChanged())
      .subscribe(searchQuery => {
        this.triggerLibrarySearch(searchQuery);
      });
  }

  public getOrientation(project: IVideoScaffold): ProjectOrientation {
    if (!project || !project.output || !project.output.orientation) {
      return ProjectOrientation.HORIZONTAL;
    }
    return project.output.orientation;
  }

  public getResolution(project: IVideoScaffold): ProjectResolution {
    if (!project || !project.output || !project.output.resolution || !project.output.resolution.type) {
      return ProjectResolution.HD;
    }
    return project.output.resolution.type;
  }

  public setOrientation(orientation: string): void {
    if (orientation === this.getOrientation(this.creation)) {
      return;
    }

    const confirmModal = this.modal.open(new ConfirmModal('Confirmation', 'Are you sure you would like to change your project orientation? Any existing elements may be rearranged to fit the new format.'));

    confirmModal.onApprove(() => {
      this.creation.output = {
        orientation: orientation as ProjectOrientation,
        resolution: {
          type: this.getResolution(this.creation),
          width: this.resolutionOptions[this.getResolution(this.creation)][orientation as ProjectOrientation].width,
          height: this.resolutionOptions[this.getResolution(this.creation)][orientation as ProjectOrientation].height,
        }
      };

      this.validateAndCorrectAllElements();
    });
  }

  public setResolution(resolution: string): void {
    if (resolution === this.getResolution(this.creation)) {
      return;
    }

    const confirmModal = this.modal.open(new ConfirmModal('Confirmation', 'Are you sure you would like to change your project resolution? Any existing elements may be rearranged to fit the new format.'));

    confirmModal.onApprove(() => {
      this.creation.output = {
        orientation: this.getOrientation(this.creation),
        resolution: {
          type: resolution as ProjectResolution,
          width: this.resolutionOptions[resolution][this.getOrientation(this.creation)].width,
          height: this.resolutionOptions[resolution][this.getOrientation(this.creation)].height,
        }
      };

      this.validateAndCorrectAllElements();
    });
  }

  public validateAndCorrectAllElements(): void {
    for (const slide of this.creation.slides) {
      for (const element of slide.elements) {
        const widthProperty: IElementProperty = this.getPropertyType(ElementType.ANY, 'size.width');
        const heightProperty: IElementProperty = this.getPropertyType(ElementType.ANY, 'size.height');

        const positionXProperty: IElementProperty = this.getPropertyType(ElementType.ANY, 'position.x');
        const positionYProperty: IElementProperty = this.getPropertyType(ElementType.ANY, 'position.y');

        this.setElementProperty(element, widthProperty, element.size.width);
        this.setElementProperty(element, heightProperty, element.size.height);

        this.setElementProperty(element, positionXProperty, element.position.x);
        this.setElementProperty(element, positionYProperty, element.position.y);
      }
    }
  }

  public closeSidebar(): void {
    this.waveform.stopAllPlayback();

    this.sidebarIsActive = false;
  }

  public openSidebar(activity: string): void {
    this.waveform.stopAllPlayback();

    this.sidebarActivity = activity;
    this.sidebarIsActive = true;
  }

  public toggleSidebar(activity: string): void {
    this.waveform.stopAllPlayback();

    if (this.sidebarActivity === activity && this.sidebarIsActive) {
      this.closeSidebar();
      return;
    }

    this.openSidebar(activity);
  }

  public openLibrary(types: string[]): void {
    this.librarySearchTypes = types;

    this.searchMediaLibrary();
  }

  public getCurrentLibraryResultTags(): string[] {
    const tags = {};
    for (const type of Object.keys(this.librarySearchResults)) {
      for (const result of this.librarySearchResults[type]) {
        if (result.tags && result.tags.length > 0) {
          for (const tag of result.tags) {
            if (!tags[tag]) {
              tags[tag] = 0;
            }
            tags[tag]++;
          }
        }
      }
    }

    const sortedTags = Object.keys(tags).sort((a, b) => tags[b] - tags[a]);

    return sortedTags;
  }

  public async triggerLibrarySearch(query: string): Promise<void> {
    this.librarySearchQuery = query;
    this.searchMediaLibrary();
  }

  public isViewingElements(): boolean {
    const types = this.getLibraryResultKeys();

    return types.indexOf('image') !== -1 || types.indexOf('graphic') !== -1 || types.indexOf('video') !== -1;
  }

  public openActivityBar(activity: string): void {
    this.activityBarActivity = activity;
    this.activityBarIsActive = true;
  }

  public closeActivityBar(): void {
    this.activityBarIsActive = false;
  }

  public toggleActivityBar(activity: string): void {
    if (this.activityBarActivity === activity && this.activityBarIsActive) {
      this.closeActivityBar();
      return;
    }

    this.openActivityBar(activity);
  }

  public getNestedOptionLabel(collection: IVideoBuilderNestedEnumOption[], value: any): string {
    let search;
    search = (array: IVideoBuilderNestedEnumOption[]) => {
      for (const item of array) {
        if (item.options) {
          const found = search(item.options);

          if (found) {
            return found;
          }
        }

        if (item.value === value) {
          return item.label;
        }
      }

      return null;
    }

    return search(collection);
  }

  public createTextElement(): void {
    this.closeSidebar();
    const addTextToolbarAction = new AddTextToolbarAction();
    this.executeToolbarAction(addTextToolbarAction.execute);
  }

  public createShapeElement(): void {
    this.closeSidebar();
    const addShapeToolbarAction = new AddShapeToolbarAction();
    this.executeToolbarAction(addShapeToolbarAction.execute);
  }

  public async getMediaLibraryResults(): Promise<void> {
    if (!this.librarySearchHasMore || this.librarySearchIsLoading) {
      return;
    }

    this.librarySearchIsLoading = true;
    this.librarySearchPage++;

    let count = (this.librarySearchTypes.length > 1) ? this.LIBRARY_SUMMARY_VIEW_PAGE_SIZE : this.LIBRARY_DETAILED_VIEW_PAGE_SIZE;

    const results = await this.library.queryMediaLibraries(this.librarySearchQuery, this.librarySearchPage, count, this.librarySearchTypes).toPromise();

    for (const type of Object.keys(results)) {
      if (!this.librarySearchResults[type]) {
        this.librarySearchResults[type] = [];
      }
      this.librarySearchResults[type].push(...results[type]);
    }

    const maxResultLength = Math.max(...Object.keys(results).map(key => results[key].length));
    if (maxResultLength === 0) {
      this.librarySearchHasMore = false;
    }
    this.librarySearchIsLoading = false;
  }

  public getTotalLibraryResultCount(): number {
    let sum = 0;
    for (const amount of Object.keys(this.librarySearchResults).map(key => this.librarySearchResults[key].length)) {
      sum += amount;
    }
    return sum;
  }

  public getLibraryResultKeys(): string[] {
    return Object.keys(this.librarySearchResults);
  }

  public async searchMediaLibrary(): Promise<void> {
    this.librarySearchPage = 1;
    this.librarySearchResults = {};
    this.librarySearchHasMore = true;

    await this.getMediaLibraryResults();
  }

  public getViewport(orientation: string | undefined = undefined): IViewportConfiguration {
    if (orientation) {
      return this.viewportOptions[orientation];
    }

    return this.viewportOptions[this.getOrientation(this.creation)];
  }

  public async addShape(svg: { model: ISVG, generator: Function }): Promise<void> {
    const centerX = (this.creation.output.resolution.width / 2);
    const centerY = (this.creation.output.resolution.height / 2)

    const addShapeAction = new AddShapeToolbarAction();
    await this.executeToolbarAction(addShapeAction.execute, [svg.generator(centerX, centerY)]);
  }

  public async addMediaFromLibrary(result: ILibraryResult, x: number = -1, y: number = -1): Promise<void> {
    const mediaAspectRatio = result.width / result.height;
    const scaledWidth = this.MEDIA_LIBRARY_MAX_DEFAULT_WIDTH;
    const scaledHeight = this.MEDIA_LIBRARY_MAX_DEFAULT_WIDTH / mediaAspectRatio;

    let element: IElement | undefined = undefined;

    switch (result.type) {
      case 'image':
      case 'graphic':
        const addImageAction = new AddImageToolbarAction();
        element = await this.executeToolbarAction(addImageAction.execute, [result.url, scaledWidth, scaledHeight]);
        break;
      case 'video':
        const addVideoAction = new AddVideoToolbarAction();
        element = await this.executeToolbarAction(addVideoAction.execute, [result.url, scaledWidth, scaledHeight]);
        break;
      case 'music':
        this.setMusicFromURL(result.description, result.url, 60);
        break;
    }

    if (element) {
      this.setActiveElement(element);

      const positionXProperty = this.getPropertyType(ElementType.ANY, 'position.x');
      const positionYProperty = this.getPropertyType(ElementType.ANY, 'position.y');

      if (element && x !== -1 && y !== -1) {
        const centerX = x - (element.size.width / 2);
        const centerY = y - (element.size.height / 2);

        this.setElementProperty(this.selectedElement, positionXProperty, centerX);
        this.setElementProperty(this.selectedElement, positionYProperty, centerY);
      } else {
        const centerX = (this.creation.output.resolution.width / 2) - (element.size.width / 2);
        const centerY = (this.creation.output.resolution.height / 2) - (element.size.height / 2);
        this.setElementProperty(this.selectedElement, positionXProperty, centerX);
        this.setElementProperty(this.selectedElement, positionYProperty, centerY);
      }
    }
  }

  private checkContextMenuElement(element: IElement | undefined, type: ElementType | undefined = undefined): boolean {
    if (element) {
      if (type && element.type !== type) {
        return false;
      }

      this.setActiveElement(element);
      return true;
    }

    return false;
  }

  public onContainerDrop(e: DndDropEvent): void {
    const { event, data } = e;

    const contentElementBoundingBox: any = this.videoContentElement.nativeElement.getBoundingClientRect();

    const mouseX: number = event.clientX - contentElementBoundingBox.x;
    const mouseY: number = event.clientY - contentElementBoundingBox.y;

    const contentRatioX: number = this.getViewport().editor.width / this.creation.output.resolution.width;
    const contentRatioY: number = this.getViewport().editor.height / this.creation.output.resolution.height;

    const contentAdjustedX: number = mouseX / contentRatioX;
    const contentAdjustedY: number = mouseY / contentRatioY;

    this.addMediaFromLibrary(data, contentAdjustedX, contentAdjustedY);
  }

  public onContainerDragover(e: DragEvent): void {
    const mouseX = e.clientX;
    const mouseY = e.clientY;
  }

  public getElementsSnappingPoints(exclude: IElement | undefined = undefined): { upper: { x: number[], y: number[] }, lower: { x: number[], y: number[] } } {
    if (!this.currentSlide) {
      return { upper: { x: [], y: [] }, lower: { x: [], y: [] } };
    }

    const elements = this.currentSlide.elements;

    const upperPoints: { x: number[], y: number[] } = { x: [], y: [] };
    const lowerPoints: { x: number[], y: number[] } = { x: [], y: [] };

    const editorWidth: number = this.creation.output.resolution.width;
    const editorHeight: number = this.creation.output.resolution.height;

    for (const element of elements) {
      if (element === exclude) {
        continue;
      }

      const positionX: number = element['position']['x'];
      const positionY: number = element['position']['y'];
      const sizeX: number = element['size']['width'];
      const sizeY: number = element['size']['height'];

      const lowerX = (positionX) / editorWidth;
      const upperX = (positionX + sizeX) / editorWidth;
      const lowerY = (positionY) / editorHeight;
      const upperY = (positionY + sizeY) / editorHeight;

      lowerPoints.x.push(lowerX);
      lowerPoints.y.push(lowerY);
      upperPoints.x.push(upperX);
      upperPoints.y.push(upperY);
    }

    return {
      upper: upperPoints,
      lower: lowerPoints
    };
  }

  public onClickMultiselectTransformer(e: MouseEvent): void {
    e.stopPropagation();

    const contentElementBoundingBox: any = this.videoContentElement.nativeElement.getBoundingClientRect();
    const transformerBoundingBox: any = this.selectorTransformerElement.nativeElement.getBoundingClientRect();

    this.isDraggingSelectionMoveBox = true;

    const mouseX: number = e.clientX - transformerBoundingBox.x;
    const mouseY: number = e.clientY - transformerBoundingBox.y;

    const contentRatioX: number = this.getViewport().editor.width / this.creation.output.resolution.width;
    const contentRatioY: number = this.getViewport().editor.height / this.creation.output.resolution.height;

    const contentAdjustedX: number = mouseX / contentRatioX;
    const contentAdjustedY: number = mouseY / contentRatioY;

    const elementGrabPointX: number = contentAdjustedX;
    const elementGrabPointY: number = contentAdjustedY;

    this.mousePivotPointX = elementGrabPointX;
    this.mousePivotPointY = elementGrabPointY;
  }

  public onStartElementRotate(element: IElement, e: MouseEvent): void {
    this.onClickElement(element, e);
    this.initialRotation = element.rotation;
    this.rotating = true;
  }

  public onHoverElement(element: IElement): void {
    this.hoveredElement = element;
  }

  public onUnhoverElement(element: IElement): void {
    this.hoveredElement = undefined;
  }

  public onClickElement(element: IElement, e: MouseEvent): void {
    if (this.selectedElement !== element) {
      this.startDragging(element, e);
    }

    this.setActiveElement(element);

    const contentElementBoundingBox: any = this.videoContentElement.nativeElement.getBoundingClientRect();

    const mouseX: number = e.clientX - contentElementBoundingBox.x;
    const mouseY: number = e.clientY - contentElementBoundingBox.y;

    const contentRatioX: number = this.getViewport().editor.width / this.creation.output.resolution.width;
    const contentRatioY: number = this.getViewport().editor.height / this.creation.output.resolution.height;

    const contentAdjustedX: number = mouseX / contentRatioX;
    const contentAdjustedY: number = mouseY / contentRatioY;

    const positionX: number = this.selectedElement.type === 'shape' ? (this.selectedElement as ShapeElement).svg.getOriginPoint().x : this.selectedElement['position']['x'];
    const positionY: number = this.selectedElement.type === 'shape' ? (this.selectedElement as ShapeElement).svg.getOriginPoint().y : this.selectedElement['position']['y'];

    const elementGrabPointX: number = contentAdjustedX - positionX;
    const elementGrabPointY: number = contentAdjustedY - positionY;

    this.mousePivotPointX = elementGrabPointX;
    this.mousePivotPointY = elementGrabPointY;

    // e.preventDefault();
    // e.stopImmediatePropagation();
    // e.stopPropagation();
  }

  private async handleShapeDragging(mouseX: number, mouseY: number): Promise<boolean> {
    const contentRatioX: number = this.getViewport().editor.width / this.creation.output.resolution.width;
    const contentRatioY: number = this.getViewport().editor.height / this.creation.output.resolution.height;

    const contentAdjustedX: number = mouseX / contentRatioX;
    const contentAdjustedY: number = mouseY / contentRatioY;

    let newPositionX: number = contentAdjustedX - this.mousePivotPointX;
    let newPositionY: number = contentAdjustedY - this.mousePivotPointY;

    const shapeElement = this.selectedElement as ShapeElement;

    shapeElement.svg.changeOrigin(newPositionX, newPositionY);
    shapeElement.svg.validateBounds(this.creation.output.resolution.width, this.creation.output.resolution.height);

    this.renderShape(shapeElement);

    return true;
  }

  private async handleDragging(mouseX: number, mouseY: number): Promise<boolean> {
    const contentRatioX: number = this.getViewport().editor.width / this.creation.output.resolution.width;
    const contentRatioY: number = this.getViewport().editor.height / this.creation.output.resolution.height;

    const contentAdjustedX: number = mouseX / contentRatioX;
    const contentAdjustedY: number = mouseY / contentRatioY;

    let newPositionX: number = contentAdjustedX - this.mousePivotPointX;
    let newPositionY: number = contentAdjustedY - this.mousePivotPointY;

    const positionXProperty: IElementProperty = this.getPropertyType(ElementType.ANY, 'position.x');
    const positionYProperty: IElementProperty = this.getPropertyType(ElementType.ANY, 'position.y');

    const sizeX: number = this.selectedElement['size']['width'];
    const sizeY: number = this.selectedElement['size']['height'];

    const clampThreshold: number = (this.isControlPressed) ? 0 : this.DEFAULT_TRANSFORM_CLAMP_THRESHOLD;

    const elementClamps = this.getElementsSnappingPoints(this.selectedElement);

    const elementsLowerXMinClamp = createClampInterval(0, this.creation.output.resolution.width, elementClamps.lower.x);
    const elementsLowerYMinClamp = createClampInterval(0, this.creation.output.resolution.height, elementClamps.lower.y);

    const elementsLowerXMaxClamp = createClampInterval(0, this.creation.output.resolution.width, elementClamps.lower.x, sizeX);
    const elementsLowerYMaxClamp = createClampInterval(0, this.creation.output.resolution.height, elementClamps.lower.y, sizeY);

    const elementsUpperXMinClamp = createClampInterval(0, this.creation.output.resolution.width, elementClamps.upper.x);
    const elementsUpperYMinClamp = createClampInterval(0, this.creation.output.resolution.height, elementClamps.upper.y);

    const elementsUpperXMaxClamp = createClampInterval(0, this.creation.output.resolution.width, elementClamps.upper.x, sizeX);
    const elementsUpperYMaxClamp = createClampInterval(0, this.creation.output.resolution.height, elementClamps.upper.y, sizeY);

    const positionXMinClamp = createClampInterval(0, this.creation.output.resolution.width, [0]);
    const positionYMinClamp = createClampInterval(0, this.creation.output.resolution.height, [0]);

    const positionXMaxClamp = createClampInterval(0, this.creation.output.resolution.width, [1], sizeX);
    const positionYMaxClamp = createClampInterval(0, this.creation.output.resolution.height, [1], sizeY);

    const centerXClamp = createClampInterval(0, this.creation.output.resolution.width, [0, 0.25, 0.5, 0.75, 1], sizeX / 2);
    const centerYClamp = createClampInterval(0, this.creation.output.resolution.height, [0, 0.25, 0.5, 0.75, 1], sizeY / 2);

    const xClamps = [positionXMinClamp, positionXMaxClamp, centerXClamp, elementsLowerXMinClamp, elementsLowerXMaxClamp, elementsUpperXMinClamp, elementsUpperXMaxClamp];
    const yClamps = [positionYMinClamp, positionYMaxClamp, centerYClamp, elementsLowerYMinClamp, elementsLowerYMaxClamp, elementsUpperYMinClamp, elementsUpperYMaxClamp];

    let [clampedPositionX, clampedPointValueX] = checkForClamps(xClamps, newPositionX, clampThreshold);
    let [clampedPositionY, clampedPointValueY] = checkForClamps(yClamps, newPositionY, clampThreshold);

    this.clampLineX = clampedPointValueX;
    this.clampLineY = clampedPointValueY;

    clampedPositionX = clamp(clampedPositionX, 0, this.creation.output.resolution.width - sizeX);
    clampedPositionY = clamp(clampedPositionY, 0, this.creation.output.resolution.height - sizeY);

    const setPositionXResult: boolean = await this.setElementProperty(this.selectedElement, positionXProperty, clampedPositionX);
    const setPositionYResult: boolean = await this.setElementProperty(this.selectedElement, positionYProperty, clampedPositionY);

    return setPositionXResult && setPositionYResult;
  }

  private async handleTransform(mouseX: number, mouseY: number): Promise<boolean> {
    const contentRatioX: number = this.getViewport().editor.width / this.creation.output.resolution.width;
    const contentRatioY: number = this.getViewport().editor.height / this.creation.output.resolution.height;

    const contentAdjustedX: number = mouseX / contentRatioX;
    const contentAdjustedY: number = mouseY / contentRatioY;

    const positionXProperty: IElementProperty = this.getPropertyType(ElementType.ANY, 'position.x');
    const positionYProperty: IElementProperty = this.getPropertyType(ElementType.ANY, 'position.y');

    const sizeWidthProperty: IElementProperty = this.getPropertyType(ElementType.ANY, 'size.width');
    const sizeHeightProperty: IElementProperty = this.getPropertyType(ElementType.ANY, 'size.height');

    const sizeX: number = this.selectedElement['size']['width'];
    const sizeY: number = this.selectedElement['size']['height'];

    const positionX: number = this.selectedElement['position']['x'];
    const positionY: number = this.selectedElement['position']['y'];

    // Upper coords is the top left
    // Lower coords is the bottom right
    const currentPointTopLeft: { x: number, y: number } = { x: positionX, y: positionY };
    const currentPointBottomLeft: { x: number, y: number } = { x: positionX, y: positionY + sizeY };
    const currentPointTopRight: { x: number, y: number } = { x: positionX + sizeX, y: positionY };
    const currentPointBottomRight: { x: number, y: number } = { x: positionX + sizeX, y: positionY + sizeY };

    const currentPointTop: { x: number, y: number } = { x: positionX + (sizeX / 2), y: positionY + sizeY };
    const currentPointRight: { x: number, y: number } = { x: positionX + sizeX, y: positionY + (sizeY / 2) };
    const currentPointBottom: { x: number, y: number } = { x: positionX + (sizeX / 2), y: positionY };
    const currentPointLeft: { x: number, y: number } = { x: positionX, y: positionY + (sizeY / 2) };

    let mousePoint: { x: number, y: number } = { x: contentAdjustedX, y: contentAdjustedY };
    let pivotPoint: { x: number, y: number };

    const additionalXClamps = [];
    const additionalYClamps = [];

    switch (this.transformHandle) {
      case TransformHandle.TOP_LEFT:
        pivotPoint = currentPointBottomRight;
        break;
      case TransformHandle.TOP_RIGHT:
        pivotPoint = currentPointBottomLeft;
        break;
      case TransformHandle.BOTTOM_LEFT:
        pivotPoint = currentPointTopRight;
        break;
      case TransformHandle.BOTTOM_RIGHT:
        pivotPoint = currentPointTopLeft;
        break;
      // case TransformHandle.TOP:
      //   pivotPoint = currentPointTop;
      //   break;
      // case TransformHandle.RIGHT:
      //   pivotPoint = currentPointRight;
      //   break;
      // case TransformHandle.BOTTOM:
      //   pivotPoint = currentPointBottom;
      //   break;
      // case TransformHandle.LEFT:
      //   pivotPoint = currentPointLeft;
      //   break;
    }

    const clampThreshold: number = (this.isControlPressed) ? 0 : this.DEFAULT_TRANSFORM_CLAMP_THRESHOLD;

    const elementClamps = this.getElementsSnappingPoints(this.selectedElement);

    const elementsLowerXMinClamp = createClampInterval(0, this.creation.output.resolution.width, elementClamps.lower.x);
    const elementsLowerYMinClamp = createClampInterval(0, this.creation.output.resolution.height, elementClamps.lower.y);

    const elementsLowerXMaxClamp = createClampInterval(0, this.creation.output.resolution.width, elementClamps.lower.x, sizeX);
    const elementsLowerYMaxClamp = createClampInterval(0, this.creation.output.resolution.height, elementClamps.lower.y, sizeY);

    const elementsUpperXMinClamp = createClampInterval(0, this.creation.output.resolution.width, elementClamps.upper.x);
    const elementsUpperYMinClamp = createClampInterval(0, this.creation.output.resolution.height, elementClamps.upper.y);

    const elementsUpperXMaxClamp = createClampInterval(0, this.creation.output.resolution.width, elementClamps.upper.x, sizeX);
    const elementsUpperYMaxClamp = createClampInterval(0, this.creation.output.resolution.height, elementClamps.upper.y, sizeY);

    const positionXClamp = createClampInterval(0, this.creation.output.resolution.width, [0, 0.5, 1]);
    const positionYClamp = createClampInterval(0, this.creation.output.resolution.height, [0, 0.5, 1]);

    const xClamps = [positionXClamp, elementsLowerXMinClamp, elementsLowerXMaxClamp, elementsUpperXMinClamp, elementsUpperXMaxClamp, ...additionalXClamps];
    const yClamps = [positionYClamp, elementsLowerYMinClamp, elementsLowerYMaxClamp, elementsUpperYMinClamp, elementsUpperYMaxClamp, ...additionalYClamps];

    let [clampedMousePositionX, clampedMousePointValueX] = checkForClamps(xClamps, mousePoint.x, clampThreshold);
    let [clampedMousePositionY, clampedMousePointValueY] = checkForClamps(yClamps, mousePoint.y, clampThreshold);
    let [clampedPivotPositionX, clampedPivotPointValueX] = checkForClamps(xClamps, pivotPoint.x, clampThreshold);
    let [clampedPivotPositionY, clampedPivotPointValueY] = checkForClamps(yClamps, pivotPoint.y, clampThreshold);

    mousePoint.x = clampedMousePositionX;
    mousePoint.y = clampedMousePositionY;

    pivotPoint.x = clampedPivotPositionX;
    pivotPoint.y = clampedPivotPositionY;

    this.clampLineX = [clampedMousePointValueX, clampedPivotPointValueX].find(value => value !== undefined);
    this.clampLineY = [clampedMousePointValueY, clampedPivotPointValueY].find(value => value !== undefined);

    let newPositionX: number = Math.min(mousePoint.x, pivotPoint.x);
    let newPositionY: number = Math.min(mousePoint.y, pivotPoint.y);

    let newSizeX: number = Math.max(mousePoint.x, pivotPoint.x) - newPositionX;
    let newSizeY: number = Math.max(mousePoint.y, pivotPoint.y) - newPositionY;

    newPositionX = clamp(newPositionX, 0, this.creation.output.resolution.width - sizeX);
    newPositionY = clamp(newPositionY, 0, this.creation.output.resolution.height - sizeY);

    await this.setElementProperty(this.selectedElement, positionXProperty, newPositionX);
    await this.setElementProperty(this.selectedElement, positionYProperty, newPositionY);
    await this.setElementProperty(this.selectedElement, sizeWidthProperty, newSizeX);
    await this.setElementProperty(this.selectedElement, sizeHeightProperty, newSizeY);

    return true;
  }

  @HostListener('document:mouseup', ['$event'])
  public onMouseUp(e: any): void {
    this.stopDragging(e);
    this.stopTransforming(e);
    this.stopShaping(e);

    this.isExpandingSlideDurationLeft = false;
    this.isExpandingSlideDurationRight = false;

    this.croppingMusic = false;
    this.lastMouseXonMusicBar = 0;

    this.isDraggingSelectionBox = false;
    this.isDraggingSelectionMoveBox = false;

    this.rotating = false;

    this.clampLineX = undefined;
    this.clampLineY = undefined;
  }

  @HostListener('document:mousedown', ['$event'])
  public onMouseDown(e: MouseEvent): void {
    this.mouseDownX = e.clientX;
    this.mouseDownY = e.clientY;

    const composedPath = e.composedPath();
    if (composedPath) {
      const videoContainerIndexInClick: number = composedPath.indexOf(this.videoContainerElement.nativeElement);
      // kinda hacky
      if (videoContainerIndexInClick >= 0 && videoContainerIndexInClick <= 1) {
        this.clearElement();
        this.multiSelectedElements = [];
        this.isDraggingSelectionBox = true;
      }
    }
  }

  @HostListener('document:keydown.control', ['$event'])
  public async onKeyControlDown(e: KeyboardEvent): Promise<void> {
    this.isControlPressed = true;
  }

  @HostListener('document:keyup.control', ['$event'])
  public async onKeyControlUp(e: KeyboardEvent): Promise<void> {
    this.isControlPressed = false;
  }

  @HostListener('document:keydown.control.x')
  public async onKeyControlX(e: KeyboardEvent): Promise<void> {
    if (this.selectedElement && this.currentSlide) {
      this.currentlyCopiedElement = this.selectedElement;
      this.currentSlide.elements = this.currentSlide.elements.filter((element) => element !== this.selectedElement);
    }
  }

  @HostListener('document:keydown.control.c', ['$event'])
  public async onKeyControlC(e: KeyboardEvent): Promise<void> {
    if (this.editingText) {
      return;
    }

    if (this.selectedElement) {
      this.notification.displayInfo('Copied!', { timeOut: 1000 });
      this.currentlyCopiedElement = this.selectedElement;
    }
  }

  @HostListener('document:keydown.control.v', ['$event'])
  public async onKeyControlV(e: KeyboardEvent): Promise<void> {
    if (this.editingText) {
      return;
    }

    if (this.currentlyCopiedElement && this.currentSlide) {
      if (this.currentlyCopiedElement.type === 'shape') {
        this.notification.displayError('Cannot paste shape, coming soon!');
        return;
      }

      const clonedElement = this.currentlyCopiedElement.clone();
      this.currentSlide.elements.push(clonedElement);
      this.setActiveElement(clonedElement);
    }
  }

  @HostListener('document:keydown.control.s', ['$event'])
  public async onKeyControlS(e: KeyboardEvent): Promise<void> {
    e.preventDefault();

    if (!this.currentProject) {
      return;
    }

    switch (this.currentProject.type) {
      case (SavedProjectType.PROJECT):
        this.saveProject(false);
        break;
      case (SavedProjectType.TEMPLATE):
        this.saveTemplate(false);
        break;
    }
  }

  @HostListener('document:keydown.control.shift.s', ['$event'])
  public async onKeyControlShiftS(e: KeyboardEvent): Promise<void> {
    e.preventDefault();

    if (!this.currentProject) {
      return;
    }

    switch (this.currentProject.type) {
      case (SavedProjectType.PROJECT):
        this.saveProject(true);
        break;
      case (SavedProjectType.TEMPLATE):
        this.saveTemplate(true);
        break;
    }
  }

  @HostListener('document:keydown.delete', ['$event'])
  public async onKeyDelete(e: KeyboardEvent): Promise<void> {
    if (this.editingText) {
      return;
    }

    if (this.selectedElement) {
      this.removeElement(this.selectedElement);
      this.clearElement();
    }

    if (this.multiSelectedElements.length > 0) {
      for (const entry of this.multiSelectedElements) {
        const element = entry.element;
        this.removeElement(element);
      }
      this.multiSelectedElements = [];
    }
  }

  @HostListener('document:mousemove', ['$event'])
  public async onMouseMove(e: MouseEvent): Promise<void> {
    if (this.croppingMusic) {
      this.onCroppingMusic(e);
    }

    if (this.isExpandingSlideDurationLeft || this.isExpandingSlideDurationRight) {
      this.onDraggingSlideDuration(e, this.isExpandingSlideDurationLeft ? 'left' : 'right');
    }

    if (this.selectedElement && this.rotating) {
      this.onElementRotate(e);
    }

    if (this.selectedElement && (this.dragging || this.transforming)) {
      this.onElementDrag(e);
    }

    if (this.selectedElement && (this.shaping)) {
      this.onElementShape(e);
    }

    if (this.isDraggingSelectionBox) {
      this.onMultiselectDrag(e);
    }

    if (this.isDraggingSelectionMoveBox) {
      this.onMultiselectTransformDrag(e);
    }

    this.lastRawMouseX = e.clientX;
    this.lastRawMouseY = e.clientY;
  }

  public async onElementRotate(e: MouseEvent): Promise<void> {
    const contentElementBoundingBox: any = this.videoContentElement.nativeElement.getBoundingClientRect();

    let rawX: number = e.clientX - contentElementBoundingBox.x;
    let rawY: number = e.clientY - contentElementBoundingBox.y;

    const clampThreshold: number = (this.isControlPressed) ? 0 : this.DEFAULT_ROTATION_CLAMP_THRESHOLD;

    const contentRatioX: number = this.getViewport().editor.width / this.creation.output.resolution.width;
    const contentRatioY: number = this.getViewport().editor.height / this.creation.output.resolution.height;

    let centerX: number = this.selectedElement.position.x + (this.selectedElement.size.width / 2);
    let centerY: number = this.selectedElement.position.y + (this.selectedElement.size.height / 2);

    const contentAdjustedX: number = rawX / contentRatioX;
    const contentAdjustedY: number = rawY / contentRatioY;

    const offsetX: number = contentAdjustedX - centerX;
    const offsetY: number = contentAdjustedY - centerY;

    // See? Geometry class WAS useful!
    let rotation: number = ((Math.atan2(offsetY, offsetX) * 180) / Math.PI) + 90;
    rotation = rotation < 0 ? 360 - Math.abs(rotation) : rotation;

    const rotationClamp = createClampInterval(0, 360, [0, 0.125, 0.25, 0.375, 0.5, 0.625, 0.75, 0.875]);

    const rotationClamps = [rotationClamp];

    let [clampedRotation, clampedRotationValue] = checkForClamps(rotationClamps, rotation, clampThreshold);

    const rotationProperty = this.getPropertyType(ElementType.ANY, 'rotation');

    this.setElementProperty(this.selectedElement, rotationProperty, clampedRotation);
  }

  public async onMultiselectTransformDrag(e: MouseEvent): Promise<void> {
    const contentElementBoundingBox: any = this.videoContentElement.nativeElement.getBoundingClientRect();
    const transformerBoundingBox: any = this.selectorTransformerElement.nativeElement.getBoundingClientRect();

    let rawX: number = e.clientX - contentElementBoundingBox.x;
    let rawY: number = e.clientY - contentElementBoundingBox.y;

    let clampedX = clamp(rawX, 0, contentElementBoundingBox.width);
    let clampedY = clamp(rawY, 0, contentElementBoundingBox.height);

    const contentRatioX: number = this.getViewport().editor.width / this.creation.output.resolution.width;
    const contentRatioY: number = this.getViewport().editor.height / this.creation.output.resolution.height;

    const contentAdjustedX: number = clampedX / contentRatioX;
    const contentAdjustedY: number = clampedY / contentRatioY;

    const contentAdjustedTransformerWidth = transformerBoundingBox.width / contentRatioX;
    const contentAdjustedTransformerHeight = transformerBoundingBox.height / contentRatioY;

    let transformOriginX: number = contentAdjustedX - this.mousePivotPointX;
    let transformOriginY: number = contentAdjustedY - this.mousePivotPointY;

    let clampedTransformOriginX: number = clamp(0, transformOriginX, this.creation.output.resolution.width - contentAdjustedTransformerWidth);
    let clampedTransformOriginY: number = clamp(0, transformOriginY, this.creation.output.resolution.height - contentAdjustedTransformerHeight);

    let minimumInitialX: number = Math.min(...this.multiSelectedElements.map(entry => entry.element).map(element => element.position.x));
    let minimumInitialY: number = Math.min(...this.multiSelectedElements.map(entry => entry.element).map(element => element.position.y));

    for (const entry of this.multiSelectedElements) {
      const element = entry.element;

      const elementOffsetX = entry.element.position.x - minimumInitialX;
      const elementOffsetY = entry.element.position.y - minimumInitialY;

      const newPositionX = clampedTransformOriginX + elementOffsetX;
      const newPositionY = clampedTransformOriginY + elementOffsetY;

      element.position.x = newPositionX;
      element.position.y = newPositionY;
    }
  }

  public isMultiselected(element: IElement): boolean {
    return !!this.multiSelectedElements.find(entry => entry.element === element);
  }

  public async onElementShape(e: MouseEvent): Promise<void> {
    const contentElementBoundingBox: any = this.videoContentElement.nativeElement.getBoundingClientRect();
    const contentRatioX: number = this.getViewport().editor.width / this.creation.output.resolution.width;
    const contentRatioY: number = this.getViewport().editor.height / this.creation.output.resolution.height;

    let rawX: number = e.clientX - contentElementBoundingBox.x;
    let rawY: number = e.clientY - contentElementBoundingBox.y;

    let clampedX = clamp(rawX, 0, contentElementBoundingBox.width);
    let clampedY = clamp(rawY, 0, contentElementBoundingBox.height);

    this.shapingPoint.x = clampedX / contentRatioX;
    this.shapingPoint.y = clampedY / contentRatioY;

    this.renderShape(this.selectedElement as ShapeElement);
  }

  public async renderShape(element: ShapeElement) {
    element.svg.render();
    element.onRendered();
  }

  public async onElementDrag(e: MouseEvent): Promise<void> {
    // check if mouse move was in range of bounding box
    const contentElementBoundingBox: any = this.videoContentElement.nativeElement.getBoundingClientRect();

    let rawX: number = e.clientX - contentElementBoundingBox.x;
    let rawY: number = e.clientY - contentElementBoundingBox.y;

    let clampedX = clamp(rawX, 0, contentElementBoundingBox.width);
    let clampedY = clamp(rawY, 0, contentElementBoundingBox.height);

    let mouseX: number = clampedX;
    let mouseY: number = clampedY;

    if (this.dragging) {
      if (this.selectedElement.type === 'shape') {
        await this.handleShapeDragging(mouseX, mouseY);
      } else {
        await this.handleDragging(mouseX, mouseY);
      }
    } else if (this.transforming) {
      await this.handleTransform(mouseX, mouseY);
    }

    this.lastMouseX = mouseX;
    this.lastMouseY = mouseY;
  }

  public async onMultiselectDrag(e: MouseEvent): Promise<void> {
    if (!this.currentSlide) {
      return;
    }

    const contentElementBoundingBox: any = this.videoContentElement.nativeElement.getBoundingClientRect();
    const selectorBoundingBox: any = this.selectorElement.nativeElement.getBoundingClientRect();

    const lowerSelectedX = Math.max(selectorBoundingBox.x, contentElementBoundingBox.x);
    const lowerSelectedY = Math.max(selectorBoundingBox.y, contentElementBoundingBox.y);

    const upperSelectedX = Math.min(selectorBoundingBox.x + selectorBoundingBox.width, contentElementBoundingBox.x + contentElementBoundingBox.width);
    const upperSelectedY = Math.min(selectorBoundingBox.y + selectorBoundingBox.height, contentElementBoundingBox.y + contentElementBoundingBox.height);

    const normalizedLowerSelectedX = (lowerSelectedX - contentElementBoundingBox.x) / contentElementBoundingBox.width;
    const normalizedLowerSelectedY = (lowerSelectedY - contentElementBoundingBox.y) / contentElementBoundingBox.height;

    const normalizedUpperSelectedX = (upperSelectedX - contentElementBoundingBox.x) / contentElementBoundingBox.width;
    const normalizedUpperSelectedY = (upperSelectedY - contentElementBoundingBox.y) / contentElementBoundingBox.height;

    const outputWidth = this.creation.output.resolution.width;
    const outputHeight = this.creation.output.resolution.height;

    const selectedElements: IMultiselectElementEntry[] = [];

    for (const element of this.currentSlide.elements) {
      const positionX = element.position.x;
      const positionY = element.position.y;
      const sizeX = element.size.width;
      const sizeY = element.size.height;

      const centerX = positionX + (sizeX / 2);
      const centerY = positionY + (sizeY / 2);

      const normalizedCenterX = centerX / outputWidth;
      const normalizedCenterY = centerY / outputHeight;

      if (
        normalizedCenterX > normalizedLowerSelectedX &&
        normalizedCenterX < normalizedUpperSelectedX &&
        normalizedCenterY > normalizedLowerSelectedY &&
        normalizedCenterY < normalizedUpperSelectedY
      ) {
        selectedElements.push({
          element: element,
          initialX: positionX,
          initialY: positionY
        });
      }
    }

    this.multiSelectedElements = selectedElements;

    if (selectedElements.length === 1) {
      this.setActiveElement(selectedElements[0].element);
    } else {
      // if we have nothing selected, or more than one item, remove this
      this.clearElement();
    }
  }

  public startDragging(element: IElement, e: any): void {
    this.dragging = true;
    this.transforming = false;
  }

  public stopDragging(e: any): void {
    this.dragging = false;
  }

  public addAnimation(element: IElement): void {
    if (!this.currentSlide) {
      return;
    }

    const existingAnimation = this.currentSlide.animations.find(animation => animation.elementId === element.id);

    if (existingAnimation) {
      return;
    }

    this.currentSlide.animations.push({
      elementId: element.id,
      trigger: AnimationTriggerType.NONE,
      transition: {
        in: AnimationType.NONE,
        out: AnimationType.NONE,
      },
      speed: AnimationSpeed.NORMAL,
      delay: undefined,
      duration: undefined
    });

    this.openActivityBar('animate');
  }

  public removeAnimation(element: IElement): void {
    if (!this.currentSlide) {
      return;
    }

    const existingAnimationIndex = this.currentSlide.animations.findIndex(animation => animation.elementId === element.id);

    if (existingAnimationIndex === -1) {
      return;
    }

    this.currentSlide.animations.splice(existingAnimationIndex, 1);
  }

  public getElementById(id: string): IElement | undefined {
    if (!this.currentSlide) {
      return;
    }

    return this.currentSlide.elements.find(element => element.id === id);
  }

  public getAllElements(): IElement[] {
    if (!this.currentSlide) {
      return [];
    }

    return [].concat.apply([], this.creation.slides.map(slide => slide.elements));
  }

  public startTransforming(e: MouseEvent, transform: number): void {
    this.transforming = true;
    this.dragging = false;

    this.transformHandle = transform as TransformHandle;

    const contentElementBoundingBox: any = this.videoContentElement.nativeElement.getBoundingClientRect();

    const mouseX: number = e.clientX - contentElementBoundingBox.x;
    const mouseY: number = e.clientY - contentElementBoundingBox.y;

    const contentRatioX: number = this.getViewport().editor.width / this.creation.output.resolution.width;
    const contentRatioY: number = this.getViewport().editor.height / this.creation.output.resolution.height;

    const contentAdjustedX: number = mouseX / contentRatioX;
    const contentAdjustedY: number = mouseY / contentRatioY;

    const elementGrabPointX: number = contentAdjustedX;
    const elementGrabPointY: number = contentAdjustedY;

    this.mousePivotPointX = elementGrabPointX;
    this.mousePivotPointY = elementGrabPointY;
  }

  public startShaping(e: MouseEvent, point: ISVGPoint): void {
    this.shaping = true;
    this.shapingPoint = point;

    const contentElementBoundingBox: any = this.videoContentElement.nativeElement.getBoundingClientRect();

    const mouseX: number = e.clientX - contentElementBoundingBox.x;
    const mouseY: number = e.clientY - contentElementBoundingBox.y;

    const contentRatioX: number = this.getViewport().editor.width / this.creation.output.resolution.width;
    const contentRatioY: number = this.getViewport().editor.height / this.creation.output.resolution.height;

    const contentAdjustedX: number = mouseX / contentRatioX;
    const contentAdjustedY: number = mouseY / contentRatioY;

    const elementGrabPointX: number = contentAdjustedX;
    const elementGrabPointY: number = contentAdjustedY;

    this.mousePivotPointX = elementGrabPointX;
    this.mousePivotPointY = elementGrabPointY;
  }

  public stopTransforming(e: any): void {
    this.transforming = false;
  }

  public stopShaping(e: any): void {
    this.shaping = false;
  }

  public getPropertyType(elementType: ElementType, propertyType: string): IElementProperty {
    const categoryTypeInstance = this.types.find((category) => category.type === elementType);

    if (!categoryTypeInstance) {
      throw new Error(`Invalid property type requested - ${propertyType} not found on ${elementType}!`);
    }

    const propertyTypeInstance = categoryTypeInstance.properties.find((property) => property.value === propertyType);

    if (!propertyTypeInstance) {
      throw new Error(`Invalid property type requested - ${propertyType} not found on ${elementType}!`);
    }
    return propertyTypeInstance;
  }

  public getElementRotationCSS(element: IElement): any {
    return {
      'transform': `rotate(${element.rotation}deg)`
    };
  }

  public getRotatorBoxCSS(element: IElement): any {
    const contentRatioX: number = this.getViewport().editor.width / this.creation.output.resolution.width;
    const contentRatioY: number = this.getViewport().editor.height / this.creation.output.resolution.height;

    if (!this.rotating) {
      return { ['display']: 'none' };
    }

    const contentAdjustedWidth: number = (element.size.width * contentRatioX);
    const diameter: number = (element.size.height * contentRatioY) + this.ROTATION_WIDGET_OFFSET;

    return {
      ['width']: `${diameter}px`,
      ['height']: `${diameter}px`,
      ['transform']: `translate(${(-diameter / 2) + contentAdjustedWidth / 2}px, 0) rotate(${element.rotation}deg)`
    }
  }

  public getClampXLineCSS(): any {
    if (this.clampLineX === undefined) {
      return { ['display']: 'none' };
    }

    return { ['left']: `${this.clampLineX * 100}%` };
  }

  public getClampYLineCSS(): any {
    if (this.clampLineY === undefined) {
      return { ['display']: 'none' };
    }

    return { ['top']: `${this.clampLineY * 100}%` };
  }

  public filterAnimationTriggerOptions(option: IVideoBuilderEnumOption, index: number): boolean {
    if (index === 0 && option.value === AnimationTriggerType.CONCURRENT) {
      return false;
    }

    return true;
  }

  public executeToolbarAction(func: Function, parameters?: any[]): any {
    if (!func) {
      return null;
    }

    const context: IVideoBuilderContext = {
      setElementProperty: (element: IElement, property: IElementProperty, value: any) => this.setElementProperty(element, property, value),
      getElementProperty: (element: IElement, property: IElementProperty) => this.getElementProperty(element, property),
      getPropertyType: (elementType: ElementType, propertyType: string) => this.getPropertyType(elementType, propertyType),
      getActiveSlide: () => this.currentSlide,
      getActiveElement: () => this.selectedElement,
      setActiveElement: (element: IElement) => this.setActiveElement(element),
      removeElement: (element: IElement) => this.removeElement(element),
      getViewportConfiguration: () => this.getViewport(),
      getModalService: () => this.modal,
      getSessionToken: () => this.sessionToken,
      getResolution: () => this.creation.output.resolution,
      addAnimation: (element: IElement) => this.addAnimation(element),
      removeAnimation: (element: IElement) => this.removeAnimation(element),
      resetCreation: () => this.resetCreation()
    };
    const result = func.apply(this, [context, ...(parameters || [])]);
    return result;
  }

  public async generateVideo(): Promise<void> {
    const buildingModal = this.modal.open(new BuildingModal(this.creation));
    buildingModal.onApprove(async (result) => {
      this.modal.open(new BuilderModal(result as string, this.sessionToken));
    });
  }

  public addSlide(): void {
    const slide: IVideoSlide = JSON.parse(JSON.stringify(this.defaultSlide));
    this.creation.slides.push(slide);
    this.selectSlide(slide);
    this.validateSlideTransitions();
  }

  public selectSlide(slide?: IVideoSlide): void {
    if (slide === this.currentSlide) {
      return;
    }

    this.currentSlide = slide;
    this.clearElement();
  }

  public removeSlide(slide: IVideoSlide): void {
    const slideIndex: number = this.creation.slides.findIndex((innerSlide) => innerSlide === slide);
    const currentIndex: number = this.creation.slides.findIndex((innerSlide) => innerSlide === this.currentSlide);
    if (slideIndex === -1) {
      return;
    }
    this.creation.slides.splice(slideIndex, 1);

    const newIndex: number = Math.abs(currentIndex >= slideIndex ? currentIndex - 1 : currentIndex) % this.creation.slides.length;
    if (this.creation.slides[newIndex]) {
      this.selectSlide(this.creation.slides[newIndex]);
    } else {
      this.selectSlide(undefined);
    }

    this.validateSlideTransitions();
  }

  public validateSlideTransitions(): void {
    for (const slide of this.creation.slides) {
      this.setSlideIntroTransition(slide, slide.transition.in as SlideTransition);
      this.setSlideOutroTransition(slide, slide.transition.out as SlideTransition);
    }
  }

  public setSlideCrossfade(slide: IVideoSlide, crossfade: boolean): void {
    const slideIndex: number = this.creation.slides.findIndex((innerSlide) => innerSlide === slide);

    slide.transition.crossfade = crossfade;

    // Ensure that the corresponding transitions between crossfaders works
    if (slideIndex !== 0) {
      const previousSlide: IVideoSlide = this.creation.slides[slideIndex - 1];
      if (previousSlide.transition.crossfade && previousSlide.transition.out) {
        slide.transition.in = previousSlide.transition.out;
      } else {
        previousSlide.transition.out = slide.transition.in;
      }
    }

    if (slideIndex !== this.creation.slides.length - 1) {
      const nextSlide: IVideoSlide = this.creation.slides[slideIndex + 1];
      if (nextSlide.transition.crossfade && nextSlide.transition.in) {
        slide.transition.out = nextSlide.transition.in;
      } else {
        nextSlide.transition.in = slide.transition.out;
      }
    }
  }

  public setSlideIntroTransition(slide: IVideoSlide, transition: SlideTransition): void {
    // This ensures that Luma masks are not overlayed on each other
    const slideIndex: number = this.creation.slides.findIndex((innerSlide) => innerSlide === slide);

    slide.transition.in = transition;

    if (slideIndex === -1 || slideIndex === 0) {
      return;
    }

    const previousSlide: IVideoSlide = this.creation.slides[slideIndex - 1];

    if (slide.transition.crossfade || previousSlide.transition.crossfade) {
      previousSlide.transition.out = transition;
    }
  }

  public setSlideOutroTransition(slide: IVideoSlide, transition: SlideTransition): void {
    const slideIndex: number = this.creation.slides.findIndex((innerSlide) => innerSlide === slide);

    slide.transition.out = transition;

    if (slideIndex === -1 || slideIndex === this.creation.slides.length - 1) {
      return;
    }

    const nextSlide: IVideoSlide = this.creation.slides[slideIndex + 1];

    if (slide.transition.crossfade || nextSlide.transition.crossfade) {
      nextSlide.transition.in = transition;
    }
  }

  public isSlideIntroTransitionLinked(slide: IVideoSlide) {
    const slideIndex: number = this.creation.slides.findIndex((innerSlide) => innerSlide === slide);

    if (slideIndex === -1 || slideIndex === 0) {
      return false;
    }

    const previousSlide: IVideoSlide = this.creation.slides[slideIndex - 1];

    return slide.transition.crossfade || previousSlide.transition.crossfade;
  }

  public isSlideOutroTransitionLinked(slide: IVideoSlide) {
    const slideIndex: number = this.creation.slides.findIndex((innerSlide) => innerSlide === slide);

    if (slideIndex === -1 || slideIndex === this.creation.slides.length - 1) {
      return;
    }

    const nextSlide: IVideoSlide = this.creation.slides[slideIndex + 1];

    return slide.transition.crossfade || nextSlide.transition.crossfade;
  }

  public getElementEditProperties(): IElementProperty[] {
    if (!this.selectedElement) {
      return [];
    }
    const relevantTypeNames: string[] = ['*', this.selectedElement.type];
    const relevantTypes: IElementPropertyCategory[] = this.types.filter((type) => relevantTypeNames.indexOf(type.type) !== -1);
    const properties: IElementProperty[] = [];
    for (const type of relevantTypes) {
      properties.push(...type.properties.filter(property => property.width !== ElementPropertyDisplayType.NONE));
    }
    return properties;
  }

  public getElementProperty(element: IElement, property: IElementProperty): any {
    // tslint:disable-next-line:no-eval
    const scopedElement = element;
    return eval(`scopedElement.${property.value}`);
  }

  public async setElementProperty(element: IElement, property: IElementProperty, value: any): Promise<boolean> {
    this.isEditorDirty = true;

    // this does get used via eval
    const scopedElement = element;

    if (property.validate) {
      const validation: any = property.validate(this.selectedElement, this.resolutionOptions[this.creation.output.resolution.type][this.creation.output.orientation], value);
      if (validation === undefined) {
        this.ref.tick();
        return false;
      } else {
        value = validation;
      }
    }

    let evalString: string = '';

    switch (property.type) {
      case 'number':
        evalString = `scopedElement.${property.value} = ${value};`;
        break;
      case 'string':
      case 'enum':
      case 'color':
        evalString = `scopedElement.${property.value} = "${value}";`;
        break;
      case 'file':
        let asset = (value && value.cdnUrl) ? value : undefined;

        if (!asset) {
          this.loader.setLoading(true, 'Uploading file...');
          try {
            asset = await this.uploadAsset(value as File);
          } catch (e) {
            this.notification.displayError(e);
            return false;
          } finally {
            this.loader.setLoading(false);
          }
        }

        evalString = `scopedElement.${property.value} = "${asset.cdnUrl}";`;
        break;
    }

    // DANGER ZONE !!!!!!!!!!!!!
    // tslint:disable-next-line:no-eval
    eval(evalString);
    // END DANGER ZONE !!!!!!!!!

    if (element.onPropertyChange) {
      element.onPropertyChange(property, value);
    }
    // this.ref.tick();

    return true;
  }

  public async uploadAsset(file: File): Promise<any> {
    let directory = await this.filesystem.getDirectoryByPath('Video Builder').toPromise();
    if (!directory) {
      directory = await this.filesystem.makeDirectory('Video Builder', undefined, 'system').toPromise();
    }

    const parallelHasher = new ParallelHasher('/assets/workers/md5.js');

    const fileHash = await parallelHasher.hash(file);
    const fileName = `${fileHash}_${file.name}`;

    const directoryDetails = await this.filesystem.getDirectoryDetails(directory).toPromise();
    const fileNameNoExt = file.name.substring(0, file.name.lastIndexOf('.'));
    const existingFile = directoryDetails.files.find((innerFile) => fileNameNoExt === innerFile.name && file.size === innerFile.size);
    if (existingFile) {
      return existingFile;
    }

    return new Promise((resolve, reject) => {
      this.filesystem
        .upload(file, directory, this.sessionToken)
        .pipe(
          catchError(async (event) => {
            const errorMessage = event.error.error || event.error.data;
            reject(errorMessage);
          })
        )
        .subscribe(async (event) => {
          if (!event) {
            return;
          }

          if (event.type === HttpEventType.Response) {
            // get response
            const upload = event.body;

            // wait for completed processing
            let uploadingFile;
            do {
              uploadingFile = await this.filesystem.getFileDetails(upload._id).toPromise();
              await sleep(5000);
            } while (!uploadingFile.cdnUrl);

            resolve(uploadingFile);
          }
        });
    });
  }

  private getDuration(asset): number {
    const metadata = asset.meta;
    if (metadata && metadata.length) {
      const duration = metadata.find((item) => item.key === 'duration');
      if (duration && duration.value) {
        return Math.round(duration.value * 10) / 10;
      }
    }
    return 0;
  }

  public getMusicCSS(): IElementStyleSheet {
    const duration: number = this.creation.music.duration || 0;
    const adjustedViewportWidth = this.getViewport().track.width * this.timelineZoomFactor;
    const style: IElementStyleSheet = {
      ['width']: `${adjustedViewportWidth * (duration / this.DEFAULT_SLIDE_LENGTH)}px`,
    };

    return style;
  }

  public getMusicPxPerSecond(): number {
    return (this.getViewport().track.width / this.DEFAULT_SLIDE_LENGTH) * this.timelineZoomFactor;
  }

  public onSliderHandlePull(e: MouseEvent, slide: IVideoSlide, direction: string): void {
    e.stopPropagation();

    if (direction === 'right') {
      this.isExpandingSlideDurationRight = true;
    } else {
      this.isExpandingSlideDurationLeft = true;
    }

    this.selectSlide(slide);
  }

  public onCroppingMusic(e: MouseEvent): void {
    if (!this.croppingMusic) return;
    const snapValue: number = 4;
    const mouseX: number = roundToNearest(e.clientX, snapValue);

    if (this.lastMouseXonMusicBar) {
      const deltaX: number = (mouseX - this.lastMouseXonMusicBar) / this.timelineZoomFactor;
      const musicDuration = this.creation.music.duration || 0;

      const newDuration = Math.round((musicDuration + (deltaX * this.DEFAULT_SLIDE_LENGTH) / this.getViewport().track.width) * 10) / 10;

      if (newDuration > 0) {
        this.creation.music.duration = newDuration;
      }
    }

    this.lastMouseXonMusicBar = mouseX;
  }

  public onDraggingSlideDuration(e: MouseEvent, direction: string): void {
    if (!this.currentSlide) {
      return;
    }

    const mouseDeltaX = e.clientX - this.lastRawMouseX;

    const slidePreviewWidth = this.getViewport().track.width;

    // 10 is the default slide duration (192px, or whatver is in viewport.track.width)
    const changeInDuration = (mouseDeltaX / slidePreviewWidth) * this.DEFAULT_SLIDE_LENGTH / this.timelineZoomFactor;

    let newDuration;
    if (direction === 'right') {
      newDuration = this.currentSlide.duration + changeInDuration;
    } else {
      newDuration = this.currentSlide.duration - changeInDuration;
    }

    this.currentSlide.duration = clamp(newDuration, 3, Number.POSITIVE_INFINITY);
  }

  public setActiveElement(element: IElement): void {
    this.selectedElement = element;
  }

  public clearElement(): void {
    this.selectedElement = null as any as IElement;
  }

  public removeElement(element: IElement): IElement | undefined {
    if (!this.currentSlide) {
      return;
    }

    const elementIndex = this.currentSlide.elements.findIndex(innerElement => innerElement.id === element.id);

    if (elementIndex === -1) {
      return;
    }

    // In case there was an associated animation
    this.removeAnimation(element);
    this.currentSlide.elements.splice(elementIndex, 1);

    return element;
  }

  public onStartEditingText(element: IElement, htmlElement: HTMLElement): void {
    this.editingText = true;
  }

  public onEndEditingText(element: IElement, htmlElement: HTMLElement): void {
    const innerText = htmlElement.innerText;
    element.value = innerText;
    this.editingText = false;
  }

  public getEditorCSS(): IElementStyleSheet {
    return {
      ['width']: `${this.getViewport().editor.width}px`,
      ['min-width']: `${this.getViewport().editor.width}px`,
      ['max-width']: `${this.getViewport().editor.width}px`,

      ['height']: `${this.getViewport().editor.height}px`,
      ['min-height']: `${this.getViewport().editor.height}px`,
      ['max-height']: `${this.getViewport().editor.height}px`,
    }
  }

  public getTrackSlideCSS(): IElementStyleSheet {
    return {
      ['max-width']: `${this.getViewport().track.width}px`,

      ['height']: `${this.getViewport().track.height}px`,
      ['min-height']: `${this.getViewport().track.height}px`,
      ['max-height']: `${this.getViewport().track.height}px`,
    }
  }

  public getTemplateCSS(template: IVideoBuilderProject): IElementStyleSheet {
    return {
      ['width']: `${this.getViewport(template.body.output.orientation).template.width}px`,
      ['min-width']: `${this.getViewport(template.body.output.orientation).template.width}px`,
      ['max-width']: `${this.getViewport(template.body.output.orientation).template.width}px`,

      ['height']: `${this.getViewport(template.body.output.orientation).template.height}px`,
      ['min-height']: `${this.getViewport(template.body.output.orientation).template.height}px`,
      ['max-height']: `${this.getViewport(template.body.output.orientation).template.height}px`,
    }
  }

  public getSlideCSS(slide: IVideoSlide, slideViewport: IViewportDimensions): IElementStyleSheet {
    const duration: number = slide.duration;
    const adjustedViewportWidth = slideViewport.width * this.timelineZoomFactor;
    const slideWidthStyle = `${adjustedViewportWidth * (duration / this.DEFAULT_SLIDE_LENGTH)}px`;
    const style: IElementStyleSheet = {
      ['width']: slideWidthStyle,
      ['max-width']: slideWidthStyle,
      ['background']: slide.background,
    };

    return style;
  }

  public getMultiselectCSS(): any {
    if (!this.isDraggingSelectionBox) {
      return { 'display': 'none' }
    };

    const editorBoundingBox: any = this.videoEditorElement.nativeElement.getBoundingClientRect();
    const editorBoundingBoxMinX = editorBoundingBox.x;
    const editorBoundingBoxMaxX = editorBoundingBox.x + editorBoundingBox.width;
    const editorBoundingBoxMinY = editorBoundingBox.y;
    const editorBoundingBoxMaxY = editorBoundingBox.y + editorBoundingBox.height;

    const pointAX = this.mouseDownX || 0;
    const pointAY = this.mouseDownY || 0;
    const pointBX = this.lastRawMouseX || 0;
    const pointBY = this.lastRawMouseY || 0;

    const lowerX = clamp(editorBoundingBoxMinX, Math.min(pointAX, pointBX), editorBoundingBoxMaxX);
    const lowerY = clamp(editorBoundingBoxMinY, Math.min(pointAY, pointBY), editorBoundingBoxMaxY);

    const upperX = clamp(editorBoundingBoxMinX, Math.max(pointAX, pointBX), editorBoundingBoxMaxX);
    const upperY = clamp(editorBoundingBoxMinY, Math.max(pointAY, pointBY), editorBoundingBoxMaxY);

    const height = upperY - lowerY;
    const width = upperX - lowerX;

    if (height < this.MINIMUM_SELECTION_THRESHOLD || width < this.MINIMUM_SELECTION_THRESHOLD) {
      return { 'display': 'none' }
    };

    return {
      'top': `${lowerY}px`,
      'height': `${upperY - lowerY}px`,
      'left': `${lowerX}px`,
      'width': `${upperX - lowerX}px`,
    }
  }

  public getMultiselectTransformerCSS(): any {
    if (this.multiSelectedElements.length < 2) {
      return { 'display': 'none' }
    };

    const contentRatioX: number = this.getViewport().editor.width / this.creation.output.resolution.width;
    const contentRatioY: number = this.getViewport().editor.height / this.creation.output.resolution.height;

    const contentElementBoundingBox: any = this.videoContentElement.nativeElement.getBoundingClientRect();
    const contentElementBoundingBoxMinX = contentElementBoundingBox.x;
    const contentElementBoundingBoxMaxX = contentElementBoundingBox.x + contentElementBoundingBox.width;
    const contentElementBoundingBoxMinY = contentElementBoundingBox.y;
    const contentElementBoundingBoxMaxY = contentElementBoundingBox.y + contentElementBoundingBox.height;

    const elementPointsAX: number[] = [];
    const elementPointsAY: number[] = [];

    const elementPointsBX: number[] = [];
    const elementPointsBY: number[] = [];

    for (const entry of this.multiSelectedElements) {
      const element = entry.element;

      const clientPointAX = contentElementBoundingBoxMinX + (element.position.x * contentRatioX);
      const clientPointAY = contentElementBoundingBoxMinY + (element.position.y * contentRatioY);

      const clientPointBX = contentElementBoundingBoxMinX + ((element.position.x + element.size.width) * contentRatioX);
      const clientPointBY = contentElementBoundingBoxMinY + ((element.position.y + element.size.height) * contentRatioY);

      elementPointsAX.push(clientPointAX);
      elementPointsAY.push(clientPointAY);
      elementPointsBX.push(clientPointBX);
      elementPointsBY.push(clientPointBY);
    }

    const pointAX = Math.min(...elementPointsAX);
    const pointAY = Math.min(...elementPointsAY);
    const pointBX = Math.max(...elementPointsBX);
    const pointBY = Math.max(...elementPointsBY);

    const lowerX = clamp(contentElementBoundingBoxMinX, pointAX, contentElementBoundingBoxMaxX);
    const lowerY = clamp(contentElementBoundingBoxMinY, pointAY, contentElementBoundingBoxMaxY);

    const upperX = clamp(contentElementBoundingBoxMinX, pointBX, contentElementBoundingBoxMaxX);
    const upperY = clamp(contentElementBoundingBoxMinY, pointBY, contentElementBoundingBoxMaxY);

    return {
      'top': `${lowerY}px`,
      'height': `${upperY - lowerY}px`,
      'left': `${lowerX}px`,
      'width': `${upperX - lowerX}px`,
    }
  }

  public getShapePointTransformerCSS(element: IElement, point: ISVGPoint): any {
    const transformerSize = 8;

    const contentElementBoundingBox: any = this.videoContentElement.nativeElement.getBoundingClientRect();

    const contentRatioX: number = this.getViewport().editor.width / this.creation.output.resolution.width;
    const contentRatioY: number = this.getViewport().editor.height / this.creation.output.resolution.height;

    const contentAdjustedX = contentElementBoundingBox.x + (point.x * contentRatioX);
    const contentAdjustedY = contentElementBoundingBox.y + (point.y * contentRatioY);

    return {
      'top': `${contentAdjustedY - (transformerSize)}px`,
      'left': `${contentAdjustedX - (transformerSize)}px`
    };
  }

  public getRenderedSVGCSS(viewport: IViewportDimensions): any {
    const contentRatioX: number = viewport.width / this.creation.output.resolution.width;
    const contentRatioY: number = viewport.height / this.creation.output.resolution.height;

    return {
      ['transform']: `scale(${contentRatioX}, ${contentRatioY})`
    };
  }

  public canGenerateVideo(): boolean {
    if (this.creation.slides.length === 0) {
      return false;
    }
    for (const slide of this.creation.slides) {
      for (const element of slide.elements) {
        if (element.type !== 'shape' && !element.value) {
          return false;
        }
      }
    }
    const minimumElementCount = Math.min(...this.creation.slides.map((slide) => slide.elements.length));
    return minimumElementCount > 0;
  }

  public dropSlide(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.creation.slides, event.previousIndex, event.currentIndex);
  }

  public dropAnimation(event: CdkDragDrop<string[]>): void {
    if (!this.currentSlide) {
      return;
    }

    moveItemInArray(this.currentSlide.animations, event.previousIndex, event.currentIndex);
  }

  public droppedFile(event): void {
    const droppedFile = event.files[0];
    if (droppedFile.fileEntry.isFile) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        let result;
        if (file.type.includes('image')) {
          const action = new AddImageToolbarAction();
          result = this.executeToolbarAction(action.execute);
        } else if (file.type.includes('video')) {
          const action = new AddVideoToolbarAction();
          result = this.executeToolbarAction(action.execute);
        }

        if (result) {
          const reader = new FileReader();
          reader.onload = () => {
            this.selectedElement.value = undefined;
            this.selectedElement.previewUrl = reader.result as string;
          };
          reader.readAsDataURL(file);
          this.uploadAsset(file).then((asset) => {
            this.selectedElement.value = asset.cdnUrl;
          });
        }
      });
    }
  }

  public droppedFileOnElement(event): void {
    const droppedFile = event.files[0];
    if (droppedFile.fileEntry.isFile) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file(async (file: File) => {
        let asset;
        if (file.type.includes(this.selectedElement.type)) {
          try {
            this.loader.setLoading(true, 'Uploading file...');
            asset = await this.uploadAsset(file);
          } catch (e) {
            this.notification.displayError(e);
            this.loader.setLoading(false);
            return;
          }
          this.loader.setLoading(false);
          this.selectedElement.value = asset.cdnUrl;
        }
      });
    }
  }

  private hydrateElementFromSchema(element: IElement): IElement {
    let hydratedElement;

    switch (element.type) {
      case 'image':
        hydratedElement = new ImageElement(element.value as string);
        break;
      case 'video':
        hydratedElement = new VideoElement(element.value as string);
        break;
      case 'text':
        hydratedElement = new TextElement(element.value as string);
        break;
      case 'shape':
        const castShapeElement = element as ShapeElement;
        const elementSvg = this.shapes.find(shape => shape.type === castShapeElement.svg.type);
        const generatedSvg = (elementSvg as { generator: Function }).generator(0, 0);
        generatedSvg.points = castShapeElement.svg.points;
        generatedSvg.properties = castShapeElement.svg.properties;
        hydratedElement = new ShapeElement(generatedSvg);
        break;
      default:
        throw new Error(`Cannot hydrate element of type ${element.type}!`);
    }

    // TODO: double check and ensure the ID gets set here
    for (const [key, value] of Object.entries(element)) {
      if (key === 'svg') {
        continue;
      }

      hydratedElement[key] = value;
    }

    return hydratedElement;
  }

  private loadTemplates(): void {
    this.builder.getTemplates().subscribe((templates) => {
      this.templates = [];
      for (const content of templates) {
        const item = { ...content, body: JSON.parse(content.body) } as IVideoBuilderProject;

        for (const slide of item.body.slides) {
          slide.elements = slide.elements.map((element) => this.hydrateElementFromSchema(element));
        }

        this.templates.push(item);
      }
    });
  }

  private loadProjects(): void {
    this.builder.getProjects().subscribe((projects) => {
      this.projects = [];
      for (const content of projects) {
        const item = { ...content, body: JSON.parse(content.body) } as IVideoBuilderProject;

        for (const slide of item.body.slides) {
          slide.elements = slide.elements.map((element) => this.hydrateElementFromSchema(element));
        }

        this.projects.push(item);
      }
    });
  }

  public saveProject(saveAs: boolean = false): void {
    if (this.currentProject && this.currentProject._id && !saveAs) {
      this.builder.editProject(this.currentProject._id!, this.currentProject.name, this.creation).subscribe((project) => {
        this.currentProject = project;
        this.loadProjects();

        this.isEditorDirty = false;
        this.notification.displaySuccess('Project saved!');
      });
    } else {
      const projectNameModal = this.modal.open(new InputModal('Save project', { header: 'Info', text: 'After saving, you can access your projects from any computer!' }, { label: 'Project name' }));

      projectNameModal.onApprove((result) => {
        this.builder.createProject(result as string, this.creation).subscribe((project) => {
          this.currentProject = { ...project };
          this.loadProjects();
        });

        this.isEditorDirty = false;
        this.notification.displaySuccess('Project saved!');
      });
    }
  }

  public saveTemplate(saveAs: boolean = false): void {
    if (this.currentProject && this.currentProject._id && !saveAs) {
      this.builder.editTemplate(this.currentProject._id!, this.currentProject.name, this.creation).subscribe((template) => {
        this.currentProject = template;
        this.loadTemplates();

        this.isEditorDirty = false;
        this.notification.displaySuccess('Template saved!');
      });
    } else {
      const projectNameModal = this.modal.open(new InputModal('Save template', { header: 'Info', text: 'You are saving this project as a template. It will be visible to all users on Citadel.' }, { label: 'Template name' }));

      projectNameModal.onApprove((result) => {
        this.builder.createTemplate(result as string, this.creation).subscribe((template) => {
          this.currentProject = { ...template };
          this.loadTemplates();
        });

        this.isEditorDirty = false;
        this.notification.displaySuccess('Template saved!');
      });
    }
  }

  public hasUnsavedChanges(): boolean {
    if (!this.currentProject) {
      return false;
    }

    if (this.getAllElements().length === 0) {
      return false;
    }

    return this.isEditorDirty;
  }

  public deleteProject(project: IVideoBuilderProject): void {
    if (!project._id) {
      this.resetCreation();
      this.notification.displaySuccess('Project deleted!');
      return;
    }

    const confirmModal = this.modal.open(new ConfirmModal(`Deleting ${project.name}`, `Are you sure you would like to delete this project?`));

    confirmModal.onApprove(() => {
      this.builder.deleteProject(project._id!).subscribe(() => {
        if (this.currentProject && this.currentProject._id === project._id) {
          this.resetCreation();
        }

        this.loadProjects();

        this.notification.displaySuccess('Project deleted!');
      });
    });
  }

  public deleteTemplate(template: IVideoBuilderProject): void {
    if (!template._id) {
      this.resetCreation();
      this.notification.displaySuccess('Template deleted!');
      return;
    }

    const confirmModal = this.modal.open(new ConfirmModal(`Deleting ${template.name}`, `Are you sure you would like to delete this template?`));

    confirmModal.onApprove(() => {
      this.builder.deleteTemplate(template._id!).subscribe(() => {
        if (this.currentProject && this.currentProject._id === template._id) {
          this.resetCreation();
        }

        this.loadTemplates();

        this.notification.displaySuccess('Template deleted!');
      });
    });
  }

  public isContentOwner(content) {
    return true;
    // TODO: REFACTOR
    // return content && this.userService.getUser()._id === content.user;
  }

  public canDelete(content) {
    return false;
    // TODO: REFACTOR
    // return this.isContentOwner(content) || this.userService.getUser().type === 'admin' || this.userService.getUser().type === 'internal';
  }

  public canUpdateContent() {
    return this.canGenerateVideo() && this.isContentOwner(this.currentProject);
  }

  public onSelectProject(content: IVideoBuilderProject) {
    content = { ...content };

    const confirmModal = this.modal.open(new ConfirmModal('Confirmation', `Are you sure you want to open this project? Your unsaved changes will be lost.`));
    confirmModal.onApprove(() => {
      this.currentProject = content;

      // Get a clone of the body so we don't modify it further
      this.loadCreation(content.body);
      this.currentSlide = this.creation.slides[0];
    });
  }

  public onSelectTemplate(content: IVideoBuilderProject) {
    content.body = { ...content.body };

    const confirmModal = this.modal.open(new ConfirmModal('Confirmation', `Are you sure you want to import this template? Your unsaved changes will be lost.`));
    confirmModal.onApprove(() => {
      this.loadCreation(content.body);
      this.currentSlide = this.creation.slides[0];
    });
  }

  public onCreateNewProject() {
    const confirmModal = this.modal.open(new ConfirmModal('Are you sure you would like to create a new project?', `You will lose your unsaved changes on your current project.`));
    confirmModal.onApprove(() => {
      this.resetCreation();
    });
  }

  public loadCreation(scaffold: IVideoScaffold): void {
    // These changes ensure backwards compatability with old projects without animations or orientation data
    if (!scaffold.output) {
      let output = {
        orientation: ProjectOrientation.HORIZONTAL,
        resolution: {
          type: ProjectResolution.HD,
          width: this.resolutionOptions[ProjectResolution.HD][ProjectOrientation.HORIZONTAL].width,
          height: this.resolutionOptions[ProjectResolution.HD][ProjectOrientation.HORIZONTAL].height
        }
      };
      scaffold.output = output;
    }

    for (const slide of scaffold.slides) {
      if (!slide.animations) {
        slide.animations = [];
      }
    }

    this.creation = scaffold;
  }

  public resetCreation(): void {
    this.creation = {
      music: {
        name: '',
        value: undefined,
        duration: 0,
      },
      resources: {},
      slides: [],
      output: {
        orientation: ProjectOrientation.HORIZONTAL,
        resolution: {
          type: ProjectResolution.HD,
          width: this.resolutionOptions[ProjectResolution.HD][ProjectOrientation.HORIZONTAL].width,
          height: this.resolutionOptions[ProjectResolution.HD][ProjectOrientation.HORIZONTAL].height
        }
      }
    };

    this.addSlide();

    const creationCopy = {};
    naiveDeepCopy(creationCopy, this.creation);

    this.currentProject = {
      name: 'Untitled project',
      type: SavedProjectType.PROJECT,
      body: creationCopy as IVideoScaffold
    };
  }

  public openContentLibrary() {
    let filter = '';
    switch (this.sidebarActivity) {
      case 'elements':
        filter = 'image/*,video/*';
        break;
      case 'music':
        filter = 'audio/*';
        break;
    }

    this.modal.open(new FilePickerModal(filter, false, true, this.sessionToken)).onApprove((files: any) => {
      const file = files[0] as IFile;

      if (!file.mimeType) {
        return;
      }

      if (file.mimeType.startsWith('video')) {
        const action = new AddVideoToolbarAction();
        this.executeToolbarAction(action.execute, [file.cdnUrl]);
      } else if (file.mimeType.startsWith('image')) {
        const action = new AddImageToolbarAction();
        this.executeToolbarAction(action.execute, [file.cdnUrl]);
      } else if (file.mimeType.startsWith('audio')) {
        this.setMusicFromFile(file);
      }
    });
  }

  public clearMusic() {
    this.creation.music = {
      name: '',
      value: undefined,
      duration: 0,
    };
  }

  public setMusicFromURL(name: string, url: string, duration: number = 0) {
    const newAsset = {
      name: name,
      value: url,
      duration: duration
    };

    this.creation.music = { ...newAsset };
  }

  public setMusicFromFile(file: IFile) {
    const newAsset = {
      name: file.name,
      value: file.cdnUrl,
      duration: this.getDuration(file),
    };

    this.creation.music = { ...newAsset };
  }

  public zoomTimelineInBy(amount: number) {
    this.timelineZoomFactor = clamp(this.timelineZoomFactor + amount, 0.25, 4);
  }

  public zoomTimelineOutBy(amount: number) {
    this.timelineZoomFactor = clamp(this.timelineZoomFactor - amount, 0.25, 4);
  }

  public onTimelineScroll(event: WheelEvent): void {
    event.stopImmediatePropagation();
    const deltaY = event.deltaY;

    if (deltaY < 0) {
      this.zoomTimelineInBy(0.0625);
    } else if (deltaY > 0) {
      this.zoomTimelineOutBy(0.0625);
    }
  }
}
