<div class="header">{{ header }}</div>
<div class="content">
  <div class="ui message" *ngIf="message">
    <div class="header">{{ message.header }}</div>
    <p>{{ message.text }}</p>
  </div>

  <div style="padding: 10px" *ngFor="let section of ctx.sections">
    <label style="font-weight: bold;">{{ section.label }}</label>
    <div
      style="margin-top: 5px; padding: 5px; background-color: #f9f9f9; border: 1px solid #ddd;"
    >
      {{ section.value }}
    </div>
  </div>

  <!-- Display JSON Stringified ctx.chosenResult -->
  <div style="padding: 10px;">
    <label style="font-weight: bold;">Selected Result:</label>
    <div
      style="margin-top: 5px; padding: 5px; background-color: #f4f4f4; border: 1px solid #ddd;"
    >
      {{ ctx.chosenResult | customTransform }}
    </div>
  </div>

  <div style="padding: 10px;" class="ui form">
    <div style="padding: 10px;" class="field">
      <div class="ui input fluid">
        <input
          type="text"
          [value]="ctx.searchTerms"
          (input)="onInputChange($event)"
        />
      </div>
    </div>
  </div>

  <!-- Search Results List -->
  <div style="padding: 20px;">
    <div class="dropdown-container" *ngIf="ctx.searchResults.length">
      <ul class="dropdown-list">
        <li
          *ngFor="let r of ctx.searchResults"
          (click)="onDropdownSelect(r)"
          [class.selected]="r === ctx.chosenResult"
        >
          {{ r.description }}
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="actions">
  <button class="ui button" (click)="modal.deny(undefined)">Cancel</button>
  <button
    app-tour="input-modal-next"
    class="ui button primary"
    (click)="save()"
    autofocus
    [disabled]="!hasValue()"
  >
    Next
  </button>
</div>
