import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
export class PermissionsUpdateService {
    constructor() {
        this.permissionsUpdated = new Subject();
        this.permissionsUpdated$ = this.permissionsUpdated.asObservable();
    }
    notifyNetworkGroupsUpdate() {
        // this.permissionsUpdated.next();
    }
}
PermissionsUpdateService.ngInjectableDef = i0.defineInjectable({ factory: function PermissionsUpdateService_Factory() { return new PermissionsUpdateService(); }, token: PermissionsUpdateService, providedIn: "root" });
